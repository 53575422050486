//colors
$theme-main: #ffa726;
$theme-main-red: #f33927;
$theme-main-dark: #383838;
$white: #ffffff;
$white-dark: #e2e2e2;
$white-1:#f1f3f5;
$black: #000000;
$dark-font: #222222;
$grey-dark: #2d2a25;
$font-color:#333333;
$grey-light: #dddddd;
$grey: #777777;
$grey2: #eeeeee;
$grey3: #c3c3c3;
$grey4: #444;
$grey5: #848484;
$grey6: #e5e5e5;
$grey7: #787878;
$grey8: #D9D9D9;
$grey9: #f3f3f3;
$grey10: #9c9c9c;
$baners-background: #f5f5f5;
$background: #fafafa;
$gray-lighter: #f6f6f6;
$footer-background: #f7f7f7;


$dark-theme: #2b2b2b;

$assetspath: "/assets/images";