.m-0 {
    margin: 0 !important;
  }
  
  .m-t-0 {
    margin-top: 0 !important;
  }
  
  .m-b-0 {
    margin-bottom: 0 !important;
  }
  
  .m-l-0 {
    margin-left: 0 !important;
  }
  
  .m-r-0 {
    margin-right: 0 !important;
  }
  
  .m-5 {
    margin: 5px !important;
  }
  
  .m-t-5 {
    margin-top: 5px !important;
  }
  
  .m-b-5 {
    margin-bottom: 5px !important;
  }
  
  .m-l-5 {
    margin-left: 5px !important;
  }
  
  .m-r-5 {
    margin-right: 5px !important;
  }
  
  .m-l-7 {
    margin-left: 7px !important;
  }
  
  .m-10 {
    margin: 10px !important;
  }
  
  .m-t-10 {
    margin-top: 10px !important;
  }
  
  .m-b-10 {
    margin-bottom: 10px !important;
  }
  
  .m-l-10 {
    margin-left: 10px !important;
  }
  
  .m-r-10 {
    margin-right: 10px !important;
  }
  
  .m-15 {
    margin: 15px !important;
  }
  
  .m-t-15 {
    margin-top: 15px !important;
  }
  
  .m-b-15 {
    margin-bottom: 15px !important;
  }
  
  .m-l-15 {
    margin-left: 15px !important;
  }
  
  .m-r-15 {
    margin-right: 15px !important;
  }
  
  .m-20 {
    margin: 20px !important;
  }
  
  .m-t-20 {
    margin-top: 20px !important;
  }
  
  .m-b-20 {
    margin-bottom: 20px !important;
  }
  
  .m-l-20 {
    margin-left: 20px !important;
  }
  
  .m-r-20 {
    margin-right: 20px !important;
  }
  
  .m-25 {
    margin: 25px !important;
  }
  
  .m-t-25 {
    margin-top: 25px !important;
  }
  
  .m-b-25 {
    margin-bottom: 25px !important;
  }
  
  .m-l-25 {
    margin-left: 25px !important;
  }
  
  .m-r-25 {
    margin-right: 25px !important;
  }
  
  .m-30 {
    margin: 30px !important;
  }
  
  .m-t-30 {
    margin-top: 30px !important;
  }
  
  .m-b-30 {
    margin-bottom: 30px !important;
  }
  
  .m-l-30 {
    margin-left: 30px !important;
  }
  
  .m-r-30 {
    margin-right: 30px !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .p-t-0 {
    padding-top: 0 !important;
  }
  
  .p-b-0 {
    padding-bottom: 0 !important;
  }
  
  .p-l-0 {
    padding-left: 0 !important;
  }
  
  .p-r-0 {
    padding-right: 0 !important;
  }
  
  .p-5 {
    padding: 5px !important;
  }
  
  .p-t-5 {
    padding-top: 5px !important;
  }
  
  .p-b-5 {
    padding-bottom: 5px !important;
  }
  
  .p-l-5 {
    padding-left: 5px !important;
  }
  
  .p-r-5 {
    padding-right: 5px !important;
  }
  
  .p-10 {
    padding: 10px !important;
  }
  
  .p-t-10 {
    padding-top: 10px !important;
  }
  
  .p-b-10 {
    padding-bottom: 10px !important;
  }
  
  .p-l-10 {
    padding-left: 10px !important;
  }
  
  .p-r-10 {
    padding-right: 10px !important;
  }
  
  .p-15 {
    padding: 15px !important;
  }
  
  .p-t-15 {
    padding-top: 15px !important;
  }
  
  .p-b-15 {
    padding-bottom: 15px !important;
  }
  
  .p-l-15 {
    padding-left: 15px !important;
  }
  
  .p-r-15 {
    padding-right: 15px !important;
  }
  
  .p-20 {
    padding: 20px !important;
  }
  .p-7 {
    padding: 7px !important;
  }
  
  .p-t-20 {
    padding-top: 20px !important;
  }
  
  .p-b-20 {
    padding-bottom: 20px !important;
  }
  
  .p-l-20 {
    padding-left: 20px !important;
  }
  
  .p-r-20 {
    padding-right: 20px !important;
  }
  
  .p-25 {
    padding: 25px !important;
  }
 
  .p-t-25 {
    padding-top: 25px !important;
  }
  
  .p-b-25 {
    padding-bottom: 25px !important;
  }
  
  .p-l-25 {
    padding-left: 25px !important;
  }
  
  .p-r-25 {
    padding-right: 25px !important;
  }
  
  .p-30 {
    padding: 30px !important;
  }
  
  .p-t-30 {
    padding-top: 30px !important;
  }
  
  .p-b-30 {
    padding-bottom: 30px !important;
  }
  
  .p-l-30 {
    padding-left: 30px !important;
  }
  
  .p-r-30 {
    padding-right: 30px !important;
  }
  
  .f-300 {
    font-weight: 300 !important;
  }
  
  .f-400 {
    font-weight: 400 !important;
  }
  
  .f-500 {
    font-weight: 500 !important;
  }
  
  .f-700 {
    font-weight: 700 !important;
  }
  
  .p-relative {
    position: relative !important;
  }
  
  .p-absolute {
    position: absolute !important;
  }
  
  .p-fixed {
    position: fixed !important;
  }
  
  .p-static {
    position: static !important;
  }
  
  .o-hidden {
    overflow: hidden !important;
  }
  
  .o-visible {
    overflow: visible !important;
  }
  
  .o-auto {
    overflow: auto !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .di-block {
    display: inline-block !important;
  }
  
  .bgm-white {
    background-color: #fff !important;
  }
  
  .c-white {
    color: #fff !important;
  }
  
  .bgm-black {
    background-color: #000 !important;
  }
  
  .c-black {
    color: #000 !important;
  }
  
  .bgm-brown {
    background-color: #795548 !important;
  }
  
  .c-brown {
    color: #795548 !important;
  }
  
  .bgm-pink {
    background-color: #e91e63 !important;
  }
  
  .c-pink {
    color: #e91e63 !important;
  }
  
  .bgm-red {
    background-color: #f44336 !important;
  }
  
  .c-red {
    color: #f44336 !important;
  }
  
  .bgm-blue {
    background-color: #2196f3 !important;
  }
  
  .c-blue {
    color: #2196f3 !important;
  }
  
  .bgm-purple {
    background-color: #9c27b0 !important;
  }
  
  .c-purple {
    color: #9c27b0 !important;
  }
  
  .bgm-deeppurple {
    background-color: #673ab7 !important;
  }
  
  .c-deeppurple {
    color: #673ab7 !important;
  }
  
  .bgm-lightblue {
    background-color: #03a9f4 !important;
  }
  
  .c-lightblue {
    color: #03a9f4 !important;
  }
  
  .bgm-cyan {
    background-color: #00bcd4 !important;
  }
  
  .c-cyan {
    color: #00bcd4 !important;
  }
  
  .bgm-teal {
    background-color: #009688 !important;
  }
  
  .c-teal {
    color: #009688 !important;
  }
  
  .bgm-green {
    background-color: #4caf50 !important;
  }
  
  .c-green {
    color: #4caf50 !important;
  }
  
  .bgm-lightgreen {
    background-color: #8bc34a !important;
  }
  
  .c-lightgreen {
    color: #8bc34a !important;
  }
  
  .bgm-lime {
    background-color: #cddc39 !important;
  }
  
  .c-lime {
    color: #cddc39 !important;
  }
  
  .bgm-yellow {
    background-color: #ffeb3b !important;
  }
  
  .c-yellow {
    color: #ffeb3b !important;
  }
  
  .bgm-amber {
    background-color: #ffc107 !important;
  }
  
  .c-amber {
    color: #ffc107 !important;
  }
  
  .bgm-orange {
    background-color: #ff9800 !important;
  }
  
  .c-orange {
    color: #ff9800 !important;
  }
  
  .bgm-deeporange {
    background-color: #ff5722 !important;
  }
  
  .c-deeporange {
    color: #ff5722 !important;
  }
  
  .bgm-gray {
    background-color: #9e9e9e !important;
  }
  
  .c-gray {
    color: #9e9e9e !important;
  }
  
  .bgm-bluegray {
    background-color: #607d8b !important;
  }
  
  .c-bluegray {
    color: #607d8b !important;
  }
  
  .bgm-indigo {
    background-color: #3f51b5 !important;
  }
  
  .c-indigo {
    color: #3f51b5 !important;
  }
  
  .bg-black-trp {
    background-color: rgba(0,0,0,0.1) !important;
  }
  
  .b-0 {
    border: 0 !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  
  .brd-2 {
    border-radius: 2px;
  }

  a {
      cursor: pointer;
  }