@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "~swiper/dist/css/swiper.min.css";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap");
@font-face {
  font-family: "Gotham";
  src: url("./assets/fonts/Gotham-Book.woff") format("woff"), url("./assets/fonts/Gotham-Book.woff2") format("woff2"), url("./assets/fonts/Gotham-Book.ttf") format("truetype");
}
/**************************************************************************
***************** Master Stylesheet for ecommerce************************
***************************************************************************
=========================== Content Index =================================
* 1. General styles
* 2. HOME-1 STYLES
     - Info bar styles
     - Header-1 styles
     - Shopping widget styles
     - Home product section styles
     - Home baners section styles
     - Home vertical-product section styles
     - Home footer section styles
* 3. HOME-2 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 4. HOME-3 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 4. HOME-4 STYLES
     - Carousel styles
     - Header-2 styles
     - Info bar styles
     - Shopping widget styles
     - Home-2 product section styles
* 3. Menu-1 styles
* 4. Menu-2 styles
* 5. Products left sidebar page styles
* 6. Popular product page styles
* 7. My account page styles
* 11. Price slider styles
* 12. Product dialog styles
* 13. Popular products styles

***************************************************************************
**************************************************************************/
/***************************************/
/* Icon Pulse Shrink */
:root {
  --theme-deafult: #00a9ef !important;
  --theme-deafult-secondary: #00a9ef !important;
  --theme-default-text: #00a9ef !important;
}

@-webkit-keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
@keyframes hvr-icon-pulse-shrink {
  to {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
.hvr-icon-pulse-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-icon-pulse-shrink .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-icon-pulse-shrink:hover .hvr-icon, .hvr-icon-pulse-shrink:focus .hvr-icon, .hvr-icon-pulse-shrink:active .hvr-icon {
  -webkit-animation-name: hvr-icon-pulse-shrink;
  animation-name: hvr-icon-pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
.color-options {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 350px;
  right: -360px;
  background-color: white;
  top: 0;
  z-index: 999;
  box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
  transition: all 0.5s ease;
}
.color-options .options-icon {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f7f7f7;
  width: 40px;
  height: 40px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  transition: all 0.5s ease;
  z-index: 2;
}
.color-options .options-icon .mat-icon {
  -webkit-animation: rotation 2s infinite linear;
  color: #333;
}
.color-options .mat-card {
  position: absolute;
  padding: 14px;
  width: 100%;
  height: 100%;
}
.color-options.show {
  right: -2px;
  -webkit-transition: max-height 0.25s ease-in;
  -moz-transition: max-height 0.25s ease-in;
  transition: all 0.5s ease;
}
.color-options .card-title-text {
  padding: 1rem 1rem;
  background: #f5f5f5;
}
.color-options .card-title-text h4 {
  color: #333 !important;
}
.color-options .configuration-content {
  padding: 1rem 1rem;
}
.color-options .configuration-content .setting-contant {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding-bottom: 1rem !important;
  margin-bottom: 1rem !important;
}
.color-options .configuration-content .setting-contant .color-box li {
  display: flex;
  align-items: center;
}
.color-options .configuration-content .setting-contant .color-box li input {
  border: none;
  box-shadow: none;
  width: 34px;
  height: 34px;
  background-color: transparent;
  margin-right: 5px;
  cursor: pointer;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 30px;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products1 {
  height: 110px;
  width: 100%;
  display: inline-flex;
  background-size: cover;
  transition: all 2s ease-out 0s;
  box-shadow: -1px 2px 5px 1px #ececec;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products1 img {
  width: 100%;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products2 {
  height: 110px;
  width: 100%;
  display: inline-flex;
  background-size: cover;
  transition: all 2s ease-out 0s;
  box-shadow: -1px 2px 5px 1px #ececec;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products2 img {
  width: 100%;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products3 {
  height: 110px;
  width: 100%;
  display: inline-flex;
  background-size: cover;
  transition: all 2s ease-out 0s;
  box-shadow: -1px 2px 5px 1px #ececec;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .products3 img {
  width: 100%;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .demo-text {
  text-align: center;
  background: transparent;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .demo-text .btn-preview {
  background: transparent;
  margin-top: 14px;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item .demo-text .btn-preview a {
  border: 1px solid #ddd;
  background-color: white;
  width: 90px;
  color: var(--theme-deafult);
  padding: 4px 12px;
  border-radius: 30px;
}
.color-options .configuration-content .setting-contant .products-preview-box-wrapper .item:hover .products1, .color-options .configuration-content .setting-contant .products-preview-box-wrapper .item:hover .products2, .color-options .configuration-content .setting-contant .products-preview-box-wrapper .item:hover .products2 {
  background-position: center 100% !important;
}
.color-options .configuration-content .setting-contant .theme-layout-version {
  cursor: pointer;
}
.color-options .configuration-content h6 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  margin: 0 0 1rem;
}

/***************************************
* 1. General styles
****************************************/
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Gotham", sans-serif !important;
  position: relative;
  font-size: 1rem;
  color: #333333 !important;
}

h1 {
  font-size: 60px;
  color: #383838;
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-size: 36px;
  color: #383838;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.02em;
}

h3 {
  font-size: 28px;
  text-align: left;
  color: #383838;
  font-weight: 500;
}

h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  color: #383838;
  line-height: 24px;
  letter-spacing: 0.05em;
}

h6 {
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  line-height: 24px;
}

* {
  margin: 0;
  padding: 0;
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-slider-track-fill {
  background-color: #ffa726;
}

.mat-primary .mat-slider-thumb {
  background-color: #ffa726;
}

.mat-raised-button.mat-warn {
  background-color: #f33927;
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--theme-deafult-secondary) !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--theme-deafult-secondary) !important;
}

.mat-expansion-indicator::after, .mat-expansion-panel-header-description {
  color: #444;
}

.mat-expansion-panel:hover .mat-expansion-indicator::after,
.mat-expansion-panel:focus .mat-expansion-indicator::after {
  color: #2a5ddf;
}

.sophia-app .mat-drawer-container {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
  background-color: #edecec;
}

.mat-menu-panel {
  min-width: 100% !important;
  max-width: 100% !important;
}

.mat-mini-fab.mat-accent {
  background-color: #ffa726;
}

a {
  text-decoration: none;
}

.text-center {
  text-align: center !important;
}

li {
  list-style-type: none;
}

.mat-snack-bar-container.error {
  background: #f33927 !important;
}

.mat-snack-bar-container.info {
  background: rgba(0, 0, 0, 0.6) !important;
  color: #fff;
}

.mat-snack-bar-container.warning {
  background: rgba(255, 204, 0, 0.6) !important;
  color: #000;
}

.mat-snack-bar-container.success {
  background: #99cc33 !important;
  color: #fff;
}

.mat-snack-bar-container button {
  width: 10px !important;
  color: #fff !important;
  background-color: transparent !important;
  padding: 0px !important;
}

.mat-drawer-backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-drawer-container {
  color: #333333;
}

.mat-radio-label-content {
  color: #787878;
  font-weight: 400;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f33927;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: var(--color1) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--color1) !important;
}

.mat-raised-button.mat-primary {
  background-color: var(--theme-deafult-secondary);
  color: #ffffff;
  border-radius: 23px;
}

.mat-input-element {
  caret-color: #ffa726 !important;
}

.mat-tab-group.mat-primary .mat-tab-label {
  opacity: 0.7;
}
.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: var(--theme-deafult);
}

.container {
  max-width: 1390px;
  margin: 0 auto;
  padding: 0 15px !important;
}

.divider {
  border-top: 1px solid #c3c3c3;
}

.bg {
  background: #fafafa;
}

.ml-10 {
  margin-left: 10px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.w-50 {
  width: 50%;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.no-pdn {
  padding: 0 !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.devider {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed #777777;
}

.w-100 {
  width: 100% !important;
}

.text-muted {
  color: #777777 !important;
}

.text-right {
  text-align: right;
}

.sec-padding {
  padding: 60px 0;
}

.btn-project {
  padding: 5px 32px !important;
  box-shadow: none !important;
}

/******** Product-1 styles *********/
.product-item-1 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 26px 5px 5px 5px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) {
  -webkit-box-shadow: 2px 1px 8px -1px rgba(0, 0, 0, 0.09) !important;
  -moz-box-shadow: 2px 1px 8px -1px rgba(0, 0, 0, 0.09) !important;
  box-shadow: 2px 1px 8px -1px rgba(0, 0, 0, 0.09) !important;
  padding: 0;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: #f5f5f5;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 180px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a {
  color: #333333;
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a:hover {
  color: var(--theme-deafult) !important;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
  color: #a2a2a2;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: #ffffff;
  font-size: 14px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap {
  margin: 10px 0 15px 0;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .stars {
  color: #ffa726;
  margin: 2px 0 10px 0;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .stars span {
  color: #c3c3c3;
  font-size: 13px;
  margin-left: 10px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .stars mat-icon {
  font-size: 17px;
  color: #ffa726;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  font-size: 19px;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: #ffffff;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a {
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
  border-radius: 0;
  text-align: center;
  line-height: 39px;
  color: #ffffff;
  font-size: 14px;
  box-shadow: none !important;
  transition: 0.4s ease;
  border: 2px solid var(--theme-deafult) !important;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a mat-icon {
  font-size: 18px;
  vertical-align: middle;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a:hover {
  border: 2px solid var(--theme-deafult) !important;
  background: #fff;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a:hover span {
  color: var(--theme-deafult) !important;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.product-item-1 .mat-card:not([class*=mat-elevation-z]):hover .price-wrap a {
  background: #ffa726;
}
.product-item-1 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

/******** Pack-1 styles *********/
.pack-item-1 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 0px 0px 0px 12px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) {
  -webkit-box-shadow: 2px 1px 8px -1px rgba(0, 0, 0, 0.09) !important;
  -moz-box-shadow: 2px 1px 8px -1px rgba(0, 0, 0, 0.09) !important;
  box-shadow: 2px 1px 8px -1px rgba(0, 0, 0, 0.09) !important;
  padding: 0;
  border: 1px solid #bac1c7;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: #f5f5f5;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 0px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a {
  color: #333333;
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a:hover {
  color: var(--theme-deafult) !important;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
  color: #a2a2a2;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: #ffffff;
  font-size: 14px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  letter-spacing: -0.3px;
  color: #3b4e60;
  font-size: 12px;
  font-weight: 400;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a {
  margin-top: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  border-radius: 6px;
  width: 100px;
  height: 30px;
  color: #fff;
  font-size: 11px;
  letter-spacing: -0.25px;
  font-family: "Gotham", sans-serif !important;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: break-spaces;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  color: #3b4e60;
  font-size: 12px;
  font-family: "Gotham", sans-serif !important;
  margin-top: 5px;
  letter-spacing: -0.35px;
  height: 50px;
}
.pack-item-1 .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.pack-item-1 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}
.pack-item-1 .footerButton .mat-button {
  min-width: 42px;
}

/******** customer-type-1 styles *********/
.customer-item-1 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 0px 0px 0px 12px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) {
  -webkit-box-shadow: 2px 1px 8px -1px rgba(0, 0, 0, 0.09) !important;
  -moz-box-shadow: 2px 1px 8px -1px rgba(0, 0, 0, 0.09) !important;
  box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, 0.09) !important;
  padding: 0;
  border: 1px solid #bac1c7;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: #f5f5f5;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a {
  color: #333333;
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .icons a:hover {
  color: var(--theme-deafult) !important;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
  color: #a2a2a2;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: #ffffff;
  font-size: 14px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  letter-spacing: -0.3px;
  color: #3b4e60;
  font-size: 12px;
  font-weight: 400;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .btn-wrap a {
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  border-radius: 6px;
  width: 100px;
  height: 30px;
  color: #fff;
  font-size: 11px;
  letter-spacing: -0.25px;
  font-family: "Gotham", sans-serif !important;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #3b4e60;
  font-size: 14px;
  font-family: "Gotham", sans-serif !important;
  margin-top: 5px;
  letter-spacing: -0.35px;
}
.customer-item-1 .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.customer-item-1 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: 46px;
  z-index: 10;
  background-image: none;
}
.customer-item-1 .footerButton .mat-button {
  min-width: 42px;
}

/******** Product-2 styles *********/
.product-item-2 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 26px 5px 5px 5px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 0;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: #f5f5f5;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 180px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img .icons a {
  color: #333333;
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-img .icons a:hover {
  opacity: 1;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars {
  color: #ffa726;
  margin: 2px 0 10px 0;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars span {
  color: #c3c3c3;
  font-size: 13px;
  margin-left: 10px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: #848484;
  font-size: 14px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  font-size: 19px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: #ffffff;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  background-color: var(--theme-deafult);
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 20px;
  line-height: 24px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  background-color: var(--theme-deafult);
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 18px;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.product-item-2 .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.product-item-2 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

/******** Product-3 styles *********/
.product-item-3 {
  position: unset;
  height: auto;
  transition: 0.4s;
  padding: 26px 5px 5px 5px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 0;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: #f5f5f5;
  position: relative;
  overflow: hidden;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 180px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img .content {
  display: table-cell;
  vertical-align: bottom;
  overflow: hidden;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img .content .icons {
  position: absolute;
  bottom: 0;
  top: auto;
  left: 0;
  width: 100%;
  text-indent: 0;
  height: auto;
  padding: 0.75rem 0 0.45rem 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  background: var(--theme-deafult);
  z-index: 2;
  transition: 0.3s ease;
  -webkit-transform: translate3d(0, 35px, 0);
  transform: translate3d(0, 35px, 0);
  -webkit-transition: -webkit-transform 0.4s ease, visibility 0.4s ease, opacity 0.4s ease;
  transition: transform 0.4s ease, visibility 0.4s ease, opacity 0.4s ease;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-img .content .icons a {
  cursor: pointer;
  transition: 0.5s ease;
  line-height: 22px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars {
  color: #ffa726;
  margin: 2px 0 10px 0;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars span {
  color: #c3c3c3;
  font-size: 13px;
  margin-left: 10px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: #848484;
  font-size: 14px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  font-size: 19px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: #ffffff;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary {
  box-shadow: none;
  background: var(--theme-deafult);
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary mat-icon {
  transition: 0.3s;
  font-size: 20px;
  line-height: 24px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  background: #ffa726;
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  background: #e2e2e2;
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a:hover {
  background: #ffa726;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 18px;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.product-item-3 .mat-card:not([class*=mat-elevation-z]):hover .content .icons {
  opacity: 0.85;
  visibility: visible;
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}
.product-item-3 button.swipe-arrow {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  z-index: 10;
  background-image: none;
}

/***************************************
    ***************************************
    ***************************************
*  HOME-1 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-1 styles
****************************************/
.main-slider {
  height: 550px;
}
.main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.main-slider .sl-wrap .content {
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
}
.main-slider .sl-wrap .content button {
  background-color: var(--theme-deafult);
  color: #ffffff;
  padding: 5px 33px;
  width: 200px;
  box-shadow: none !important;
}
.main-slider .sl-wrap .content button span {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.main-slider .sl-wrap .content h1 {
  font-size: 48px;
  text-align: left;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.main-slider .sl-wrap .content h3 {
  font-size: 36px;
  text-align: left;
  color: #333333;
  margin-bottom: 30px;
  font-weight: 300;
  letter-spacing: 2px;
}
.main-slider .slide-item {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.swiper-pagination-bullet-active {
  background: #ffa726 !important;
}

/* .mask{
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    background-color: rgba(0,0,0,0.8);
} */
.swiper-lazy-preloader {
  top: 18%;
}

.h-100 {
  height: 100%;
}

/***************************************
*  Home info bar styles
****************************************/
.info-bar {
  margin-top: 30px;
  border-bottom: 1px solid #e5e5e5;
}
.info-bar .card-wrap {
  padding: 15px 0;
}
.info-bar .card-wrap .mat-card {
  height: 100px;
  box-shadow: none;
  border-right: 1px solid #e5e5e5;
}
.info-bar .card-wrap .mat-card.no-bd {
  border-right: none;
}
.info-bar .card-wrap .mat-icon {
  color: #333333;
  font-size: 41px;
  margin-right: 15px;
  line-height: 23px;
}

span {
  font-size: 14px;
}

.sidenav {
  width: 250px;
  position: fixed !important;
  z-index: 1000000000000000100 !important;
}

.mat-toolbar-multiple-rows {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
}

.welcome-message p {
  font-size: 13.5px;
  color: #787878;
}

/***************************************
*  Header-1 styles
****************************************/
/*  Topbar styles */
.main-topbar {
  background: #ffffff !important;
}
.main-topbar .top-bar .widget-text {
  padding: 9px 7px;
}
.main-topbar .top-bar .widget-text a {
  color: #333333;
  text-decoration: none;
  font-size: 13px;
  transition: 0.4s;
}
.main-topbar .top-bar .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
}
.main-topbar .top-bar .widget-text a:hover {
  color: #9c9c9c;
}
.main-topbar .top-bar .widget-text:not(:last-child) {
  margin: 0 20px 0 -15px;
}
.main-topbar .top-bar .widget-text .mat-button-wrapper img {
  margin-right: 3px;
}
.main-topbar .top-bar .welcome-message p {
  color: #787878;
  font-size: 13.5px;
  font-weight: 400;
}
.main-topbar .top-bar span .mat-icon {
  color: #ffa726;
}
.main-topbar .top-bar .cur-icon {
  color: #787878 !important;
}

/*  header styles */
.home-header.yellow {
  display: none;
}
.home-header .kuro {
  background-color: var(--theme-deafult);
  padding: 13px 0;
  color: var(--theme-default-text);
}
.home-header .call-bucket-wrap .info-message-icon {
  color: #fff !important;
}
.home-header .top-navbar {
  background: transparent !important;
}
.home-header .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff !important;
}
.home-header .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header .logo {
  padding: 25px 0;
}
.home-header .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: #444;
  background: rgba(0, 0, 0, 0.2);
}
.home-header .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 3px;
  border-color: #ffffff;
  -webkit-transition: box-shadow 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header .search-form .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  color: #ffffff;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: #333333;
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
}
.home-header .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  /* color: #fff; */
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: #222222;
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}

.home-header-yellow {
  background: #fbc02d;
  padding: 13px 0;
}
.home-header-yellow.green {
  display: none;
}
.home-header-yellow.blue {
  display: none;
}
.home-header-yellow.red {
  display: none;
}
.home-header-yellow.pink {
  display: none;
}
.home-header-yellow.purple {
  display: none;
}
.home-header-yellow.grey {
  display: none;
}
.home-header-yellow.orange {
  display: none;
}
.home-header-yellow .kuro {
  background-color: var(--theme-deafult);
}
.home-header-yellow .top-navbar {
  background: transparent !important;
}
.home-header-yellow .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-header-yellow .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-yellow .top-navbar .mat-button-wrapper .commerce-buttons .flex-row-button .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: #ffffff;
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}
.home-header-yellow .logo {
  padding: 25px 0;
}
.home-header-yellow .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header-yellow .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: #444;
  background: rgba(0, 0, 0, 0.2);
}
.home-header-yellow .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 3px;
  border-color: #ffffff;
  -webkit-transition: box-shadow 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header-yellow .search-form .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  color: #ffffff;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: #333333;
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
}
.home-header-yellow .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  color: #000000;
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header-yellow .call-bucket-wrap .info-message-icon .info-text {
  color: #000000;
}
.home-header-yellow .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-yellow .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-yellow .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: #ffffff;
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}

/*  shopping widgets styles */
.spCard-main {
  width: 100%;
}
.spCard-main .mat-menu-content {
  width: 239px !important;
}
.spCard-main .spCard-dropdown {
  background-color: #ffffff;
  padding: 15px 10px;
  max-width: 300px;
  z-index: 400;
}
.spCard-main .spCard-dropdown .card-list-title {
  padding: 0 0 10px 0;
}
.spCard-main .spCard-dropdown h4 {
  padding-bottom: 10px;
}
.spCard-main .spCard-dropdown .new-product .product img {
  width: 70px;
}
.spCard-main .spCard-dropdown .new-product .close-circle {
  flex: 1;
}
.spCard-main .spCard-dropdown .new-product .close-circle a {
  cursor: pointer;
  color: #c3c3c3;
  transition: 0.3s;
  float: right;
}
.spCard-main .spCard-dropdown .new-product .close-circle a:hover {
  color: #ffa726;
}
.spCard-main .spCard-dropdown .new-product .close-circle a i {
  font-size: 20px;
}
.spCard-main .spCard-dropdown .new-product .item {
  padding: 10px 0;
}
.spCard-main .spCard-dropdown .new-product .item .product {
  margin-right: 7px;
  background: #f5f5f5;
  border-radius: 3px;
}
.spCard-main .spCard-dropdown .new-product .item .desc p {
  font-weight: 500;
}
.spCard-main .spCard-dropdown .new-product .item .desc span {
  color: #777777;
  font-size: 15px;
  margin-top: 5px;
  /* padding-top: 7px; */
  display: block;
}
.spCard-main .spCard-dropdown .new-product .total {
  padding-top: 15px;
}
.spCard-main .spCard-dropdown .new-product .total p {
  font-size: 15px;
}
.spCard-main .spCard-dropdown .new-product .total span {
  font-weight: 500;
  font-size: 16px;
}
.spCard-main .spCard-dropdown .card-list-title a {
  color: #444;
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .card-list-title p {
  color: #444;
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .woo-message {
  text-align: center;
  padding: 0;
  margin: 1.5em 0 0 0;
}

/***************************************
*  Shopping widget styles
****************************************/
.card-list-title2 {
  padding: 5px 0 15px 0;
}

/***************************************
*  Home product section styles
****************************************/
.products {
  position: relative;
}
.products .swiper-button-prev.swiper-button-disabled[_ngcontent-c25] {
  pointer-events: auto;
}
.products .product-wraper {
  padding: 20px 0px 20px 20px;
}
.products .product-wraper .product-box {
  width: 100%;
}

/***************************************
*  Home baners section styles
****************************************/
.banners-container .baners {
  background: #f5f5f5 !important;
  margin-right: 15px;
}
.banners-container .bn-wrap {
  margin-bottom: 18px;
}
.banners-container .baner {
  height: 100%;
  transition: 0.15s ease-in;
  text-align: left;
}
.banners-container .baner .info {
  padding: 20px;
}
.banners-container .baner .info h4 {
  font-size: 21px;
  font-weight: 600;
  color: #2d2a25;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.banners-container .baner h4.big-title {
  font-size: 37px;
  font-weight: 500;
  color: #2d2a25;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.banners-container .baner .info .big-price {
  padding: 10px 25px !important;
  box-shadow: none !important;
  width: 135px;
  border-radius: 5px;
  text-align: center;
}
.banners-container .baner .info .big-price span {
  font-size: 27px !important;
}
.banners-container .baner p {
  color: #383838;
}
.banners-container .baner a {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: #383838;
  margin-top: 15px;
  display: block;
}
.banners-container .first {
  height: 450px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 4px;
}
.banners-container .seccond {
  /* height: 200px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  margin: 0 10px;
  background-color: #f6f6f6;
}

/***************************************
*  Home vertical-product section styles
****************************************/
.vertical-products .widget-column .title {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.vertical-products .widget-column .title .widget-title {
  font-size: 21px;
  font-weight: 500;
}
.vertical-products .widget-column .title .widget-title::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #f6f6f6;
}
.vertical-products .widget-column .title .widget-title::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #222222;
  bottom: 0.3125rem;
}
.vertical-products .widget-column .media-image {
  background: #f6f6f6;
  margin-right: 20px;
  width: 80px;
  height: 80px;
  border-radius: 3px;
}
.vertical-products .widget-column .media-image .small {
  width: 100%;
}
.vertical-products .widget-column .media-body span {
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.03em;
  line-height: 1;
  color: #333333;
}
.vertical-products .widget-column .media-body .stars mat-icon {
  color: #ffa726;
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.vertical-products .widget-column .media-body p.price {
  margin-top: 5px;
}
.vertical-products .widget-column .product-list li:not(:last-child) {
  margin-bottom: 20px;
}
.vertical-products .product-panel:not(:last-child) {
  padding-right: 30px;
}

/***************************************
*  Home footer section styles
****************************************/
.footer {
  background-color: #f7f7f7;
}
.footer .footer-widget {
  padding-right: 15px;
}
.footer .footer-widget .social {
  margin-top: 20px;
}
.footer .footer-widget .social li {
  width: 33px;
  height: 33px;
  border: 1px solid #c3c3c3;
  border-radius: 50px;
  text-align: center;
  margin-right: 5px;
}
.footer .footer-widget .social li a i {
  color: #383838;
}
.footer .footer-widget .ped-btn-footer {
  box-shadow: none !important;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  padding: 0px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
  background-color: var(--theme-deafult);
  color: #ffffff;
  border-radius: 3px;
  line-height: 45px;
}
.footer .footer-widget .title {
  margin-bottom: 10px;
}
.footer .footer-widget .title h5 {
  font-size: 18px;
  font-weight: 600;
}
.footer .footer-widget a {
  text-decoration: none;
  font-size: 15px;
  line-height: 32px;
  color: #333333;
}
.footer .footer-widget .newsletter-widget {
  position: relative;
  margin: 15px 0 20px 0;
}
.footer .footer-widget p {
  line-height: 24px;
  color: #333333;
}
.footer .footer-widget ul {
  list-style-type: none;
}
.footer .footer-widget .about-widget .logo img {
  max-width: 240px;
}
.footer .footer-widget .tel-box .icon i {
  color: #383838;
  display: block;
  line-height: 27px;
  vertical-align: middle;
  margin-right: 5px;
  width: 18px;
  text-align: center;
  font-size: 18px;
}
.footer .footer-widget .tel-box .text p a {
  line-height: 22px;
}
.footer .subscribe-widget input {
  height: 45px;
  background: #ffffff;
  font-size: 15px;
  line-height: 45px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 0 65px 0 20px;
  color: #777777;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 2 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-2 styles
****************************************/
.carousel-2 {
  padding-top: 25px;
}
.carousel-2 .ctn-box {
  box-sizing: content-box !important;
}
.carousel-2 .main-slider {
  height: 461px;
}
.carousel-2 .main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.carousel-2 .main-slider .sl-wrap .content {
  padding-left: 20px;
  padding-right: 400px;
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
  transform: none;
}
.carousel-2 .slider-categories {
  padding-right: 15px;
}
.carousel-2 .slider-categories .categories-wrap {
  border: 1px solid #D9D9D9;
}
.carousel-2 .slider-categories .categories-wrap .title {
  background: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  color: #444;
  padding: 15px 20px 15px 10px;
  font-weight: 500;
  font-size: 14.5px;
}
.carousel-2 .slider-categories .categories-wrap .category-contant ul li {
  display: block;
}
.carousel-2 .slider-categories .categories-wrap .category-contant ul li a {
  padding-top: 13px;
  padding-bottom: 13px !important;
  border-top: 1px solid #D9D9D9;
  margin: 0 10px -1px;
}
.carousel-2 .slider-categories .categories-wrap .category-contant ul li .no-brd {
  border-top: none;
}
.carousel-2 .slider-categories .categories-wrap .btn-sale {
  margin: 20px 10px;
}
.carousel-2 .slider-categories .categories-wrap .btn-sale button {
  padding: 5px 33px;
  width: 100%;
  box-shadow: none;
}
.carousel-2 .carousel-2-wrapper {
  max-width: 70%;
}
.carousel-2 .carousel-2-wrapper .main-slider {
  height: 461px;
}
.carousel-2 .carousel-2-wrapper .main-slider .content {
  padding-left: 20px;
  padding-right: 485px;
}

/***************************************
*  Header-2 styles
****************************************/
.home-header-2 {
  background: #ffffff;
}
.home-header-2 .logo {
  padding: 25px 0;
}
.home-header-2 .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header-2 .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: #444;
  background: rgba(0, 0, 0, 0);
}
.home-header-2 .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 3px;
  border: 1px solid #eeeeee;
  -webkit-transition: box-shadow 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header-2 .search-form .form-wrap .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  color: #ffffff;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: #333333;
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
}
.home-header-2 .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  /* color: #fff; */
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header-2 .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-2 .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-2 .call-bucket-wrap .main-menu-wrapper-2 .top-navbar {
  background: #ffffff;
}
.home-header-2 .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-header-2 .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-2 .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .commerce-buttons .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  color: #ffffff;
  background-color: var(--theme-deafult);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}

/***************************************
*  Header-5 styles
****************************************/
/*  header styles */
.home-header-five {
  background: #ffffff;
}
.home-header-five .header-wrapper {
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0;
}
.home-header-five .header-wrapper .phone-wrap .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-five .header-wrapper .phone-wrap .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-five .header-wrapper .top-navbar {
  background: transparent;
}
.home-header-five .header-wrapper .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-header-five .header-wrapper .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-five .header-wrapper .thm-button button {
  color: #ffffff !important;
  border-radius: 0;
  padding: 4px 40px;
}

/*  menu styles */
.menu-wraper-five {
  background: #ffffff;
}
.menu-wraper-five .menu-container {
  padding: 14px 0;
  height: 67px;
}
.menu-wraper-five .menu-container .mat-toolbar-row {
  height: 100%;
}
.menu-wraper-five .menu-container .main-menu {
  margin-left: -15px;
}
.menu-wraper-five .menu-container .main-menu a {
  text-transform: uppercase;
  color: #787878;
}
.menu-wraper-five .menu-container .call-bucket-wrap {
  padding-top: 10px;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar {
  flex-direction: row !important;
  box-sizing: border-box;
  display: flex;
  background: transparent !important;
  /*  shopping widgets styles */
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .info-message-icon .icon mat-icon {
  font-size: 35px;
  /* color: #fff; */
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons {
  margin-top: 7px;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .flex-row-button .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .flex-row-button .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--theme-deafult);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
  color: #ffffff;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .badge .mat-badge-content {
  color: #fff;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form {
  margin-right: 20px;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form .mat-form-field-wrapper .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.07) !important;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form .mat-form-field-wrapper .mat-form-field-label {
  display: none;
}
.menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .devider2 {
  height: 31px;
  width: 1px;
  background: #e5e5e5;
  margin: 0 20px;
}

.spCard-main {
  width: 100%;
}
.spCard-main .mat-menu-content {
  width: 239px !important;
}
.spCard-main .spCard-dropdown {
  background-color: #ffffff;
  padding: 15px 10px;
  max-width: 300px;
  z-index: 400;
}
.spCard-main .spCard-dropdown .card-list-title {
  padding: 0 0 10px 0;
}
.spCard-main .spCard-dropdown h4 {
  padding-bottom: 10px;
}
.spCard-main .spCard-dropdown .new-product .product img {
  width: 70px;
}
.spCard-main .spCard-dropdown .new-product .close-circle {
  flex: 1;
}
.spCard-main .spCard-dropdown .new-product .close-circle a {
  cursor: pointer;
  color: #c3c3c3;
  transition: 0.3s;
  float: right;
}
.spCard-main .spCard-dropdown .new-product .close-circle a i {
  font-size: 20px;
}
.spCard-main .spCard-dropdown .new-product .item {
  padding: 10px 0;
}
.spCard-main .spCard-dropdown .new-product .item .product {
  margin-right: 7px;
  background: #ffffff;
  /* border-radius: 3px; */
  border: 1px solid #e7e7e7;
}
.spCard-main .spCard-dropdown .new-product .item .desc p {
  font-weight: 500;
}
.spCard-main .spCard-dropdown .new-product .item .desc span {
  color: #777777;
  font-size: 15px;
  margin-top: 5px;
  /* padding-top: 7px; */
  display: block;
}
.spCard-main .spCard-dropdown .new-product .total {
  padding-top: 15px;
}
.spCard-main .spCard-dropdown .new-product .total p {
  font-size: 15px;
}
.spCard-main .spCard-dropdown .new-product .total span {
  font-weight: 500;
  font-size: 16px;
}
.spCard-main .spCard-dropdown .card-list-title a {
  color: #444;
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .card-list-title p {
  color: #444;
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .woo-message {
  text-align: center;
  padding: 0;
  margin: 1.5em 0 0 0;
}

/***************************************
*  Home-2 info bar styles
****************************************/
.info-bar {
  margin-top: 30px;
  border: 1px solid #e5e5e5;
}
.info-bar .card-wrap {
  padding: 15px 0;
}
.info-bar .card-wrap .mat-card {
  height: 100px;
  box-shadow: none;
  border-right: 1px solid #e5e5e5;
  box-shadow: none !important;
  background: transparent !important;
}
.info-bar .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
}
.info-bar .card-wrap .mat-card.no-bd {
  border-right: none;
}
.info-bar .card-wrap .mat-icon {
  color: var(--theme-deafult);
  font-size: 41px;
  margin-right: 10px;
  line-height: 65px;
  clear: both;
  /* display: block; */
  width: auto;
  height: 100%;
  /* vertical-align: middle; */
  padding: 0 3px;
}

/***************************************
*  Home-2 product section styles
****************************************/
.home-2-wrap .products-section {
  background: #e5e5e5;
}
.home-2-wrap .products-section .products {
  padding: 0 !important;
}
.home-2-wrap .products-section .products .product-wraper .product-box .product-item {
  width: 240px;
}
.home-2-wrap .products-section .products .product-wraper .product-box .product-item .product-img {
  background: #ffffff;
}
.home-2-wrap .products-section .products .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.home-2-wrap .products-section h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 23px;
  font-weight: 580;
  position: absolute;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 3 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-3 styles
****************************************/
.carousel-3 {
  background: #ffffff;
  padding: 25px 0;
}
.carousel-3 .ctn-box {
  box-sizing: content-box !important;
}
.carousel-3 .carousel-2-wrapper {
  max-width: 70%;
}
.carousel-3 .carousel-2-wrapper .main-slider {
  height: 461px;
}
.carousel-3 .carousel-2-wrapper .main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.carousel-3 .carousel-2-wrapper .main-slider .sl-wrap .content {
  padding-left: 20px;
  padding-right: 400px;
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
}
.carousel-3 .carousel-2-wrapper .main-slider .swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 5px;
  display: inline-block;
  border-radius: 13px;
  background: #222222;
  opacity: 0.6;
}
.carousel-3 .slider-categories {
  padding: 0 !important;
}
.carousel-3 .slider-categories .carousel-banners-wrapper {
  padding-left: 15px;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner {
  width: 100%;
  height: 223px;
  background: #f5f5f5;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  margin: 0 10px;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info {
  padding: 40px 0;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .bn-img img {
  width: 100%;
  height: auto;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .text-box h4 {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .text-box button {
  color: var(--theme-deafult);
  text-decoration: underline;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  vertical-align: baseline;
  text-align: left;
  margin-top: 21px;
  min-width: auto !important;
  line-height: 0 !important;
  padding: 0 !important;
  border-radius: 4px;
  overflow: visible;
  box-shadow: none !important;
}

.home-3-wrap .home3-sidebar {
  padding-right: 15px;
}
.home-3-wrap .home3-sidebar .categories-wrap {
  border: 1px solid #dddddd;
  margin-bottom: 25px;
}
.home-3-wrap .home3-sidebar .categories-wrap .title {
  background: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  color: #444;
  padding: 15px 20px 15px 10px;
  font-weight: 500;
  font-size: 14.5px;
}
.home-3-wrap .home3-sidebar .categories-wrap .category-contant ul li {
  display: block;
}
.home-3-wrap .home3-sidebar .categories-wrap .category-contant ul li a {
  padding-top: 13px;
  padding-bottom: 13px !important;
  border-top: 1px solid #dddddd;
  margin: 0 10px -1px;
}
.home-3-wrap .home3-sidebar .categories-wrap .category-contant ul li .no-brd {
  border-top: none;
}
.home-3-wrap .home3-sidebar .categories-wrap .btn-sale {
  margin: 20px 10px;
}
.home-3-wrap .home3-sidebar .categories-wrap .btn-sale button {
  padding: 5px 33px;
  width: 100%;
  box-shadow: none !important;
}
.home-3-wrap .home3-sidebar .brands {
  border: 1px solid #dddddd;
  padding: 15px 20px;
  margin-bottom: 25px;
}
.home-3-wrap .home3-sidebar .brands .title-header {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.home-3-wrap .home3-sidebar .brands .title-header h4::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #e5e5e5;
}
.home-3-wrap .home3-sidebar .brands .title-header h4::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #333333;
  bottom: 0.3125rem;
}
.home-3-wrap .home3-sidebar .brands .brand-logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}
.home-3-wrap .home3-sidebar .brands .brand-logos .brand-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}
.home-3-wrap .home3-sidebar .brands .brand-logos .brand-item a img {
  width: 100%;
}
.home-3-wrap .home3-sidebar .mat-toolbar {
  height: 17%;
  margin-bottom: 25px;
  background-color: var(--theme-deafult);
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget {
  padding: 15px 20px;
  text-align: center;
  margin-bottom: 25px;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .sale-widget1 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .sale-widget2 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .discount {
  display: inline-block;
  color: #2d2a25;
  font-size: 77px;
  font-weight: 600;
  position: relative;
  letter-spacing: 0.5;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .percent {
  display: inline-block;
  line-height: 31px;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget .percent span {
  display: block;
  font-size: 2.5em;
  font-weight: 500;
}
.home-3-wrap .home3-sidebar .mat-toolbar .sale-widget p {
  color: #ffffff;
  line-height: 24px;
  font-weight: 300;
  font-size: 15px;
}
.home-3-wrap .home3-sidebar .subscribe-widget {
  padding: 15px 20px;
  background: #f6f6f6;
  text-align: center;
}
.home-3-wrap .home3-sidebar .subscribe-widget span {
  font-weight: 600;
  display: block;
}
.home-3-wrap .home3-sidebar .subscribe-widget p {
  color: #444;
  line-height: 24px;
  font-weight: 300;
  font-size: 15px;
}
.home-3-wrap .home3-sidebar .subscribe-widget .form {
  position: relative;
  margin-top: 20px;
}
.home-3-wrap .home3-sidebar .subscribe-widget .form .newsletter-widget input {
  height: 45px;
  background: #ffffff;
  font-size: 15px;
  line-height: 45px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 0 65px 0 20px;
}
.home-3-wrap .home3-sidebar .subscribe-widget .form .newsletter-widget .ped-btn-footer {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  padding: 0px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
  width: 100%;
  border-radius: 3px;
  line-height: 45px;
  box-shadow: none !important;
}
.home-3-wrap .main-home3-wrapper {
  padding-left: 15px;
}
.home-3-wrap .main-home3-wrapper .info-bar {
  margin-top: 0px;
  border: 1px solid #e5e5e5;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap {
  padding: 15px 0;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card {
  height: 100px;
  box-shadow: none;
  border-right: 1px solid #e5e5e5;
  padding: 9px;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card.no-bd {
  border-right: none;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
}
.home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-icon {
  font-size: 41px;
  margin-right: 10px;
  line-height: 65px;
  width: 25%;
}
.home-3-wrap .main-home3-wrapper .products {
  background: #ffffff;
  position: relative;
}
.home-3-wrap .main-home3-wrapper .products .product-wraper .product-box .product-item {
  width: 227px;
}
.home-3-wrap .main-home3-wrapper .products .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
  height: 100%;
}
.home-3-wrap .main-home3-wrapper .products .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.home-3-wrap .main-home3-wrapper h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  position: absolute;
  top: 69px;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap {
  width: 100%;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner {
  position: relative;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
  position: absolute;
  top: 33px;
  left: 35px;
  font-size: 95%;
  z-index: 9999999999999;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .subtitle {
  color: #ffffff;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
  color: #ffa726;
  font-size: 38px;
  font-weight: 600;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
  border: 1px solid #ffa726;
  color: #333333 !important;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
  margin-top: 14px;
  padding: 0px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
  background: #ffa726;
  width: 120px;
  border-radius: 3px;
  line-height: 40px;
}
.home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner img {
  position: relative;
  display: block;
  height: auto;
  max-width: 100%;
  width: calc(100% + 60px);
}

.menu-container1 {
  background: #f3f3f3;
}
.menu-container1 a.mat-button {
  text-transform: uppercase;
  color: #383838;
  font-size: 1em;
  font-weight: 700;
  padding: 0 25px;
  line-height: 63px;
}

/***************************************
*  Menu-2 styles
****************************************/
.mat-menu-content {
  width: 221px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 4px solid #ffa726 !important;
}
.mat-menu-content .mat-menu-item {
  border-bottom: 1px dashed #f6f6f6;
}

.top-navbar a {
  padding: 15px 26px;
}

.menu-container2 {
  background: var(--theme-deafult);
}
.menu-container2 .mat-toolbar {
  background: transparent !important;
}
.menu-container2 a.mat-button {
  text-transform: uppercase;
  color: #ffffff !important;
  font-size: 1em;
  font-weight: 700;
  padding: 0 25px;
  line-height: 63px;
}

/***************************************
*  Sidebar styles
****************************************/
.mat-drawer {
  width: 240px;
  position: fixed;
  margin-left: 0px;
}

.toggle-header {
  height: 64px;
  background: #f33927;
}

.sidebar-menu {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eeeeee;
}
.sidebar-menu .menu-item a {
  color: #383838;
  padding: 8px 15px;
  font-size: 16px;
  display: block;
}

/***************************************
*  Products left sidebar page styles
****************************************/
.left-sidebar-wrapper mat-sidenav.filter-sidenav.mat-drawer.mat-sidenav.ng-tns-c15-4.ng-trigger.ng-trigger-transform.ng-tns-c13-3.ng-star-inserted.mat-drawer-over {
  padding-right: 10px;
}

.left-sidebar-wrapper .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid #dddddd;
  box-shadow: none !important;
  margin-bottom: 20px;
  background: transparent !important;
}
.left-sidebar-wrapper .mat-expansion-panel .mat-expansion-panel-header-title span {
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
}
.left-sidebar-wrapper .filter-sidenav {
  width: 280px;
  border: 0 !important;
}
.left-sidebar-wrapper .filter-sidenav .brands .title-header {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.left-sidebar-wrapper .filter-sidenav .brands .title-header h4::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #e5e5e5;
}
.left-sidebar-wrapper .filter-sidenav .brands .title-header h4::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #333333;
  bottom: 0.3125rem;
}
.left-sidebar-wrapper .filter-sidenav .brands .brand-logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}
.left-sidebar-wrapper .filter-sidenav .brands .brand-logos .brand-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}
.left-sidebar-wrapper .filter-sidenav .brands .brand-logos .brand-item a img {
  width: 100%;
}
.left-sidebar-wrapper .filter-sidenav .popular-products {
  margin-top: 20px;
}
.left-sidebar-wrapper .filter-row {
  padding: 6px 25px 0px;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  margin-bottom: 20px;
  box-shadow: none !important;
}
.left-sidebar-wrapper .all-products {
  padding-left: 17px;
}
.left-sidebar-wrapper .all-products select {
  /* margin: 50px; */
  width: 150px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: none;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  background: url(/assets/images/dropdown.png) no-repeat 90%;
}
.left-sidebar-wrapper .all-products .col {
  padding: 9px 0 9px 0px;
}
.left-sidebar-wrapper .all-products .col .product-item {
  position: relative;
  height: auto;
  padding: 0 10px;
  transition: 0.4s;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  padding: 0;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: #f6f6f6;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img img {
  max-width: 180px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img .icons a {
  color: #333333;
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars {
  color: #ffa726;
  margin: 2px 0 10px 0;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars span {
  color: #c3c3c3;
  font-size: 13px;
  margin-left: 10px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: #848484;
  font-size: 14px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  font-size: 19px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: #ffffff;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  background: var(--theme-deafult);
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 18px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary {
  box-shadow: none;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary .mat-icon {
  transition: 0.3s;
  font-size: 20px;
  line-height: 24px;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.left-sidebar-wrapper .no-shadow {
  box-shadow: none !important;
}

/***************************************
*  Products left sidebar page styles
****************************************/
.right-sidebar-wrapper .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid #dddddd;
  box-shadow: none !important;
  margin-bottom: 20px;
  background: transparent !important;
}
.right-sidebar-wrapper .mat-expansion-panel .mat-expansion-panel-header-title span {
  font-size: 17px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
}
.right-sidebar-wrapper .filter-sidenav {
  width: 280px;
  border: 0 !important;
}
.right-sidebar-wrapper .filter-sidenav .brands .title-header {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.right-sidebar-wrapper .filter-sidenav .brands .title-header h4::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #e5e5e5;
}
.right-sidebar-wrapper .filter-sidenav .brands .title-header h4::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #333333;
  bottom: 0.3125rem;
}
.right-sidebar-wrapper .filter-sidenav .brands .brand-logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1em;
  grid-column-gap: 1em;
}
.right-sidebar-wrapper .filter-sidenav .brands .brand-logos .brand-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  overflow: hidden;
  position: relative;
  margin-right: 10px;
}
.right-sidebar-wrapper .filter-sidenav .brands .brand-logos .brand-item a img {
  width: 100%;
}
.right-sidebar-wrapper .filter-sidenav .popular-products {
  margin-top: 20px;
}
.right-sidebar-wrapper .filter-row {
  padding: 6px 25px 0px;
  border-bottom: 1px solid #e4e4e4;
  border-top: 1px solid #e4e4e4;
  margin-bottom: 20px;
  box-shadow: none !important;
}
.right-sidebar-wrapper .all-products select {
  /* margin: 50px; */
  width: 150px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: none;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  background: url(/assets/images/dropdown.png) no-repeat 90%;
}
.right-sidebar-wrapper .all-products .col {
  padding: 9px 0 9px 0px;
}
.right-sidebar-wrapper .all-products .col .product-box {
  min-height: 440px;
  width: 307px;
  margin-left: 9px !important;
  margin-right: 9px !important;
  box-shadow: 0 2px 14px #888887;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  padding: 0;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: #f6f6f6;
  display: table-cell;
  vertical-align: middle;
  height: 306px;
  width: 299px;
  margin: 0 auto;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img img {
  /* max-height: 100%; */
  max-width: 85%;
  padding: 25px;
  margin: auto;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img .icons {
  position: absolute;
  top: 30px;
  right: 10px;
  opacity: 0;
  transition: all 0.5s ease;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-img .icons a {
  color: #333333;
  cursor: pointer;
  transition: 0.5s ease;
  margin-bottom: 5px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
  padding: 15px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars {
  color: #ffa726;
  margin: 2px 0 10px 0;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars span {
  color: #c3c3c3;
  font-size: 13px;
  margin-left: 10px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .category {
  margin-bottom: 4px;
  display: block;
  font-weight: 300;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper span {
  color: #848484;
  font-size: 14px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap mat-icon {
  color: #ffffff;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
  background-color: var(--theme-deafult);
  cursor: pointer;
  transition: 0.3s;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 46px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a mat-icon {
  transition: 0.3s;
  font-size: 18px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary {
  box-shadow: none;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap .mat-mini-fab.mat-primary .mat-icon {
  transition: 0.3s;
  font-size: 20px;
  line-height: 24px;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .title-wrap h4 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]):hover .icons {
  opacity: 1;
}
.right-sidebar-wrapper .no-shadow {
  box-shadow: none !important;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 4 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-4 styles
****************************************/
.home-header-three {
  background: #ffffff;
  border-top: 1px solid #f5f5f5;
}
.home-header-three .logo {
  padding: 25px 0;
}
.home-header-three .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header-three .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: #444;
  background: rgba(0, 0, 0, 0.2);
}
.home-header-three .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 3px;
  border: 2px solid #dcdcdc;
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header-three .search-form .form-wrap .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: var(--theme-deafult);
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: none;
  box-shadow: none !important;
  -webkit-appearance: none;
}
.home-header-three .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  /* color: #fff; */
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header-three .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-three .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar {
  background: transparent;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar .flex-row-button {
  background: transparent;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar .flex-row-button .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar .flex-row-button .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-navbar .flex-row-button .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--theme-deafult);
  height: 18px;
  width: 18px;
  color: #ffffff;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}

.menu-container4 {
  background: #ffffff;
  border-top: 1px solid #e5e5e5;
}
.menu-container4 .main-menu {
  margin-left: -15px;
  margin: 0 auto;
}
.menu-container4 .main-menu a.mat-button {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  padding: 0 25px;
  line-height: 63px;
}

.topbar_four {
  background: #f5f5f5 !important;
}
.topbar_four .top-bar .widget-text {
  padding: 9px 7px;
}
.topbar_four .top-bar .widget-text a {
  color: #333333;
}

/***************************************
*  Home-4 info bar styles
****************************************/
.info-bar-four-wrapper {
  margin-top: 0px;
  border: none;
  background: #ffffff;
  border-bottom: 1px solid #e5e5e5;
}
.info-bar-four-wrapper .info-bar-four .card-wrap {
  padding: 15px 0;
}
.info-bar-four-wrapper .info-bar-four .card-wrap .mat-card {
  height: 100px;
  box-shadow: none;
  border-right: 1px solid #e5e5e5;
  box-shadow: none !important;
  background: transparent;
}
.info-bar-four-wrapper .info-bar-four .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
}
.info-bar-four-wrapper .info-bar-four .card-wrap .mat-card.no-bd {
  border-right: none;
}
.info-bar-four-wrapper .info-bar-four .card-wrap .mat-icon {
  color: var(--theme-deafult);
  font-size: 41px;
  margin-right: 10px;
  line-height: 65px;
  clear: both;
  overflow: hidden;
  /* display: block; */
  width: auto;
  height: 100%;
  /* vertical-align: middle; */
  padding: 0 3px;
}

/***************************************
*  Home-4 banner styles
****************************************/
.banners-four {
  padding: 25px 0;
}
.banners-four .banners-container .four {
  height: 162px;
}

/***************************************
*  Home-4 products styles
****************************************/
.products .products-four h3 {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
}
.products .products-four h3::before {
  content: "";
  left: 29%;
  position: absolute;
  width: 43%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: rgba(0, 0, 0, 0.08);
}
.products .products-four h3::after {
  content: "";
  left: 48.5%;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #222222;
  bottom: 0.3125rem;
}

.featured-products-wrap {
  background: #fafafa;
}

/***************************************
*  Home-4 banner-promotion styles
****************************************/
.main-banner .banner-pro {
  height: 442px;
}
.main-banner .banner-pro img {
  max-width: 100%;
  height: auto;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - 5 STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Carousel-5 styles
****************************************/
/***************************************
*  Menu-5 styles
****************************************/
.mat-menu-content {
  width: 221px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 4px solid var(--theme-deafult) !important;
}
.mat-menu-content .mat-menu-item {
  border-bottom: 1px dashed #f6f6f6;
}
.mat-menu-content .mat-menu-item span {
  background: var(--theme-deafult) !important;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
  padding: 1px 3px;
}
.mat-menu-content .mat-menu-item .soon {
  background: #12b13b !important;
}

.top-navbar a {
  padding: 15px 26px;
}

.menu-container5 {
  background: transparent;
}
.menu-container5 .container {
  background: transparent;
}
.menu-container5 .container a.mat-button {
  text-transform: uppercase;
  color: #333333;
  font-size: 1em;
  font-weight: 700;
  padding: 0 25px;
  line-height: 63px;
}

/***************************************
*  Categories menu styles
****************************************/
.categories-menu-wrap .category-contant ul {
  background-color: #403f3f;
}
.categories-menu-wrap .category-contant ul li {
  width: 14.285%;
  transition: 0.3s;
}
.categories-menu-wrap .category-contant ul li a {
  display: flex;
  flex-direction: column;
  line-height: 16px;
  letter-spacing: -0.13px;
  color: #fff;
  padding: 15px 16px 18px 16px;
  justify-content: center;
  text-align: center;
  transition: 0.3s;
}
.categories-menu-wrap .category-contant ul li a i {
  margin-bottom: 5px;
}
.categories-menu-wrap .category-contant ul li a:hover {
  color: #222222 !important;
}
.categories-menu-wrap .category-contant ul li:hover {
  background: #c3c3c3;
  color: #222222 !important;
}

/***************************************
*  Products styles
****************************************/
.home-5-wrap {
  background: #ffffff;
}
.home-5-wrap .products {
  position: relative;
}
.home-5-wrap .products h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  position: absolute;
  top: 69px;
}
.home-5-wrap .products .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

/***************************************
*  Categories section styles
****************************************/
.home-5-wrap .categories {
  background: #f7f7f7;
}
.home-5-wrap .categories .title {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 1.25rem;
}
.home-5-wrap .categories .title h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
}
.home-5-wrap .categories .title h3::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #e4e4e4;
}
.home-5-wrap .categories .title h3::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #222222;
  bottom: 0.3125rem;
}
.home-5-wrap .categories .left-section {
  padding-right: 7px;
}
.home-5-wrap .categories .left-section .item {
  background: #ffffff;
  height: 100%;
  padding: 20px;
  border: 1px solid #eeeeee;
}
.home-5-wrap .categories .left-section .item .product {
  text-align: center;
}
.home-5-wrap .categories .left-section .item .product img {
  max-width: 40%;
}
.home-5-wrap .categories .left-section .item .product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.home-5-wrap .categories .left-section .item .product-info h4 {
  margin-right: 15px;
}
.home-5-wrap .categories .left-section .item .product-info h4 a {
  color: #333333;
}
.home-5-wrap .categories .left-section .item .product-info h4 a:hover {
  text-decoration: underline;
}
.home-5-wrap .categories .left-section .item .product-info ul {
  margin-left: 15px;
}
.home-5-wrap .categories .left-section .item .product-info ul li a {
  color: #777777;
  font-size: 15px;
}
.home-5-wrap .categories .left-section .item .product-info ul li a:hover {
  text-decoration: underline;
}
.home-5-wrap .categories .right-section {
  padding-left: 7px;
}
.home-5-wrap .categories .right-section .item {
  background: #ffffff;
  height: 100%;
  padding: 20px;
  border: 1px solid #eeeeee;
}
.home-5-wrap .categories .right-section .item .product {
  text-align: center;
}
.home-5-wrap .categories .right-section .item .product img {
  max-width: 40%;
}
.home-5-wrap .categories .right-section .item .product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.home-5-wrap .categories .right-section .item .product-info h4 {
  margin-right: 15px;
}
.home-5-wrap .categories .right-section .item .product-info h4 a {
  color: #333333;
}
.home-5-wrap .categories .right-section .item .product-info h4 a:hover {
  text-decoration: underline;
}
.home-5-wrap .categories .right-section .item .product-info li a {
  color: #777777;
  font-size: 15px;
}
.home-5-wrap .categories .right-section .item .product-info li a:hover {
  text-decoration: underline;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - INDUSTRIAL STYLES
****************************************
****************************************
****************************************/
/***************************************


/***************************************
*  Home product section styles
****************************************/
.products-industrial {
  position: relative;
}
.products-industrial .swiper-button-prev.swiper-button-disabled[_ngcontent-c25] {
  pointer-events: auto;
}
.products-industrial .product-wraper {
  padding: 20px 0px 20px 20px;
}
.products-industrial .product-wraper .product-box {
  width: 100%;
}
.products-industrial .product-item-1 .mat-card:not([class*=mat-elevation-z]) .product-img {
  background: #ffffff;
}

/* Categories banners */
.industrial-home-wrap .categories {
  background: #ffffff;
}
.industrial-home-wrap .categories .title {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 1.25rem;
}
.industrial-home-wrap .categories .title h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
}
.industrial-home-wrap .categories .title h3::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #e4e4e4;
}
.industrial-home-wrap .categories .title h3::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #777777;
  bottom: 0.3125rem;
}
.industrial-home-wrap .categories .left-section {
  padding-right: 7px;
}
.industrial-home-wrap .categories .left-section .item {
  background: #f5f5f5;
  height: 100%;
  padding: 20px;
  border: 1px solid #eeeeee;
}
.industrial-home-wrap .categories .left-section .item .product {
  text-align: center;
}
.industrial-home-wrap .categories .left-section .item .product img {
  max-width: 40%;
}
.industrial-home-wrap .categories .left-section .item .product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.industrial-home-wrap .categories .left-section .item .product-info h4 {
  margin-right: 15px;
}
.industrial-home-wrap .categories .left-section .item .product-info h4 a {
  color: #333333;
}
.industrial-home-wrap .categories .left-section .item .product-info h4 a:hover {
  text-decoration: underline;
}
.industrial-home-wrap .categories .left-section .item .product-info ul {
  margin-left: 15px;
}
.industrial-home-wrap .categories .left-section .item .product-info ul li a {
  color: #777777;
  font-size: 15px;
}
.industrial-home-wrap .categories .left-section .item .product-info ul li a:hover {
  text-decoration: underline;
}
.industrial-home-wrap .categories .right-section {
  padding-left: 7px;
}
.industrial-home-wrap .categories .right-section .item {
  background: #f5f5f5;
  height: 100%;
  padding: 20px;
  border: 1px solid #eeeeee;
}
.industrial-home-wrap .categories .right-section .item .product {
  text-align: center;
}
.industrial-home-wrap .categories .right-section .item .product img {
  max-width: 40%;
}
.industrial-home-wrap .categories .right-section .item .product-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.industrial-home-wrap .categories .right-section .item .product-info h4 {
  margin-right: 15px;
}
.industrial-home-wrap .categories .right-section .item .product-info h4 a {
  color: #333333;
}
.industrial-home-wrap .categories .right-section .item .product-info h4 a:hover {
  text-decoration: underline;
}
.industrial-home-wrap .categories .right-section .item .product-info li a {
  color: #777777;
  font-size: 15px;
}
.industrial-home-wrap .categories .right-section .item .product-info li a:hover {
  text-decoration: underline;
}
.industrial-home-wrap .categories .h {
  height: 205px !important;
}

/* Info bar */
.info-wrap-industrial {
  position: relative;
  background: #f7f7f7;
}
.info-wrap-industrial .info-bar .card-wrap {
  margin-bottom: 20px;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .icon {
  border: 2px solid #c3c3c3;
  width: 70px;
  height: 70px;
  text-align: center;
  line-height: 89px;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .icon .mat-icon {
  font-size: 31px;
  margin-right: 5px;
  color: var(--theme-deafult) !important;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .content {
  text-align: center;
  margin-top: 15px;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .content p {
  font-weight: 600;
}
.info-wrap-industrial .info-bar .card-wrap .light-block .content span {
  line-height: 26px;
}
.info-wrap-industrial .subscribe {
  padding: 50px;
  margin-top: 30px;
  background: var(--theme-deafult) !important;
}
.info-wrap-industrial .subscribe .subscribe-wrap {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.info-wrap-industrial .subscribe .subscribe-wrap p {
  color: #fff;
  font-size: 16px;
}
.info-wrap-industrial .subscribe .subscribe-wrap .form-wrap {
  margin-left: 20px;
}
.info-wrap-industrial .subscribe .subscribe-wrap .form-wrap input {
  background: rgba(255, 255, 255, 0.2);
  width: 347px;
  border: none;
  color: #fff;
  padding: 2px 14px;
  height: 40px;
}
.info-wrap-industrial .subscribe .subscribe-wrap .form-wrap button {
  width: 175px;
  height: 44px;
  color: #fff;
  background: transparent !important;
  border: 2px solid #fff;
  border-radius: 0;
  margin-left: 15px;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - FOOD STYLES
****************************************
****************************************
****************************************/
.topbar-food {
  background: var(--theme-deafult) !important;
}
.topbar-food .top-bar .widget-text a {
  color: #ffffff;
  text-decoration: none;
  font-size: 13px;
  transition: 0.4s;
}
.topbar-food .top-bar .widget-text a .mat-icon {
  color: #ffffff !important;
}

/* Header six section */
.header-six {
  position: relative;
}
.header-six .header-wrapper {
  position: absolute;
  top: 20px;
  z-index: 9999;
  left: 0;
  right: 0;
}
.header-six .header-wrapper .logo {
  cursor: pointer;
}
.header-six .header-wrapper .main-menu {
  margin-left: -15px;
}
.header-six .header-wrapper .main-menu a {
  text-transform: uppercase;
  color: #ffffff;
}

.mat-menu-content {
  width: 221px;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 4px solid #c3c3c3 !important;
}

/* Product section */
.food-product-section {
  position: relative;
  top: -15px;
  background: url(/assets/images/background/download.svg) no-repeat 90%;
  background-size: auto 75px;
  background-repeat: repeat-x;
  background-position: center 0;
  z-index: 989;
}
.food-product-section .back-img1 {
  position: absolute;
  left: 0;
  top: 56px;
}
.food-product-section .back-img2 {
  position: absolute;
  left: 0;
  bottom: 56px;
}
.food-product-section .back-img3 {
  position: absolute;
  right: 0;
  bottom: 235px;
}
.food-product-section .head-title {
  text-align: center;
}
.food-product-section .head-title h3 {
  text-align: center;
  margin-bottom: 15px;
}
.food-product-section .head-title p {
  line-height: 28px;
  margin-top: 0px;
}
.food-product-section .prod-menu {
  margin-top: 45px;
  margin-bottom: 45px;
}
.food-product-section .prod-menu ul li {
  cursor: pointer;
  transition: 0.3s;
}
.food-product-section .prod-menu ul li .icon {
  height: 50px;
}
.food-product-section .prod-menu ul li .icon svg {
  max-height: 38px;
  fill: #444 !important;
}
.food-product-section .prod-menu ul li .icon svg .cls-1 {
  stroke: #444;
}
.food-product-section .prod-menu ul li .text p {
  color: #777777;
  margin: 0;
}
.food-product-section .prod-menu ul li:hover {
  transition: 0.3s;
}
.food-product-section .prod-menu ul li:hover .icon svg {
  color: #ffa726;
}
.food-product-section .prod-menu ul li:hover .text p {
  transition: 0.3s;
  color: #ffa726;
}
.food-product-section .product-wrapper {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}
.food-product-section .product-wrapper .product-box .product-item {
  position: relative;
  overflow: hidden;
}
.food-product-section .product-wrapper .product-box .product-item mat-card {
  padding: 0;
  box-shadow: none;
  border: 1px solid #e5e5e5;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-img {
  text-align: center;
  cursor: pointer;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-img img {
  max-width: 180px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper {
  background: #f9f9f9;
  padding: 15px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .category {
  text-align: left;
  font-weight: 400;
  color: #787878;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .title-wrap {
  margin: 7px 0;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .title-wrap h4 {
  font-size: 21px;
  margin: 0;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .title-wrap p {
  font-size: 21px;
  font-family: "Gelasio", serif;
  font-weight: 600;
  margin: 0;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .stars .mat-icon {
  font-size: 20px;
  height: 22px;
  width: 22px;
  color: #ffa726;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap {
  margin-top: 10px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap .view-more {
  font-weight: 500;
  color: #787878;
  cursor: pointer;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap a {
  cursor: pointer;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap a .mat-icon {
  color: var(--theme-deafult) !important;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .btn-wrap .bucket {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #dedede;
  text-align: center;
  vertical-align: middle;
  line-height: 49px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .details-products .more-info {
  margin-top: 15px;
  overflow: hidden;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .details-products .more-info p {
  line-height: 24px;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: -10px;
  opacity: 0;
}
.food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .icons mat-icon {
  color: #444;
  cursor: pointer;
}
.food-product-section .product-wrapper .product-box .product-item mat-card:hover .icons {
  opacity: 1;
  transition: all 0.4s ease;
  transform: translateX(-20px);
}
.food-product-section .product-wrapper .product-box .product-item mat-card:hover .icons .ic-1 {
  animation-delay: 2s;
}
.food-product-section .product-wrapper .product-box .product-item mat-card:hover .icons .ic-2 {
  animation: 0.5s ease-in-out fadeInRight;
}
.food-product-section .product-wrapper .product-box .product-item mat-card:hover .icons .ic-3 {
  animation: 0.7s ease-in-out fadeInRight;
}

/* Product details page */
.food-details-wrap .header-title {
  background-size: cover;
  padding: 130px 0;
  position: relative;
}
.food-details-wrap .header-title .title {
  text-align: center;
}
.food-details-wrap .header-title .title h2 {
  color: #ffffff;
  font-size: 52px;
  margin: 0;
}
.food-details-wrap .header-title .title p {
  color: #ffffff;
  line-height: 26px;
  margin: 0;
}
.food-details-wrap .details-wrapper {
  position: relative;
  top: -15px;
  background-size: auto 75px;
  background-repeat: repeat-x;
  background-position: center 0;
}
.food-details-wrap .details-wrapper .product-image {
  box-shadow: none !important;
  border: 1px solid #D9D9D9;
  border-radius: 0;
}
.food-details-wrap .details-wrapper .product-image img {
  max-width: 100%;
}
.food-details-wrap .details-wrapper .product-image a {
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-right {
  padding: 0 30px 0 25px;
}
.food-details-wrap .details-wrapper .product-right .product-details {
  padding-bottom: 15px;
  border-bottom: 1px dashed #dddddd;
}
.food-details-wrap .details-wrapper .product-right .product-details h2 {
  margin-bottom: 10px;
}
.food-details-wrap .details-wrapper .product-right .product-details h4 del {
  color: #777777;
  font-size: 17px;
}
.food-details-wrap .details-wrapper .product-right .product-details h4 span {
  padding-left: 5px;
  color: #f33927;
  font-size: 24px;
}
.food-details-wrap .details-wrapper .product-right .product-details .avalibility {
  padding-bottom: 15px;
}
.food-details-wrap .details-wrapper .product-right .product-details .avalibility span {
  color: #f33927;
  font-weight: 500;
}
.food-details-wrap .details-wrapper .product-right .product-details p.description {
  line-height: 26px;
}
.food-details-wrap .details-wrapper .product-right .product-details h3.price {
  font-size: 26px;
  font-weight: 400;
  margin: 10px 0;
}
.food-details-wrap .details-wrapper .product-right .py-1 .red {
  font-weight: 600;
  color: var(--theme-deafult);
}
.food-details-wrap .details-wrapper .product-right .quantity {
  margin-top: 10px;
}
.food-details-wrap .details-wrapper .product-right .quantity span {
  margin-bottom: 0;
  display: block;
}
.food-details-wrap .details-wrapper .product-right .quantity span .mat-icon {
  font-weight: 300;
  font-size: 20px;
}
.food-details-wrap .details-wrapper .product-right .quantity .quantity-box {
  border: 1px solid #D9D9D9;
  margin-right: 10px;
}
.food-details-wrap .details-wrapper .product-right .quantity .quantity-box input {
  width: 48px;
  text-align: center;
  background-color: #fff;
  height: 100%;
  border: none;
  border-left: 1px solid #D9D9D9;
  border-right: 1px solid #D9D9D9;
}
.food-details-wrap .details-wrapper .product-right .quantity .quantity-box button {
  line-height: 44px;
}
.food-details-wrap .details-wrapper .product-right .social-icons {
  padding-top: 15px;
  border-top: 1px dashed #dddddd;
}
.food-details-wrap .details-wrapper .product-right .social-icons ul li {
  width: 33px;
  height: 33px;
  border: 1px solid #c3c3c3;
  border-radius: 50%;
  text-align: center;
  margin-right: 5px;
}
.food-details-wrap .details-wrapper .product-right .social-icons ul li a {
  text-decoration: none;
  font-size: 15px;
  line-height: 32px;
  color: #dddddd;
}
.food-details-wrap .details-wrapper .product-right .social-icons ul li a i {
  color: #444;
}
.food-details-wrap .details-wrapper .info-bar {
  border: 1px solid #dddddd;
  padding: 0 20px;
  margin-top: 0px !important;
}
.food-details-wrap .details-wrapper .info-bar .mat-icon {
  color: #333333;
  font-size: 41px;
  margin-right: 15px;
  line-height: 23px;
}
.food-details-wrap .details-wrapper .info-bar .content p {
  text-transform: capitalize;
  font-weight: 500;
}
.food-details-wrap .details-wrapper .info-bar .mat-card {
  box-shadow: none !important;
  padding: 20px 0;
  border-bottom: 1px solid #dddddd;
}
.food-details-wrap .details-wrapper .info-bar .mat-card.no-border {
  border-bottom: none;
}
.food-details-wrap .details-wrapper .review-wrapper {
  padding-right: 30px;
  margin-top: 30px;
}
.food-details-wrap .details-wrapper .review-wrapper .mat-tab-label {
  color: #333333 !important;
  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  /* opacity: 0.1; */
  min-width: 33%;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  background: #f4f4f4;
  margin: 0 2px;
}
.food-details-wrap .details-wrapper .review-wrapper .mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  color: #333333 !important;
  font-family: "Gelasio", serif;
  font-weight: 600;
  font-size: 15px;
}
.food-details-wrap .details-wrapper .review-wrapper .full-desc {
  padding: 20px;
}
.food-details-wrap .details-wrapper .review-wrapper .full-desc p {
  line-height: 24px;
}
.food-details-wrap .details-wrapper .review-wrapper .full-desc ul {
  margin-top: 15px;
  padding-left: 20px;
}
.food-details-wrap .details-wrapper .review-wrapper .full-desc ul li {
  margin-bottom: 7px;
}
.food-details-wrap .details-wrapper .review-wrapper .Reviews .reviews .name {
  font-weight: 500;
}
.food-details-wrap .details-wrapper .review-wrapper .Reviews .reviews p {
  line-height: 22px;
  white-space: normal;
}
.food-details-wrap .details-wrapper .review-wrapper .Reviews .reviews .text {
  margin-top: 10px;
}
.food-details-wrap .details-wrapper .review-wrapper .Reviews .reviews .mat-list-item .mat-list-item-content {
  align-items: start !important;
}
.food-details-wrap .details-wrapper .review-wrapper .writte-reviews .mat-form-field {
  width: 100%;
}
.food-details-wrap .details-wrapper .review-wrapper .writte-reviews .rating h3 {
  font-size: 22px;
  margin-top: 20px;
}
.food-details-wrap .details-wrapper .review-wrapper .writte-reviews .btn-project {
  background: #ffa726;
  padding: 3px 32px;
  color: #ffffff;
  border: 2px solid #ffa726;
  border-radius: 0;
  transition: 0.3s;
}
.food-details-wrap .details-wrapper .review-wrapper .writte-reviews .btn-project:hover {
  background: #ffffff;
  color: #ffa726;
  border: 2px solid #ffa726;
}
.food-details-wrap .details-wrapper .review-wrapper .products {
  padding-right: 30px;
}
.food-details-wrap .details-wrapper .review-wrapper .products .title {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.food-details-wrap .details-wrapper .review-wrapper .products .title::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #f6f6f6;
}
.food-details-wrap .details-wrapper .review-wrapper .products .title::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #222222;
  bottom: 0.3125rem;
}
.food-details-wrap .details-wrapper .sale-widget {
  padding: 15px 20px;
  background: #444;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 25px;
  height: auto;
}
.food-details-wrap .details-wrapper .sale-widget .sale-widget1 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.food-details-wrap .details-wrapper .sale-widget .sale-widget2 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.food-details-wrap .details-wrapper .sale-widget .discount {
  display: inline-block;
  color: #ffffff;
  font-size: 77px;
  font-weight: 600;
  position: relative;
  letter-spacing: 0.5;
}
.food-details-wrap .details-wrapper .sale-widget .percent {
  display: inline-block;
  line-height: 31px;
  color: #ffffff;
}
.food-details-wrap .details-wrapper .sale-widget .percent span {
  display: block;
  font-size: 3.067em;
  font-weight: 500;
  color: #ffffff;
}
.food-details-wrap .details-wrapper .sale-widget p {
  color: #ffffff;
  line-height: 24px;
  font-weight: 300;
  font-size: 15px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-slide mat-card img {
  max-width: 110px;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.food-details-wrap .details-wrapper .small-carousel {
  position: relative;
  margin-top: 10px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-container {
  height: 180px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-container .swiper-slide mat-card {
  max-height: 120px;
  min-height: 120px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-container .swiper-button-prev {
  position: absolute;
  top: 32%;
  left: 0px;
}
.food-details-wrap .details-wrapper .small-carousel .swiper-container .swiper-button-next {
  position: absolute;
  top: 32%;
  right: 0px;
}
.food-details-wrap .details-wrapper .product-item {
  position: relative;
  overflow: hidden;
}
.food-details-wrap .details-wrapper .product-item mat-card {
  padding: 0;
  box-shadow: none;
  border: 1px solid #e5e5e5;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-img {
  text-align: center;
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-img img {
  max-width: 180px;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper {
  background: #f9f9f9;
  padding: 15px;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .category {
  text-align: left;
  font-weight: 400;
  color: #787878;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .title-wrap {
  margin: 7px 0;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .title-wrap h4 {
  font-size: 21px;
  margin: 0;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .title-wrap p {
  font-size: 21px;
  font-family: "Gelasio", serif;
  font-weight: 600;
  margin: 0;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .stars .mat-icon {
  font-size: 20px;
  height: 22px;
  width: 22px;
  color: #ffa726;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .btn-wrap {
  margin-top: 10px;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .btn-wrap .view-more {
  font-weight: 500;
  color: #787878;
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .btn-wrap .bucket {
  background: #dedede;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 49px;
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .btn-wrap .bucket a mat-icon {
  font-size: 21px;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .details-products .more-info {
  margin-top: 15px;
  overflow: hidden;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .icons {
  position: absolute;
  top: 30px;
  right: -10px;
  opacity: 0;
}
.food-details-wrap .details-wrapper .product-item mat-card .product-info-wrapper .icons mat-icon {
  color: #444;
  cursor: pointer;
}
.food-details-wrap .details-wrapper .product-item mat-card:hover .icons {
  opacity: 1;
  transition: all 0.4s ease;
  transform: translateX(-20px);
}
.food-details-wrap .details-wrapper .product-item mat-card:hover .icons .ic-1 {
  animation-delay: 2s;
}
.food-details-wrap .details-wrapper .product-item mat-card:hover .icons .ic-2 {
  animation: 0.5s ease-in-out fadeInRight;
}
.food-details-wrap .details-wrapper .product-item mat-card:hover .icons .ic-3 {
  animation: 0.7s ease-in-out fadeInRight;
}
.food-details-wrap .details-wrapper .title {
  margin-bottom: 22px;
}
.food-details-wrap .no-bg {
  background: transparent !important;
}
.food-details-wrap .no-bg .title h4 {
  font-size: 22px;
}

.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}

.mat-list-base .mat-list-item.mat-3-line, .mat-list-base .mat-list-option.mat-3-line {
  height: 100% !important;
  margin-bottom: 20px;
}

/* Galery section*/
.popular-recipes-box {
  padding: 90px 0 0 0;
  background-size: cover;
  position: relative;
}
.popular-recipes-box .recipes-contain .head-title {
  text-align: center;
  color: #ffffff;
  margin-bottom: 60px;
}
.popular-recipes-box .recipes-contain .head-title h3 {
  text-align: center;
  margin-bottom: 15px;
  color: #ffffff;
}
.popular-recipes-box .recipes-contain .head-title p {
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 20px;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section {
  grid-column: full-start/full-end;
  display: grid;
  /*grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(7, 5vw);*/
  grid-template: repeat(5, 5vw)/repeat(8, 1fr);
  grid-gap: 1.5rem;
  padding: 1.5rem;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section img {
  max-width: 100%;
  height: auto;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  will-change: transform;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item {
  overflow: hidden;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(1) {
  overflow: hidden;
  grid-row: 1/span 2;
  grid-column: 1/span 2;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(2) {
  grid-row: 1/span 3;
  grid-column: 3/span 3;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(3) {
  grid-row: 1/span 2;
  grid-column: 8/8;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(4) {
  grid-row: 3/span 3;
  grid-column: 1/span 2;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(5) {
  grid-row: 4/span 2;
  grid-column: 3/span 2;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(6) {
  grid-row: 4/6;
  grid-column: 5/8;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(7) {
  grid-row: 1/4;
  grid-column: 6/span 2;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:nth-child(8) {
  grid-row: 3/span 3;
  grid-column: 8/-1;
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__item:hover img {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}
.popular-recipes-box .recipes-contain .gallery .gallery-section .gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: 0px;
}

/* About products section*/
.about-products {
  padding: 90px 0;
  background: #fafafa;
}
.about-products .about-products-wrap .left .text {
  text-align: center;
  padding: 0 50px;
}
.about-products .about-products-wrap .left .text h3 {
  text-align: center;
  margin-bottom: 10px;
}
.about-products .about-products-wrap .left .text span {
  font-weight: 500;
  line-height: 26px;
  margin-top: 10px;
}
.about-products .about-products-wrap .left .text p {
  line-height: 26px;
}
.about-products .about-products-wrap .left .text .btn-project {
  margin-top: 20px;
}
.about-products .about-products-wrap .right .img-wrap img {
  max-width: 100%;
  height: auto;
}

/***************************************
*  Widget three styles
****************************************/
.widgets-three {
  width: 30px;
  margin-left: 0;
  position: relative;
  padding-top: 10px;
}
.widgets-three .bucket .mat-button-wrapper {
  width: 30px;
}
.widgets-three .bucket .mat-button-wrapper svg {
  max-height: 40px;
  fill: #ffffff;
}
.widgets-three .bucket .mat-button-wrapper .mat-badge {
  margin-left: -10px;
}
.widgets-three .bucket .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  text-align: center;
  top: -3px;
  left: 37px;
  background: var(--theme-deafult);
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
  color: #ffffff;
  font-weight: 700;
}
.widgets-three .bucket .top-cart span {
  color: #ffffff;
}

.spCard-main {
  width: 100%;
}
.spCard-main .mat-menu-content {
  width: 239px !important;
}
.spCard-main .spCard-dropdown {
  background-color: #ffffff;
  padding: 15px 10px;
  max-width: 300px;
  z-index: 400;
}
.spCard-main .spCard-dropdown .card-list-title {
  padding: 0 0 10px 0;
}
.spCard-main .spCard-dropdown h4 {
  padding-bottom: 10px;
}
.spCard-main .spCard-dropdown .new-product .product img {
  width: 70px;
}
.spCard-main .spCard-dropdown .new-product .close-circle {
  flex: 1;
}
.spCard-main .spCard-dropdown .new-product .close-circle a {
  cursor: pointer;
  color: #c3c3c3;
  transition: 0.3s;
  float: right;
}
.spCard-main .spCard-dropdown .new-product .close-circle a i {
  font-size: 20px;
}
.spCard-main .spCard-dropdown .new-product .item {
  padding: 10px 0;
}
.spCard-main .spCard-dropdown .new-product .item .product {
  margin-right: 7px;
  background: #ffffff;
  /* border-radius: 3px; */
  border: 1px solid #e7e7e7;
}
.spCard-main .spCard-dropdown .new-product .item .desc p {
  font-weight: 500;
}
.spCard-main .spCard-dropdown .new-product .item .desc span {
  color: #777777;
  font-size: 15px;
  margin-top: 5px;
  /* padding-top: 7px; */
  display: block;
}
.spCard-main .spCard-dropdown .new-product .total {
  padding-top: 15px;
}
.spCard-main .spCard-dropdown .new-product .total p {
  font-size: 15px;
}
.spCard-main .spCard-dropdown .new-product .total span {
  font-weight: 500;
  font-size: 16px;
}
.spCard-main .spCard-dropdown .card-list-title a {
  color: #444;
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .card-list-title p {
  color: #444;
  font-size: 14px;
  font-weight: 500;
}
.spCard-main .spCard-dropdown .woo-message {
  text-align: center;
  padding: 0;
  margin: 1.5em 0 0 0;
}

/***************************************
*  Carousel Food styles
****************************************/
.main-slider-food {
  height: 760px;
}
.main-slider-food .sl-wrap {
  position: relative;
  height: 100%;
}
.main-slider-food .sl-wrap .content {
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  text-align: center;
  /* top: 58%; */
}
.main-slider-food .sl-wrap .content button {
  background: var(--theme-deafult) !important;
  border-radius: 0;
  padding: 5px 33px;
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}
.main-slider-food .sl-wrap .content button span {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.main-slider-food .sl-wrap .content h1 {
  font-size: 48px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 0;
}
.main-slider-food .sl-wrap .content p {
  font-size: 19px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 30px;
  font-weight: 400;
  width: 756px;
  margin: 0 auto;
  line-height: 35px;
}
.main-slider-food .slide-item {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.swiper-pagination-bullet-active {
  background: #ffffff !important;
}

/* .mask{
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    background-color: rgba(0,0,0,0.8);
} */
.swiper-lazy-preloader {
  top: 18%;
}

.h-100 {
  height: 100%;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - FURNITURE STYLES
****************************************
****************************************
****************************************/
/*  Topbar styles */
.main-topbar-furniture {
  background: #f3f3f3 !important;
}
.main-topbar-furniture .top-bar .widget-text a {
  color: #2d2a25;
  text-decoration: none;
  font-size: 13px;
  transition: 0.4s;
}
.main-topbar-furniture .top-bar .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
}
.main-topbar-furniture .top-bar .widget-text a:hover {
  color: #ffa726;
}
.main-topbar-furniture .top-bar .widget-text:not(:last-child) {
  margin: 0 20px 0 0;
}
.main-topbar-furniture .top-bar .widget-text .mat-button-wrapper img {
  margin-right: 3px;
}
.main-topbar-furniture .top-bar .welcome-message p {
  color: #787878;
  font-size: 13.5px;
  font-weight: 400;
}
.main-topbar-furniture .top-bar span .mat-icon {
  color: #ffa726;
}
.main-topbar-furniture .top-bar .cur-icon {
  color: #787878 !important;
}
.main-topbar-furniture .company-email p {
  font-size: 14px;
  color: #333;
}

/***************************************
*  Header-2 styles
****************************************/
.home-header-seven {
  background: #ffffff;
}
.home-header-seven .logo {
  padding: 25px 0;
  margin-left: -15px;
}
.home-header-seven .search-form {
  width: 80%;
  margin: 0 auto;
  /* margin-top: 6px; */
  padding: 13px 0;
}
.home-header-seven .search-form .form-wrap {
  background-color: transparent;
  white-space: nowrap;
  zoom: 1;
  width: 100%;
  margin: 0;
  position: relative;
  background: #444;
  background: rgba(0, 0, 0, 0);
}
.home-header-seven .search-form .form-wrap input {
  border-style: none;
  border-width: 1px;
  border-radius: 70px;
  border: 1px solid #eeeeee;
  -webkit-transition: box-shadow 0.3s ease-out;
  transition: box-shadow 0.3s ease-out;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0 0 0 12px;
  float: left;
  outline: none;
  box-shadow: none;
}
.home-header-seven .search-form .form-wrap .btn-search {
  overflow: visible;
  position: absolute;
  border: 0;
  margin: 0;
  cursor: pointer;
  height: 100%;
  min-width: 50px;
  right: -15px;
  top: 0;
  padding: 0 35px;
  color: #ffffff;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -ms-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-transform: uppercase;
  background-color: var(--theme-deafult) !important;
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none !important;
  -webkit-appearance: none;
  border-radius: 0 50px 50px 0;
}
.home-header-seven .call-bucket-wrap .info-message-icon .icon mat-icon {
  font-size: 35px;
  /* color: #fff; */
  display: block;
  float: left;
  height: 49px;
  margin: 0 16px 0 0;
}
.home-header-seven .call-bucket-wrap .info-message-icon .info-text p {
  font-size: 12px;
  line-height: 0px;
  margin-bottom: 7px;
}
.home-header-seven .call-bucket-wrap .info-message-icon .info-text span {
  font-size: 20px;
  font-weight: 600;
}
.home-header-seven .call-bucket-wrap .main-menu-wrapper-2 .top-navbar {
  background: #ffffff;
  padding: 0;
}
.home-header-seven .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 22px;
}
.home-header-seven .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-button-wrapper .top-cart span {
  font-size: 15px;
}
.home-header-seven .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .commerce-buttons .mat-button-wrapper .cart-count-wrapper {
  position: absolute;
  top: -10px;
  left: 30px;
  background: var(--theme-deafult) !important;
  color: #ffffff;
  height: 18px;
  width: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-size: 11px;
}
.home-header-seven .widget-text a {
  color: #444;
  text-decoration: none;
  font-size: 13px;
  transition: 0.4s;
}
.home-header-seven .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
}
.home-header-seven .widget-text a:hover {
  color: var(--theme-deafult) !important;
}
.home-header-seven .widget-text:not(:last-child) {
  margin: 0 16px 0 0;
}
.home-header-seven .widget-text .mat-button-wrapper img {
  margin-right: 3px;
}
.home-header-seven .user .mat-icon {
  font-size: 26px !important;
}

/***************************************
*  Slider and menu styles
****************************************/
.carousel-furniture .ctn-box {
  box-sizing: content-box !important;
}
.carousel-furniture .ctn-box .main-slider {
  height: 503px;
}
.carousel-furniture .ctn-box .main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.carousel-furniture .ctn-box .main-slider .sl-wrap .content {
  padding-left: 20px;
  padding-right: 400px;
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
  transform: none;
}
.carousel-furniture .ctn-box .slider-categories {
  padding-right: 15px;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap {
  border: 1px solid #D9D9D9;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .title {
  background: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  color: #444;
  padding: 15px 20px 15px 10px;
  font-weight: 500;
  font-size: 14.5px;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .category-contant ul li {
  display: block;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .category-contant ul li a {
  padding-top: 12px;
  padding-bottom: 12px !important;
  border-top: 1px solid #D9D9D9;
  margin: 0 10px -1px;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .category-contant ul li .no-brd {
  border-top: none;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .btn-sale {
  margin: 15px 10px;
}
.carousel-furniture .ctn-box .slider-categories .categories-wrap .btn-sale button {
  padding: 5px 33px;
  width: 100%;
  box-shadow: none;
}

.main-menu {
  margin-left: -15px;
}
.main-menu a.mat-button {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  padding: 0 20px;
  line-height: 63px;
}

/***************************************
*  Products section styles
****************************************/
.products-furniture.products {
  position: relative;
}
.products-furniture.products h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 23px;
  font-weight: 580;
  position: absolute;
}
.products-furniture.products .mat-tab-labels {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

/***************************************
*  Banner section styles
****************************************/
.commercial-banner .image-column {
  background-repeat: no-repeat 90%;
  background-size: cover;
  align-items: center;
}
.commercial-banner .inner-column h1 {
  text-transform: capitalize;
  font-size: 52px;
  margin-bottom: 20px;
}
.commercial-banner .inner-column button {
  padding: 5px 33px;
  width: 200px;
  box-shadow: none !important;
}
@media only screen and (max-width: 50em) {
  .commercial-banner .image-column {
    grid-column: 1/-1;
    padding: 6rem;
  }
}
.commercial-banner .story__content {
  background-color: #f9f7f6;
  grid-column: col-start 5/full-end;
  padding: 6rem 8vw;
  /*
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        */
  display: grid;
  align-content: center;
  justify-items: start;
}
@media only screen and (max-width: 50em) {
  .commercial-banner .story__content {
    grid-column: 1/-1;
    grid-row: 5/6;
  }
}
.commercial-banner .story__text {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 4rem;
}

/***************************************
    ***************************************
    ***************************************
*  HOME - DECOR STYLES
****************************************
****************************************
****************************************/
/***************************************
*  Header section styles
****************************************/
.header-decor > div {
  width: 100%;
  background-color: #455a64;
}
.header-decor > div .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
  color: #ffffff;
}
.header-decor > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar {
  background: transparent !important;
}
.header-decor > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-icon {
  color: #ffffff;
}
.header-decor > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .top-cart span {
  color: #ffffff;
}

/***************************************
*  Carousel section styles
****************************************/
.carousel-home-decor {
  position: relative;
}
.carousel-home-decor .ctn-box {
  box-sizing: content-box !important;
  position: absolute;
  top: -64px;
  z-index: 9999;
}
.carousel-home-decor .main-slider {
  height: 640px;
}
.carousel-home-decor .main-slider .sl-wrap {
  position: relative;
  height: 100%;
}
.carousel-home-decor .main-slider .sl-wrap .content {
  padding-left: 21%;
  padding-right: 400px;
  height: 100%;
  position: relative;
  z-index: 9;
  /* padding-left: 294px; */
  vertical-align: middle;
  position: relative;
  /* top: 58%; */
  transform: none;
}
.carousel-home-decor .slider-categories {
  background-color: #fff;
}
.carousel-home-decor .slider-categories .categories-wrap {
  border: 1px solid #D9D9D9;
}
.carousel-home-decor .slider-categories .categories-wrap .title {
  background: #f5f5f5;
  border-bottom: 1px solid #dddddd;
  color: #444;
  padding: 19px 20px 19px 10px;
  font-weight: 500;
  font-size: 14.5px;
}
.carousel-home-decor .slider-categories .categories-wrap .title .mat-icon {
  margin-right: 4px;
}
.carousel-home-decor .slider-categories .categories-wrap .category-contant ul li {
  display: block;
}
.carousel-home-decor .slider-categories .categories-wrap .category-contant ul li a {
  padding-top: 12px;
  padding-bottom: 12px !important;
  border-top: 1px solid #D9D9D9;
  margin: 0 10px -1px;
}
.carousel-home-decor .slider-categories .categories-wrap .category-contant ul li .no-brd {
  border-top: none;
}
.carousel-home-decor .slider-categories .categories-wrap .btn-sale {
  margin: 15px 10px;
}
.carousel-home-decor .slider-categories .categories-wrap .btn-sale button {
  padding: 5px 33px;
  width: 100%;
  box-shadow: none;
}
.carousel-home-decor .carousel-2-wrapper {
  max-width: 100%;
  position: relative;
}
.carousel-home-decor .carousel-2-wrapper .main-slider {
  height: 640px;
}
.carousel-home-decor .carousel-2-wrapper .main-slider .content {
  padding-left: 23%;
  padding-right: 485px;
}
.carousel-home-decor .decor-menu .main-menu {
  margin-left: 293px;
}

/***************************************
*  Home baners section styles
****************************************/
.banners-decor {
  margin-top: -70px;
  z-index: 9999999999999;
  position: relative;
  background: #fff;
  border: 1px solid #e6e6e6;
}
.banners-decor .baners {
  background: #f5f5f5 !important;
}
.banners-decor .baner {
  padding: 10px 0 0 10px;
  height: 100%;
  transition: 0.15s ease-in;
  text-align: left;
}
.banners-decor .baner h4 {
  font-size: 19px;
  font-weight: 500;
  color: #2d2a25;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.banners-decor .baner .info .big-price {
  padding: 10px 25px !important;
  box-shadow: none !important;
  width: 135px;
  border-radius: 5px;
  text-align: center;
}
.banners-decor .baner .info .big-price span {
  font-size: 27px !important;
}
.banners-decor .baner p {
  color: #383838;
}
.banners-decor .baner a {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  color: #383838;
  margin-top: 15px;
  display: block;
}
.banners-decor .baner ul li a {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  color: #6b6b6b;
  margin-top: 5px;
  display: block;
}
.banners-decor .first {
  height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}
.banners-decor .first .info .text-box {
  text-align: center;
  padding: 15px 20px;
}
.banners-decor .first .info .text-box span {
  color: #fff;
  margin-bottom: 10px;
  line-height: 31px;
}
.banners-decor .first .info .text-box h4 {
  color: #fff;
  line-height: 27px;
}
.banners-decor .first .info .text-box a {
  color: #fff;
  font-weight: 500;
}
.banners-decor .seccond {
  /* height: 200px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0px;
}
.banners-decor .media {
  border-right: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
}
.banners-decor .electrics {
  border-left: 1px solid #D9D9D9;
}
.banners-decor .decors {
  border-bottom: 1px solid #D9D9D9;
}

/***************************************
*  Commercial banners section styles
****************************************/
.commercial-banners .image-column {
  background: #f6f6f6;
  padding: 0px 30px;
}
.commercial-banners .image-column h3 {
  margin-bottom: 20px;
}
.commercial-banners .image-column span {
  color: #bc0911;
  font-weight: 500;
}
.commercial-banners .image-column a {
  color: #333333;
  font-weight: 500;
  text-decoration: underline;
}
.commercial-banners .image-column .img img {
  max-width: 85%;
}

/***************************************
*  Home footer-2 section styles
****************************************/
.footer-two {
  background-color: #383838;
}
.footer-two .footer-widget {
  padding-right: 15px;
}
.footer-two .footer-widget .social {
  margin-top: 20px;
}
.footer-two .footer-widget .social li {
  width: 33px;
  height: 33px;
  border: 1px solid #c3c3c3;
  border-radius: 50px;
  text-align: center;
  margin-right: 5px;
}
.footer-two .footer-widget .social li a {
  color: #dddddd;
}
.footer-two .footer-widget .social li a i {
  color: #ffa726;
}
.footer-two .footer-widget .social li .mat-icon-button {
  padding: 0;
  min-width: 0;
  width: auto;
  height: auto;
  flex-shrink: 0;
  line-height: 32px;
  border-radius: 50%;
}
.footer-two .footer-widget .social li .mat-icon-button i {
  color: #f1f3f5;
}
.footer-two .footer-widget .ped-btn-footer {
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 20px;
  padding: 0px 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.4s ease 0s;
  z-index: 222;
  background: var(--theme-deafult) !important;
  box-shadow: none !important;
  width: 100%;
  border-radius: 3px;
  line-height: 45px;
}
.footer-two .footer-widget .title {
  margin-bottom: 10px;
}
.footer-two .footer-widget .title h5 {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.footer-two .footer-widget a {
  text-decoration: none;
  font-size: 15px;
  line-height: 32px;
  color: #dddddd;
}
.footer-two .footer-widget .newsletter-widget {
  position: relative;
  margin: 15px 0 20px 0;
}
.footer-two .footer-widget p {
  line-height: 24px;
  color: #dddddd;
}
.footer-two .footer-widget ul {
  list-style-type: none;
}
.footer-two .footer-widget .about-widget .logo img {
  max-width: 240px;
}
.footer-two .footer-widget .tel-box .icon i {
  display: block;
  line-height: 27px;
  vertical-align: middle;
  margin-right: 5px;
  color: var(--theme-deafult) !important;
  width: 18px;
  text-align: center;
  font-size: 18px;
}
.footer-two .footer-widget .tel-box .text p a {
  line-height: 22px;
}
.footer-two .footer-widget .mat-icon-button {
  padding: 0;
  min-width: 0;
  width: auto;
  height: auto;
  flex-shrink: 0;
  line-height: 32px;
  border-radius: 50%;
}
.footer-two .subscribe-widget input {
  height: 45px;
  background: #ffffff;
  font-size: 15px;
  line-height: 45px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  padding: 0 65px 0 20px;
  color: #777777;
}

/***************************************
*  Popular product page styles
****************************************/
.popular-products .reviews .content {
  height: auto;
  margin-bottom: 10px;
  margin-left: -15px !important;
}
.popular-products .reviews .content .media {
  margin-right: 7px;
  background: #f6f6f6;
  border-radius: 3px;
}
.popular-products .reviews .content .media img {
  max-width: 80px;
  margin-right: 8px;
}
.popular-products .reviews .content .info h5 {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  font-size: 17px;
  font-weight: 600;
  -webkit-transition: color 0.4s ease;
  transition: color 0.4s ease;
}
.popular-products .reviews .content .info .price {
  font-weight: 400;
}
.popular-products .title-header {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.popular-products .title-header h4::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #e5e5e5;
}
.popular-products .title-header h4::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #333333;
  bottom: 0.3125rem;
}

/***************************************
*  Login page styles
****************************************/
.login .login-wrap .title {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.login .login-wrap .title:before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #e5e5e5;
}
.login .login-wrap .title:after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #333333;
  bottom: 0.3125rem;
}
.login .login-wrap form .mat-form-field {
  width: 100%;
  margin-bottom: 10px;
  font-family: "Gotham", sans-serif !important;
}
.login .login-wrap .register-wrapper .register-body .mat-form-field {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}
.login .login-wrap .register-wrapper .register-body p {
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
}
.login .login-wrap .register-wrapper .register-body .button-wrap {
  margin-top: 20px;
}

/***************************************
*  Price slider styles
****************************************/
.price-slider .filter-price .mat-slider-horizontal {
  min-width: 110px;
}

/***************************************
*  Product dialog styles
****************************************/
.product-dialog .mat-dialog-container {
  overflow: visible;
}
.product-dialog .swiper-container .swiper-wrapper img.img-fluid {
  margin: auto;
  max-width: 100%;
}
.product-dialog .product-right {
  padding: 0 30px;
}
.product-dialog .product-right .product-details .discription {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed #dddddd;
  border-bottom: 1px dashed #dddddd;
}
.product-dialog .product-right .product-details .discription .bold {
  font-weight: 600;
}
.product-dialog .product-right .product-details .discription p.productDescription {
  line-height: 24px;
  margin-top: 5px;
}
.product-dialog .product-right .product-details h4 span {
  padding-left: 5px;
  color: #f33927;
  font-size: 25px;
}
.product-dialog .product-right .product-details h4 del {
  color: #777777;
  font-size: 18px;
}
.product-dialog .product-right .product-details h3.price {
  font-size: 26px;
  font-weight: 400;
  margin: 10px 0;
}
.product-dialog .product-right .quantity input {
  width: 80px;
  text-align: center;
  background-color: #f6f6f6;
  height: 35px;
  border: 1px solid #c3c3c3;
}
.product-dialog .product-right .quantity span {
  margin-bottom: 5px;
}
.product-dialog .product-right .avalibility .red {
  color: #f33927;
  font-weight: 500;
}
.product-dialog .product-right .buttons .mat-icon {
  color: #787878;
  margin-left: 17px;
  cursor: pointer;
}
.product-dialog .mat-dialog-container .close-btn-outer button {
  position: absolute;
  right: -44px;
  top: -44px;
}
.product-dialog .product-dialog .close-btn-outer {
  position: relative;
}

.product-pagination .ngx-pagination {
  margin-left: 0;
  margin-bottom: 1rem;
  text-align: center;
  padding: 10px 5px;
}

.product-pagination .ngx-pagination .current {
  padding: 4px 12px;
  background: #eeeeee;
  color: #444;
  cursor: default;
  border-radius: 2px;
  line-height: 22px;
}

.product-dialog .mat-dialog-content {
  max-width: 52vw;
}

/***************************************
*  Popular products styles
****************************************/
.popular-products .reviews .stars {
  color: #ffa726;
  margin: 0px 0 5px 0;
}
.popular-products .reviews .stars span {
  color: #c3c3c3;
  font-size: 13px;
  margin-left: 10px;
}
.popular-products .reviews .stars mat-icon {
  font-size: 17px;
  line-height: 17px;
  height: 17px;
  width: 17px;
  vertical-align: middle;
}

.mega-menu-pane .mat-menu-panel {
  width: auto !important;
}
.mega-menu-pane .mat-menu-panel .mat-menu-content {
  width: 100% !important;
}

/***************************************
*  Error page styles
****************************************/
.error {
  text-align: center;
}
.error .page404 {
  font-size: 67px;
  font-weight: 700;
  color: #777777;
  margin-bottom: 30px;
}
.error .not-found {
  font-size: 39px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 30px;
}
.error .error_description {
  margin-bottom: 30px;
}
.error .error_description p {
  font-weight: 600;
}
.error button {
  background: #ffa726;
  color: #333333;
  padding: 5px 33px;
  width: 200px;
}

/***************************************
*  FAQ page styles
****************************************/
.faq .questions {
  padding-right: 15px;
}
.faq .questions .faq-wrap .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid #dddddd;
  box-shadow: none;
  margin-bottom: -1px;
  box-shadow: none !important;
}
.faq .questions .faq-wrap .mat-expansion-panel .mat-expansion-panel-header-title span {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
}
.faq .questions .faq-wrap .mat-expansion-panel .mat-expansion-panel-header:hover span {
  color: #2a5ddf;
}
.faq .questions .faq-wrap .mat-expansion-panel .mat-expansion-panel-body p {
  line-height: 24px;
}
.faq .faq-form {
  padding-left: 15px;
}
.faq .faq-form .text-wrap p {
  line-height: 26px;
}
.faq .faq-form form {
  margin: 30px 0;
}

/***************************************
*  About us page styles
****************************************/
.about-us-wrapper .mat-expansion-panel-header:hover span {
  color: #2a5ddf;
}
.about-us-wrapper .about-section .left-info-panel {
  overflow: hidden;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro h2 {
  margin-bottom: 20px;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro p {
  line-height: 26px;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro .accordions {
  margin-top: 20px;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro .accordions .mat-expansion-panel {
  margin-bottom: 10px;
  box-shadow: none;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}
.about-us-wrapper .about-section .left-info-panel .about-us-intro .accordions .mat-expansion-panel h4 {
  font-weight: 500;
}
.about-us-wrapper .our-values {
  background: #f5f5f5;
}
.about-us-wrapper .our-values .box-wraper .box {
  padding-left: 15px;
  padding-right: 15px;
}
.about-us-wrapper .our-values .box-wraper .box .content {
  padding: 41px 20px 31px;
  background: #ffffff;
}
.about-us-wrapper .our-values .box-wraper .box .content .icon-box i {
  font-size: 55px;
  color: var(--theme-deafult);
}
.about-us-wrapper .our-values .box-wraper .box .content h4 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 5px;
  margin-bottom: 10px;
}
.about-us-wrapper .our-values .box-wraper .box .content p {
  line-height: 26px;
}
.about-us-wrapper .title-wrapper {
  text-align: center;
  margin-bottom: 35px;
}
.about-us-wrapper .title-wrapper h2 {
  margin-bottom: 10px;
}
.about-us-wrapper .title-wrapper p {
  color: #777777;
}
.about-us-wrapper .our-team .team-members .item {
  padding-left: 15px;
  padding-right: 15px;
}
.about-us-wrapper .our-team .team-members .item .single-team-member {
  transition: 0.3s;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box {
  position: relative;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box img {
  width: 100%;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box {
  display: table;
  width: 100%;
  height: 100%;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box .content {
  display: table-cell;
  vertical-align: bottom;
  overflow: hidden;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box .content ul {
  background: var(--theme-deafult);
  margin: 0;
  margin-left: -12px;
  margin-right: -12px;
  -webkit-transform: translate3d(0, 35px, 0);
  transform: translate3d(0, 35px, 0);
  -webkit-transition: -webkit-transform 0.4s ease, visibility 0.4s ease, opacity 0.4s ease;
  transition: transform 0.4s ease, visibility 0.4s ease, opacity 0.4s ease;
  visibility: hidden;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box .content ul li {
  padding: 0 12px;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .img-box .overlay .box .content ul li i {
  color: #ffffff;
  font-size: 16px;
  line-height: 35px;
  display: block;
}
.about-us-wrapper .our-team .team-members .item .single-team-member .text-box {
  border: 1px solid #f6f6f6;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .img-box img {
  opacity: 0.4;
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .img-box .overlay .box .content .social {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .text-box {
  background: #444;
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .text-box h4 {
  color: #ffffff;
}
.about-us-wrapper .our-team .team-members .item .single-team-member:hover .text-box p {
  color: #ffffff;
}

/***************************************
*  Order success page styles
****************************************/
.succesfull-message {
  text-align: center;
  padding: 50px 0;
}
.succesfull-message .material-icons {
  color: #4CAF50;
  font-size: 63px;
}
.succesfull-message h2 {
  margin: 10px 0;
}
.succesfull-message p {
  line-height: 30px;
}

.order-success .billing-details h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}
.order-success .billing-details .product-order-details .order-img img {
  max-width: 130px;
}
.order-success .billing-details .product-order-details p {
  font-size: 17px;
  font-weight: 500;
}
.order-success .billing-details .product-order-details span {
  font-size: 17px;
  margin-top: 5px;
}
.order-success .billing-details ul.price-list li {
  padding: 10px 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.order-success .billing-details ul.price-list li p {
  line-height: 30px;
  font-weight: 500;
}
.order-success .billing-details ul.price-list li span {
  position: relative;
  width: 35%;
  float: right;
  font-size: 18px;
  text-align: right;
  color: #4c4c4c;
}
.order-success .billing-details ul.price-list li img {
  max-width: 30px;
  height: 23px;
  margin: 0 10px;
}
.order-success .mainOrder-wrapper .main-order {
  background: #f3f3f3;
  padding: 30px;
  padding: 30px;
}
.order-success .mainOrder-wrapper .main-order .order-box .title-box {
  position: relative;
  padding-bottom: 25px;
  color: #444;
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}
.order-success .mainOrder-wrapper .main-order .order-box .title-box span.tl-amount {
  position: relative;
  width: 35%;
  float: right;
  line-height: 1.2em;
}
.order-success .mainOrder-wrapper .main-order .order-box ul.price-list {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.order-success .mainOrder-wrapper .main-order .order-box ul.price-list li {
  padding: 10px 0;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}
.order-success .mainOrder-wrapper .main-order .order-box ul.price-list li p {
  line-height: 30px;
  font-weight: 500;
}
.order-success .mainOrder-wrapper .main-order .order-box ul.price-list li span {
  position: relative;
  width: 35%;
  float: right;
  font-size: 18px;
  text-align: right;
  color: #4c4c4c;
}
.order-success .mainOrder-wrapper .main-order ul.total-amount {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.order-success .mainOrder-wrapper .main-order ul.total-amount .shipping {
  width: 35%;
  float: right;
}
.order-success .mainOrder-wrapper .main-order ul.total-amount .shipping .shopping-option {
  line-height: 30px;
}
.order-success .mainOrder-wrapper .main-order ul.total-amount li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.order-success .mainOrder-wrapper .main-order ul.total-amount li span.amount {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: #f33927;
  font-weight: 400;
  width: 35%;
  float: right;
}
.order-success .mainOrder-wrapper .main-order ul.quantity {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.order-success .mainOrder-wrapper .main-order ul.quantity li span.total {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: #f33927;
  font-weight: 400;
  width: 35%;
  float: right;
}
.order-success .mainOrder-wrapper .main-order ul.total {
  position: relative;
}
.order-success .mainOrder-wrapper .main-order ul.total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.order-success .mainOrder-wrapper .main-order ul.total li span.count {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: #f33927;
  font-weight: 400;
  width: 35%;
  float: right;
  text-align: right;
}
.order-success .mainOrder-wrapper .main-order .paymentFinal {
  margin-bottom: 20px;
  line-height: 34px;
}

/***************************************
*  Breadcrumb  styles
****************************************/
.breadcrumb-section {
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
}
.breadcrumb-section .breadcrumb-title h4 {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
}
.breadcrumb-section .breadcrumb-path .breadcrumb {
  background-color: transparent;
  justify-content: flex-end;
  text-transform: uppercase;
  font-size: 14px;
  color: #555;
  font-weight: 500;
  margin: 0;
  padding-right: 0;
}
.breadcrumb-section .breadcrumb-path .breadcrumb .breadcrumb-item {
  padding: 0 0.5rem;
}
.breadcrumb-section .breadcrumb-path .breadcrumb .breadcrumb-item:active {
  color: #6c757d;
}
.breadcrumb-section .breadcrumb-path .breadcrumb a {
  color: #555;
}

/***************************************
*  Checkout page styles
****************************************/
.checkout .billing-details .mat-form-field {
  width: 100%;
}
.checkout .billing-details .add-info {
  margin-top: 15px;
}
.checkout .mainOrder-wrapper .main-order {
  border: 1px solid #D9D9D9;
  padding: 30px;
}
.checkout .mainOrder-wrapper .main-order .order-box .title-box {
  position: relative;
  padding-bottom: 25px;
  color: #444;
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order .order-box .title-box span.tl-amount {
  position: relative;
  width: 35%;
  float: right;
  line-height: 1.2em;
}
.checkout .mainOrder-wrapper .main-order .order-box ul.price-list {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order .order-box ul.price-list li {
  line-height: 44px;
}
.checkout .mainOrder-wrapper .main-order .order-box ul.price-list li span {
  position: relative;
  width: 35%;
  float: right;
  font-size: 20px;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount .shipping {
  width: 35%;
  float: right;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount .shipping .shopping-option {
  line-height: 30px;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.checkout .mainOrder-wrapper .main-order ul.total-amount li span.amount {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: #f33927;
  font-weight: 400;
  width: 35%;
  float: right;
}
.checkout .mainOrder-wrapper .main-order ul.quantity {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order ul.quantity li span.total {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: #f33927;
  font-weight: 400;
  width: 35%;
  float: right;
}
.checkout .mainOrder-wrapper .main-order ul.total {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.checkout .mainOrder-wrapper .main-order ul.total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.checkout .mainOrder-wrapper .main-order ul.total li span.count {
  position: relative;
  font-size: 20px;
  line-height: 20px;
  color: #f33927;
  font-weight: 400;
  width: 35%;
  float: right;
}
.checkout .mainOrder-wrapper .main-order .paymentFinal {
  margin-bottom: 20px;
  line-height: 34px;
}

.mat-radio-label-content {
  color: #848484;
  font-weight: 400;
}

/***************************************
*  Cart page styles
****************************************/
.cart-wrapper .mat-card {
  text-align: center;
}
.cart-wrapper .mat-card .cart-table.mat-table {
  display: block;
  overflow-x: auto;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row {
  min-height: 100px;
  min-height: 100px;
  border-bottom: 1px solid #eeeeee;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row .product-name {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row .grand-total {
  font-weight: 500;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row span:nth-child(3) {
  font-size: 16px;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row input {
  width: 80px;
  text-align: center;
  background-color: #eeeeee;
  height: 35px;
  border: 1px solid #e5e5e5;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row .price {
  font-size: 21px;
  font-weight: 500;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-row .total {
  font-size: 21px;
  font-weight: 500;
  color: #f33927;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-cell, .cart-wrapper .mat-card .cart-table.mat-table .mat-header-cell {
  flex: 1;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 14px;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-cell img, .cart-wrapper .mat-card .cart-table.mat-table .mat-header-cell img {
  width: 100px;
  cursor: pointer;
}
.cart-wrapper .mat-card .cart-table.mat-table .mat-cell mat-icon, .cart-wrapper .mat-card .cart-table.mat-table .mat-header-cell mat-icon {
  cursor: pointer;
}

.mat-row, .mat-header-row {
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  align-items: center;
  min-height: 48px;
  padding: 0 24px;
  min-width: 870px;
}

/***************************************
*  Compare page styles
****************************************/
.compare-component .table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.compare-component .table-responsive table {
  width: 100%;
  border: 1px solid #eeeeee;
}
.compare-component .table-responsive table thead .th-compare td {
  padding: 15px;
  vertical-align: top;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  background: #f6f6f6;
  font-weight: 600;
  font-size: 14px;
}
.compare-component .table-responsive table thead .th-compare a {
  cursor: pointer;
}
.compare-component .table-responsive table tbody tr th {
  padding: 15px;
  vertical-align: top;
  border-bottom: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  background: #f6f6f6;
  text-align: left;
}
.compare-component .table-responsive table tbody tr .product-name {
  width: 15%;
  font-size: 14px;
}
.compare-component .table-responsive table tbody tr td .description-compare {
  font-size: 14px;
  line-height: 22px;
}
.compare-component img.img-fluid.mb-4 {
  max-width: 100px;
}
.compare-component .empty-cart-cls h4 {
  font-weight: 500;
  color: #848484;
}
.compare-component .empty-cart-cls h3 {
  text-align: center;
}

.compare-component .table-responsive .table tbody tr td, .compare-component .table-responsive .table tbody tr th {
  padding: 15px;
  vertical-align: top;
  border-top: 1px solid #eeeeee;
}

/***************************************
*  Details page styles
****************************************/
.details-wrapper .product-image img {
  max-width: 100%;
}
.details-wrapper .product-right {
  padding: 0px;
}
.details-wrapper .product-right .product-details {
  padding-bottom: 15px;
  border-bottom: 1px dashed #dddddd;
}
.details-wrapper .product-right .product-details h2 {
  margin-bottom: 10px;
}
.details-wrapper .product-right .product-details h4 del {
  color: #777777;
  font-size: 17px;
}
.details-wrapper .product-right .product-details h4 span {
  padding-left: 5px;
  color: #f33927;
  font-size: 24px;
}
.details-wrapper .product-right .product-details .avalibility span {
  color: #f33927;
  font-weight: 500;
}
.details-wrapper .product-right .product-details p.description {
  line-height: 26px;
}
.details-wrapper .product-right .product-details h3.price {
  font-size: 26px;
  font-weight: 400;
  margin: 10px 0;
}
.details-wrapper .product-right .quantity {
  margin-top: 10px;
}
.details-wrapper .product-right .quantity span {
  margin-bottom: 7px;
  display: block;
}
.details-wrapper .product-right .quantity input {
  width: 80px;
  text-align: center;
  background-color: #ffffff;
  height: 35px;
  border: 1px solid #000000;
}
.details-wrapper .product-right .red {
  color: #f33927;
  font-weight: 700;
}
.details-wrapper .info-bar {
  border: 1px solid #dddddd;
  padding: 0 20px;
  margin-top: 0px !important;
}
.details-wrapper .info-bar .mat-icon {
  color: #333333;
  font-size: 41px;
  margin-right: 15px;
  line-height: 23px;
}
.details-wrapper .info-bar .content p {
  text-transform: capitalize;
  font-weight: 500;
}
.details-wrapper .info-bar .mat-card {
  box-shadow: none !important;
  padding: 20px 0;
  border-bottom: 1px solid #dddddd;
}
.details-wrapper .info-bar .mat-card.no-border {
  border-bottom: none;
}
.details-wrapper .review-wrapper .full-desc {
  padding: 20px;
}
.details-wrapper .review-wrapper .full-desc p {
  line-height: 26px;
}
.details-wrapper .review-wrapper .full-desc ul {
  margin-top: 15px;
  padding-left: 20px;
}
.details-wrapper .review-wrapper .full-desc ul li {
  margin-bottom: 7px;
}
.details-wrapper .review-wrapper .Reviews .reviews .name {
  font-weight: 500;
}
.details-wrapper .review-wrapper .Reviews .reviews p {
  line-height: 22px;
  white-space: normal;
}
.details-wrapper .review-wrapper .Reviews .reviews .text {
  margin-top: 10px;
}
.details-wrapper .review-wrapper .Reviews .reviews .mat-list-item .mat-list-item-content {
  align-items: start !important;
}
.details-wrapper .review-wrapper .Reviews .writte-reviews .mat-form-field {
  width: 100%;
}
.details-wrapper .products {
  padding-right: 30px;
}
.details-wrapper .products .title {
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 1.25rem;
}
.details-wrapper .products .title::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #f6f6f6;
}
.details-wrapper .products .title::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #222222;
  bottom: 0.3125rem;
}
.details-wrapper .sale-widget {
  height: 14%;
  margin-top: 25px;
  padding: 15px 20px;
  text-align: center;
  background-color: var(--theme-deafult);
}
.details-wrapper .sale-widget .sale-widget1 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.details-wrapper .sale-widget .sale-widget2 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  display: block;
  letter-spacing: 0.5;
}
.details-wrapper .sale-widget .discount {
  display: inline-block;
  color: #2d2a25;
  font-size: 77px;
  font-weight: 600;
  position: relative;
  letter-spacing: 0.5;
}
.details-wrapper .sale-widget .percent {
  display: inline-block;
  line-height: 31px;
}
.details-wrapper .sale-widget .percent span {
  display: block;
  font-size: 3.111em;
  font-weight: 500;
}
.details-wrapper .sale-widget p {
  color: #ffffff;
  line-height: 24px;
  font-weight: 300;
  font-size: 15px;
}
.details-wrapper .small-carousel {
  position: relative;
  margin-top: 10px;
}
.details-wrapper .small-carousel .swiper-container {
  height: 180px;
}
@media only screen and (max-width: 600px) {
  .details-wrapper .small-carousel .swiper-container {
    height: 190px;
  }
}
.details-wrapper .small-carousel .swiper-container .swiper-slide mat-card {
  padding: 0px;
  max-width: 100%;
  max-height: 100%;
}
.details-wrapper .small-carousel .swiper-container .swiper-slide mat-card img {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.details-wrapper .small-carousel .swiper-container .swiper-button-prev {
  position: absolute;
  top: 32%;
  left: 0px;
}
.details-wrapper .small-carousel .swiper-container .swiper-button-next {
  position: absolute;
  top: 32%;
  right: 0px;
}
.details-wrapper .sale-baner {
  background: transparent;
}
.details-wrapper .mat-expansion-panel {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border: 1px solid #dddddd;
  box-shadow: none !important;
  margin-bottom: 20px;
  background: transparent !important;
}

.product-slick.product-zoom.slick-initialized.slick-slider {
  z-index: 1;
}

.mat-list-base .mat-list-item.mat-3-line, .mat-list-base .mat-list-option.mat-3-line {
  height: 100% !important;
  margin-bottom: 20px;
}

/***************************************
*  Brands page styles
****************************************/
.brands-items .mat-radio-button {
  margin-bottom: 15px;
}
.brands-items .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: var(--theme-deafult-secondary) !important;
}

/***************************************
*  Categorie page styles
****************************************/
.category-contant ul li a {
  color: #777777;
  padding-bottom: 10px;
  display: block;
  font-size: 15px;
}

/***************************************
*  Contact us page styles
****************************************/
.contact-page iframe {
  width: 100%;
  height: 400px;
  border: none;
}
.contact-page .left-info-panel ul {
  list-style-type: none;
  padding: 0;
}
.contact-page .left-info-panel ul > *:not(:last-child) {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}
.contact-page .left-info-panel ul li i {
  float: left;
  font-size: 33px;
  line-height: 74px;
  margin-right: 7px;
  color: var(--theme-deafult);
}
.contact-page .left-info-panel ul li h4 {
  display: inline-block;
  font-size: 20px;
}
.contact-page .contact-form .title {
  margin-bottom: 20px;
}
.contact-page .contact-form .w-100 {
  width: 100% !important;
}
.contact-page .contact-form .mat-form-field {
  caret-color: var(--theme-deafult) !important;
  font-size: 14px !important;
}
.contact-page .contact-form .mat-form-field .mat-input-element {
  caret-color: var(--theme-deafult) !important;
}
.contact-page .contact-form .px-1 {
  margin-bottom: 15px;
}

@media screen and (max-width: 599px) {
  .contact-page .left-info-panel {
    padding-right: 0px;
    width: 100%;
  }
}
.sidenav .divider {
  margin: 10px 0;
}

:host {
  display: flex;
  flex-direction: column;
  outline: none;
  width: 100%;
}
:host .mat-list-item.active {
  background-color: mat-color(#ffa726, 50);
}
:host:hover > .mat-list-item:not(.expanded), :host:focus > .mat-list-item:not(.expanded) {
  background-color: mat-color(#ffa726, 100) !important;
}

.mat-list-item {
  padding: 10px 0;
  display: flex;
  width: auto;
}
.mat-list-item .mat-list-item-content {
  width: 100%;
}
.mat-list-item .routeIcon {
  margin-right: 40px;
}

.btn-default, .mat-button {
  color: #333333;
}

.header.mat-toolbar {
  box-shadow: 0 0 5px grey;
  z-index: 13;
  font-size: 20px;
  max-height: 64px;
  height: 64px;
  padding: 0px 10px;
}

.sub-container .mat-card {
  box-shadow: none !important;
}

.search_box-n {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.search_box-n .search_input {
  width: 85% !important;
}

.search_box-n .search_input input {
  height: 40px !important;
}

table.mat-elevation-z8 thead tr.b-b.ng-star-inserted {
  display: table-row !important;
}

table.mat-elevation-z8 tbody tr.b-b.ng-star-inserted {
  display: table-row !important;
}

.mat-dialog-content {
  width: 100%;
}

.example-form {
  max-width: 100% !important;
}

.mat-dialog-container {
  position: relative !important;
}

.mat-dialog-container .close-button {
  top: 0px !important;
  right: 0px !important;
}

.mat-icon.mat-warn {
  color: gray !important;
}

.vl {
  margin: unset;
  border-left: 1px solid #000;
  height: 30px;
  margin-top: 15px;
}

.c-info {
  text-align: center;
}

.c-info h3 {
  text-align: center;
}

.c-info a {
  background: none !important;
}

.c-info a:hover {
  background: none !important;
}

.c-info .mat-toolbar {
  background: none !important;
}

.c-info .mat-toolbar a i {
  width: 40px;
  height: 40px;
  background: #333;
  border-radius: 500px;
  font-size: 20px !important;
  line-height: 40px !important;
  color: #FFF !important;
}

.home-header .top-navbar .mat-button-wrapper {
  text-transform: uppercase;
}

.invoiceDialog .mat-dialog-container {
  margin: 20px 0px;
  padding: 0px;
}

.ds-summary-cart ::ng-deep .mat-drawer {
  width: 420px;
  padding: 0px;
}
.ds-summary-cart ::ng-deep .mat-icon-button {
  padding: 8px;
}

.mat-badge-content {
  background-color: var(--color1);
}

.cdk-global-overlay-wrapper {
  pointer-events: auto;
  overflow: auto;
}

.loyalty-rewards .faq-panel .mat-expansion-panel-header {
  flex-direction: row-reverse;
  height: auto;
}

.loyalty-rewards .faq-panel .mat-expansion-panel-header:hover {
  background: transparent !important;
}

.loyalty-rewards .faq-panel .mat-expansion-panel-header-title {
  font: 600 20px Nunito;
  margin-left: 10px;
}

@media (max-width: 589px) {
  .loyalty-rewards .faq-panel .mat-expansion-panel-header-title {
    font-size: 16px;
  }
}
@media (min-width: 1200px) and (max-width: 1480px) {
  .product-dialog .mat-dialog-content {
    max-width: 64vw;
  }
  .product-dialog .mat-dialog-content .product-right .buttons .btn-project {
    max-width: 125px;
    padding: 4px 18px !important;
    font-size: 10px !important;
  }
}
@media (max-width: 1199px) {
  .product-dialog .mat-dialog-content {
    max-width: 64vw;
  }
  .product-dialog .mat-dialog-content .product-right .buttons .btn-project {
    max-width: 115px;
    padding: 4px 18px !important;
    font-size: 10px !important;
  }
}
@media (min-width: 1200px) {
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 400px;
  }
}
@media (min-width: 1200px) and (max-width: 1280px) {
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 15px;
    left: 22px;
    font-size: 95%;
    z-index: 9999999999999;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 32px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 0;
    width: 97px;
    border-radius: 3px;
    line-height: 33px;
  }

  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 400px;
  }
}
@media (min-width: 960px) and (max-width: 960px) {
  .main-home3-wrapper {
    padding-left: 0 !important;
  }
  .main-home3-wrapper .info-bar {
    margin-top: 25px !important;
  }

  .carousel-3 .carousel-2-wrapper {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px !important;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
  .carousel-3 .slider-categories {
    max-height: 100% !important;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper {
    padding-left: 0;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .bn-img img {
    max-width: 50%;
  }
}
@media (min-width: 961px) {
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px !important;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
}
@media (max-width: 959px) {
  .mat-toolbar-row {
    height: 100% !important;
    padding: 15px !important;
  }

  .home-header .logo {
    padding: 0;
  }
  .home-header .search-form {
    padding: 0;
  }

  .home-5-wrap .categories .left-section {
    padding-right: 0;
    margin-bottom: 15px;
  }
  .home-5-wrap .categories .left-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .left-section .item .product-info ul {
    margin: 0;
  }
  .home-5-wrap .categories .right-section {
    padding-left: 0;
  }
  .home-5-wrap .categories .right-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .right-section .item .product-info ul {
    margin: 0;
  }

  .details-wrapper .product-right {
    padding: 0;
    margin-top: 25px;
  }

  .left-sidebar-wrapper .all-products {
    padding-left: 0;
  }

  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px !important;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
}
@media (min-width: 960px) and (max-width: 1199px) {
  .home-5-wrap .categories .left-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .left-section .item .product-info ul {
    margin: 0;
  }
  .home-5-wrap .categories .right-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .right-section .item .product-info ul {
    margin: 0;
  }

  .prod-three .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper {
    padding: 10px;
  }
  .prod-three .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
    font-size: 16px;
  }
  .prod-three .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap a {
    width: 27px;
    height: 27px;
    line-height: 35px;
  }

  .products .product-item .mat-card:not([class*=mat-elevation-z]) .product-img img {
    max-width: 131px;
  }

  .home-3-wrap .main-home3-wrapper h3 {
    top: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 15px;
    left: 22px;
    font-size: 95%;
    z-index: 9999999999999;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 32px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 0;
    width: 97px;
    border-radius: 3px;
    line-height: 33px;
  }

  .carousel-2 .main-slider .content {
    padding-right: 323px !important;
  }
  .carousel-2 .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-2 .main-slider .content h3 {
    font-size: 29px;
  }
}
@media (min-width: 769px) and (max-width: 991px) {
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 15px;
    left: 22px;
    font-size: 95%;
    z-index: 9999999999999;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 32px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 0;
    width: 97px;
    border-radius: 3px;
    line-height: 33px;
  }

  .slide-item .content h1 {
    font-size: 37px !important;
    letter-spacing: 0px;
    max-width: 190px;
  }
  .slide-item .content h3 {
    font-size: 29px;
  }

  .carousel-2 .main-slider .content {
    padding-right: 323px !important;
  }
  .carousel-2 .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-2 .main-slider .content h3 {
    font-size: 29px;
  }

  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }

  .home-header .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }

  .home-header-2 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }

  .home-header-4 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }

  .home-header-three .logo {
    margin: 0 auto;
  }

  .demo-wrapper .demo-showcase .demos .item.middle {
    margin: 0 auto !important;
    margin-bottom: 50px !important;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 350px !important;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 34px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 24px;
  }

  .checkout .billing-details {
    padding-right: 0 !important;
    margin-bottom: 20px;
  }
  .checkout .mainOrder-wrapper {
    padding-left: 0;
  }

  .carousel-2 .carousel-2-wrapper {
    max-width: 100%;
  }

  .details-wrapper .product-image {
    margin-bottom: 20px;
  }
  .details-wrapper .product-right {
    padding: 0px;
  }

  .home-3-wrap .container {
    padding: 0 15px;
  }
  .home-3-wrap .container .home3-sidebar {
    padding-right: 0px;
  }
  .home-3-wrap .main-home3-wrapper {
    padding-left: 0;
  }
  .home-3-wrap .main-home3-wrapper .info-bar {
    margin-top: 25px;
  }

  .banners-container .baners {
    background: #f5f5f5;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .banners-container .baners .first {
    width: 100%;
    background-size: contain;
  }
  .banners-container .baner h4.big-title {
    font-size: 25px;
  }
  .banners-container .seccond {
    margin-right: 0px;
    background-color: #f5f5f5;
    height: 332px;
  }
  .banners-container .pd-xs {
    margin-top: 10px;
  }
  .banners-container .mg-xs-0 {
    margin-left: 0;
  }

  .carousel-2 .carousel-2-wrapper {
    max-width: 100%;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content {
    padding-right: 323px !important;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }

  .carousel-3 .carousel-2-wrapper {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper {
    padding-left: 0px;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond {
    height: 100%;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info {
    height: 100%;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .bn-img img {
    width: auto;
    height: 100%;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .text-box h4 {
    font-size: 20px;
  }

  .carousel-2 .slider-categories {
    padding-right: 0px;
    margin-bottom: 10px;
  }

  .left-sidebar-wrapper .filter-sidenav {
    width: 100%;
  }
  .left-sidebar-wrapper .filter-sidenav .popular-products {
    margin-top: 0;
  }
  .left-sidebar-wrapper .filter-sidenav .toggles-wrapper .toggles {
    padding-right: 7px;
  }
  .left-sidebar-wrapper .filter-sidenav .toggles-wrapper .popular-products {
    padding-left: 0px;
  }
}
@media (max-width: 768px) {
  .crypto-value {
    display: flex;
    flex-direction: column;
  }

  .demo-wrapper .demo-showcase .demos .item.middle {
    margin: 0 auto !important;
    margin-bottom: 50px !important;
  }

  .left-sidebar-wrapper .all-products {
    padding-left: 0;
  }

  .home-header .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }

  .home-header-2 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }

  .home-header-4 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }

  .home-header-three .logo {
    margin: 0 auto;
  }

  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 15px;
    left: 22px;
    font-size: 95%;
    z-index: 9999999999999;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 32px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 0;
    width: 97px;
    border-radius: 3px;
    line-height: 33px;
  }

  .home-3-wrap .main-home3-wrapper .info-bar {
    margin-top: 25px;
  }

  .home-3-wrap .main-home3-wrapper h3 {
    top: 30px;
  }

  .slide-item .content {
    padding-left: 0px !important;
  }
  .slide-item .content h1 {
    font-size: 34px !important;
    letter-spacing: 0px;
    max-width: 190px;
  }
  .slide-item .content h3 {
    font-size: 24px;
  }

  .checkout .billing-details {
    padding-right: 0 !important;
    margin-bottom: 20px;
  }
  .checkout .mainOrder-wrapper {
    padding-left: 0;
  }

  .carousel-2 .carousel-2-wrapper {
    max-width: 100%;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content {
    padding-right: 323px !important;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 37px;
  }
  .carousel-2 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 29px;
  }
  .carousel-2 .details-wrapper .product-image {
    margin-bottom: 20px;
  }
  .carousel-2 .details-wrapper .product-right {
    padding: 0px;
  }
  .carousel-2 .demo-wrapper .demo-showcase .demos .item.middle {
    margin: 0 auto !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .carousel-2 .banners-container .baners {
    margin-right: 0;
  }
  .carousel-2 .home-header .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .carousel-2 .home-header-2 .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .carousel-2 .home-header-three .logo {
    margin: 0 auto;
    margin-bottom: 0;
  }
  .carousel-2 .main-slider .content {
    padding-left: 10px;
  }
  .carousel-2 .main-slider .content button {
    padding: 0;
    width: 100px;
  }
}
@media (max-width: 768px) and (max-width: 649px) {
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner {
    height: 116px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap {
    position: absolute;
    top: 12px;
    left: 15px;
    /* font-size: 95%; */
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap .main {
    font-size: 20px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner .text-wrap a {
    margin-top: 7px;
  }
  .home-3-wrap .main-home3-wrapper .home3-banner-wrap .banner img {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
  }

  .home-3-wrap .home3-sidebar {
    padding-right: 0px;
  }
  .home-3-wrap .main-home3-wrapper {
    padding-left: 0;
  }
  .home-3-wrap .main-home3-wrapper h3 {
    top: 30px;
  }

  .home-header .search-form {
    width: 95%;
    padding: 0;
  }

  .home-header-2 .search-form {
    width: 95%;
    padding: 0;
  }

  .home-header-three .search-form {
    width: 95%;
    padding: 0;
  }

  .home-header-four .search-form {
    width: 95%;
    padding: 0;
  }
}
@media (max-width: 768px) and (max-width: 599px) {
  .home-5-wrap .categories .left-section {
    padding-right: 0;
    margin-bottom: 0px;
  }
  .home-5-wrap .categories .left-section .item {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 15px;
  }
  .home-5-wrap .categories .left-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .left-section .item .product-info ul {
    margin: 0;
  }
  .home-5-wrap .categories .right-section {
    padding-left: 0;
  }
  .home-5-wrap .categories .right-section .item {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 15px;
    margin-top: 0px !important;
  }
  .home-5-wrap .categories .right-section .item .product-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home-5-wrap .categories .right-section .item .product-info ul {
    margin: 0;
  }

  .categories-menu-wrap .category-contant ul li {
    width: 100%;
    transition: 0.3s;
  }

  .info-bar-four-wrapper {
    border-bottom: none;
  }
  .info-bar-four-wrapper .info-bar-four {
    border: none;
  }
  .info-bar-four-wrapper .info-bar-four .card-wrap {
    padding: 0;
    border-bottom: 1px solid #e5e5e5;
  }
  .info-bar-four-wrapper .info-bar-four .card-wrap .mat-card {
    border: none;
  }

  .info-bar .card-wrap {
    padding: 0 !important;
  }

  .carousel-3 .carousel-2-wrapper {
    max-width: 100% !important;
    margin-bottom: 20px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-right: 320px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h1 {
    font-size: 34px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content h3 {
    font-size: 24px;
  }

  .carousel-3 .slider-categories .carousel-banners-wrapper {
    padding-left: 0px;
  }

  .main-slider {
    height: 280px;
  }

  .slide-item .content h1 {
    font-size: 24px;
    letter-spacing: 3px;
  }
  .slide-item .content h3 {
    font-size: 18px;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }

  .vertical-products .product-panel:not(:last-child) {
    padding-right: 0px;
    margin-bottom: 30px;
  }

  .mt-2 {
    margin-top: 20px;
  }

  .banners-container .seccond {
    margin: 10px 0 0 0;
  }

  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .text-box {
    width: 100% !important;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper .carousel-banner .seccond .info .bn-img img {
    width: 100%;
    height: auto;
  }

  .left-sidebar-wrapper .filter-sidenav {
    width: 100% !important;
  }

  .left-sidebar-wrapper .all-products {
    padding-left: 0px;
  }
  .left-sidebar-wrapper .all-products .col .product-item {
    margin-right: 0px;
  }

  .mat-toolbar-row {
    height: 100% !important;
    padding: 15px !important;
  }

  .cdk-global-overlay-wrapper .product-dialog {
    max-width: 80vw !important;
    min-width: 90% !important;
  }

  .product-dialog .mat-dialog-content {
    max-width: 73vw !important;
  }

  .product-dialog .mat-dialog-content {
    max-width: 52vw;
  }
  .product-dialog .mat-dialog-content .product-right {
    padding: 0px !important;
  }
  .product-dialog .mat-dialog-content .product-right .buttons .btn-project {
    padding: 3px 12px !important;
  }
}
@media (max-width: 768px) and (max-width: 480px) {
  .demo-wrapper .demo-showcase .demos .item .first {
    height: auto !important;
  }

  .checkout .mainOrder-wrapper .main-order {
    padding-left: 10px;
    padding-right: 10px;
  }
  .checkout .mainOrder-wrapper .main-order ul.total-amount .shipping {
    /* width: 35%; */
    /* float: right; */
    width: unset;
    float: unset;
    display: flex;
    flex-direction: column;
  }
  .checkout .mainOrder-wrapper .main-order ul.total-amount .shipping .shopping-option .mat-radio-group {
    display: flex;
    flex-direction: column;
  }
  .checkout .mainOrder-wrapper .main-order ul.price-list li {
    line-height: 29px !important;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .commerce-buttons .mat-button {
    padding-right: 0px !important;
  }

  .home-header .info-message-icon .info-text span {
    font-size: 15px !important;
  }

  .home-header-2 .info-message-icon .info-text span {
    font-size: 15px !important;
  }

  .slide-item .content h1 {
    font-size: 23px !important;
    letter-spacing: 0px;
    max-width: 190px;
  }
  .slide-item .content h3 {
    font-size: 19px !important;
    margin-bottom: 10px;
    letter-spacing: 0px;
  }

  .carousel-2 .carousel-2-wrapper .main-slider .content {
    padding-left: 10px;
    padding-right: 150px;
  }

  .carousel-3 .carousel-2-wrapper {
    max-width: 100% !important;
    margin-bottom: 20px;
  }
  .carousel-3 .carousel-2-wrapper .main-slider .content {
    padding-left: 10px;
    padding-right: 146px !important;
  }
  .carousel-3 .slider-categories .carousel-banners-wrapper {
    padding-left: 0px;
  }
}
body.dark {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #fff;
}
body.dark .mat-drawer-content {
  background-color: #2b2b2b;
}

.dark .color-options .configuration-content h6 {
  color: #777 !important;
}
.dark .color-options .configuration-content h5 {
  color: #383838 !important;
}
.dark .main-slider .sl-wrap .content h3 {
  color: #333 !important;
}
.dark .mat-card {
  background: transparent !important;
}
.dark h2, .dark h3, .dark h4, .dark h5, .dark h6 {
  color: #f1f3f5 !important;
}
.dark .mat-tab-labels .mat-tab-label-content {
  color: #f1f3f5;
}
.dark .product-item-2 .mat-card:not([class*=mat-elevation-z]) .price-wrap p, .dark .product-item-1 .mat-card:not([class*=mat-elevation-z]) .price-wrap p {
  font-size: 19px;
  color: #f1f3f5;
}
.dark .info-bar {
  border: 1px solid #383636;
}
.dark .info-bar .card-wrap .mat-card {
  border-right: 1px solid #383636;
}
.dark .info-bar .card-wrap .mat-card .content p {
  font-weight: 600;
  font-size: 14.5px;
  color: #f1f3f5;
}
.dark .info-bar .card-wrap .mat-card.no-bd {
  border-right: none;
}
.dark .info-bar .card-wrap .mat-icon {
  color: var(--theme-deafult);
}
.dark .home-3-wrap .main-home3-wrapper .info-bar {
  border: 1px solid #383636;
}
.dark .home-3-wrap .main-home3-wrapper .info-bar .card-wrap .mat-card {
  border-right: 1px solid #383636;
}
.dark .home-3-wrap .brands {
  border: 1px solid #484848;
}
.dark .left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  background: transparent;
}
.dark .left-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  color: #f1f3f5;
}
.dark .left-sidebar-wrapper .all-products select {
  color: #f1f3f5;
}
.dark .left-sidebar-wrapper .all-products input {
  color: #f1f3f5;
}
.dark .left-sidebar-wrapper .filter-row {
  border-bottom: 1px solid #3c3c3c;
  border-top: 1px solid #3c3c3c;
}
.dark .left-sidebar-wrapper .mat-expansion-panel {
  border: 1px solid #3c3c3c;
}
.dark .left-sidebar-wrapper .mat-expansion-panel span {
  color: #f1f3f5;
}
.dark .left-sidebar-wrapper .no-shadow {
  background: transparent;
}
.dark .right-sidebar-wrapper .all-products {
  background: transparent;
}
.dark .right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
  background: transparent;
}
.dark .right-sidebar-wrapper .all-products .col .product-item .mat-card:not([class*=mat-elevation-z]) .product-info-wrapper .price-wrap p {
  color: #f1f3f5;
}
.dark .right-sidebar-wrapper .all-products select {
  color: #f1f3f5;
}
.dark .right-sidebar-wrapper .all-products input {
  color: #f1f3f5;
}
.dark .right-sidebar-wrapper .filter-row {
  border-bottom: 1px solid #3c3c3c;
  border-top: 1px solid #3c3c3c;
}
.dark .right-sidebar-wrapper .mat-expansion-panel {
  border: 1px solid #3c3c3c;
}
.dark .right-sidebar-wrapper .mat-expansion-panel span {
  color: #f1f3f5;
}
.dark .right-sidebar-wrapper .no-shadow {
  background: transparent;
}
.dark .products .product-item .mat-card {
  background-color: transparent;
  box-shadow: none !important;
}
.dark .products .product-item .mat-card .product-info-wrapper .price-wrap p {
  color: #f1f3f5;
}
.dark .vertical-products .widget-column .title .widget-title::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  bottom: 0.34375rem;
  background-color: #404040;
}
.dark .vertical-products .widget-column .title .widget-title::after {
  content: "";
  left: 0;
  position: absolute;
  width: 2.5rem;
  height: 0.125rem;
  background-color: #9c9c9c;
  bottom: 0.3125rem;
}
.dark .vertical-products .widget-column .media-body span {
  color: #f1f3f5;
}
.dark .vertical-products .widget-column .media-body .price {
  color: #f1f3f5;
}
.dark .footer {
  background-color: #252525;
}
.dark .footer .footer-widget a {
  color: #D9D9D9;
}
.dark .footer .footer-widget p {
  color: #D9D9D9;
}
.dark .footer .footer-widget .tel-box .icon i {
  color: var(--theme-deafult);
}
.dark .footer .footer-widget .social li {
  border: 1px solid #848484;
}
.dark .footer .footer-widget .social li i {
  color: #e5e5e5;
}
.dark .carousel-2 .slider-categories .categories-wrap {
  border: 1px solid #484848;
}
.dark .carousel-2 .slider-categories .categories-wrap .category-contant ul li a {
  border-top: 1px solid #383636;
}
.dark .carousel-2 .slider-categories .categories-wrap .title {
  background: #252525;
  border-bottom: 1px solid #232323;
  color: #e0e0e0;
}
.dark .home3-sidebar .categories-wrap {
  border: 1px solid #484848;
}
.dark .home3-sidebar .categories-wrap .category-contant ul li a {
  border-top: 1px solid #383636;
}
.dark .home3-sidebar .categories-wrap .title {
  background: #252525;
  border-bottom: 1px solid #232323;
  color: #e0e0e0;
}
.dark .carousel-3 {
  background-color: #2b2b2b;
  padding: 25px 0;
}
.dark .home-3-wrap .main-home3-wrapper .products {
  background-color: #2b2b2b;
}
.dark .menu-container1 {
  background: #333;
  border-bottom: 1px solid #333;
}
.dark .menu-container1 a.mat-button {
  color: #f1f3f5;
}
.dark .info-bar-four-wrapper {
  margin-top: 0px;
  border: none;
  background: #2b2b2b;
  border-bottom: 1px solid #3a3a3a;
}
.dark .info-bar-four-wrapper .card-wrap .mat-card {
  border-right: 1px solid #383636;
}
.dark .info-bar-four-wrapper .card-wrap .mat-card p {
  color: #f1f3f5;
}
.dark .products .products-four h3::before {
  background-color: #404040;
}
.dark .products .products-four h3::after {
  background-color: #9c9c9c;
}
.dark .featured-products-wrap {
  background: #2b2b2b;
}
.dark .home-header-three {
  background: #2b2b2b;
  border-top: none;
}
.dark .home-header-three .call-bucket-wrap .main-menu-wrapper-3 .mat-icon {
  color: #f1f3f5;
}
.dark .home-header-three .call-bucket-wrap .main-menu-wrapper-3 .top-cart span {
  color: #f1f3f5;
}
.dark .topbar_four {
  background: #222222 !important;
}
.dark .topbar_four .widget-text a {
  color: #f1f3f5;
}
.dark .topbar_four .widget-text a .mat-icon {
  color: #f1f3f5;
}
.dark .menu-container4 {
  background: #2b2b2b;
  border-top: 1px solid #343434;
}
.dark .menu-container4 .main-menu a.mat-button {
  color: #f1f3f5;
}
.dark .popular-products .title-header h4::before {
  background-color: #373737;
}
.dark .popular-products .title-header h4::after {
  background-color: #828282;
}
.dark .popular-products .reviews .content .info .price {
  color: #f1f3f5;
}
.dark .home-5-wrap {
  background: #2b2b2b;
}
.dark .home-5-wrap .categories {
  background: #2b2b2b;
}
.dark .home-5-wrap .categories .title h3::before {
  background-color: #404040;
}
.dark .home-5-wrap .categories .title h3::after {
  background-color: #9c9c9c;
}
.dark .home-header-4 .call-bucket-wrap .info-message-icon .info-text span {
  color: #f1f3f5;
}
.dark .home-header-4 .call-bucket-wrap .info-message-icon .info-text p {
  color: #f1f3f5;
}
.dark .home-header-4 .call-bucket-wrap .info-message-icon .icon .mat-icon {
  color: #f1f3f5;
}
.dark .home-header-4 .call-bucket-wrap .top-navbar .mat-button-wrapper .mat-icon {
  color: #f1f3f5;
}
.dark .home-header-4 .call-bucket-wrap .top-navbar .mat-button-wrapper .top-cart span {
  color: #f1f3f5;
}
.dark .menu-container5 .container {
  border-top: 1px solid #464646;
}
.dark .menu-container5 .container a.mat-button {
  color: #f1f3f5;
}
.dark .mat-form-field-label-wrapper label {
  color: #c3c3c3;
}
.dark .about-us-wrapper .about-section .left-info-panel .about-us-intro p {
  color: #f1f3f5;
}
.dark .about-us-wrapper .about-section .left-info-panel .about-us-intro .accordions .mat-expansion-panel {
  background: transparent;
  border: 1px solid #505050;
}
.dark .about-us-wrapper .our-values {
  background-color: #2b2b2b;
}
.dark .about-us-wrapper .our-values .box-wraper .box .content {
  background: transparent;
  border: 1px solid #505050;
}
.dark .about-us-wrapper .our-values .box-wraper .box .content p {
  color: #f1f3f5;
}
.dark .about-us-wrapper .our-team .team-members .item .single-team-member .text-box p {
  color: #848484;
}
.dark .details-wrapper .product-image {
  background: #fff !important;
}
.dark .details-wrapper .small-carousel .mat-card {
  background: #fff !important;
}
.dark .details-wrapper .info-bar .mat-icon {
  color: #f1f3f5;
}
.dark .details-wrapper .info-bar .content p {
  color: #f1f3f5;
}
.dark .details-wrapper .info-bar .content span {
  color: #c3c3c3;
}
.dark .details-wrapper .products .title::before {
  background-color: #404040;
}
.dark .details-wrapper .products .title::after {
  background-color: #9c9c9c;
}
.dark .details-wrapper .product-right .avalibility {
  color: #848484;
}
.dark .details-wrapper p.description {
  color: #c3c3c3;
}
.dark .product-dialog .mat-dialog-container {
  overflow: visible;
  background: #1d1d1d;
}
.dark .product-dialog .swiper-container .swiper-wrapper img.img-fluid {
  background: #fff;
}
.dark .product-dialog .product-right .discription .bold {
  color: #b9b9b9;
}
.dark .product-dialog .product-right span {
  color: #eeeeee;
}
.dark .product-dialog .product-right .quantity input {
  background-color: transparent;
  border: 1px solid #525252;
  color: #fff;
}
.dark .menu-wraper-five {
  background: #2b2b2b;
}
.dark .menu-wraper-five a {
  color: #f1f3f5 !important;
}
.dark .menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form input {
  color: #f1f3f5;
}
.dark .menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .search-form i {
  color: #f1f3f5;
}
.dark .menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .flex-row-button .mat-button-wrapper .mat-icon {
  color: #f1f3f5;
}
.dark .menu-wraper-five .menu-container .call-bucket-wrap .top-navbar .commerce-buttons .flex-row-button .mat-button-wrapper .top-cart span {
  color: #f1f3f5;
}
.dark .food-product-section {
  background: url(/assets/images/background/download2.svg) no-repeat 90%;
  background-size: auto 75px;
  background-repeat: repeat-x;
  background-position: center 0;
}
.dark .food-product-section .head-title p {
  color: #f1f3f5;
}
.dark .food-product-section .prod-menu ul li .text p {
  color: #f1f3f5;
}
.dark .food-product-section .prod-menu ul li .icon svg {
  fill: #f1f3f5 !important;
}
.dark .food-product-section .prod-menu ul li .icon svg .cls-1 {
  stroke: #f1f3f5 !important;
}
.dark .food-product-section .product-wrapper .product-box .product-item mat-card {
  border: 1px solid #3e3e3e;
}
.dark .food-product-section .product-wrapper .product-box .product-item mat-card .product-img {
  background-color: #fff;
}
.dark .food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper {
  background: #252525;
}
.dark .food-product-section .product-wrapper .product-box .product-item mat-card .product-info-wrapper .title-wrap p {
  color: #f1f3f5;
}
.dark .about-products {
  background: #252525;
}
.dark .about-products p, .dark .about-products span {
  color: #f1f3f5;
}
.dark .main-topbar-furniture {
  background: #252525 !important;
}
.dark .main-topbar-furniture span, .dark .main-topbar-furniture p {
  color: #f1f3f5;
}
.dark .home-header-seven > div {
  width: 100%;
  background-color: #383838;
}
.dark .home-header-seven > div .widget-text a .mat-icon {
  vertical-align: middle;
  margin-right: 1px !important;
  font-size: 23px;
  color: #ffffff;
}
.dark .home-header-seven > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar {
  background: transparent !important;
}
.dark .home-header-seven > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .mat-icon {
  color: #ffffff;
}
.dark .home-header-seven > div .call-bucket-wrap .main-menu-wrapper-2 .top-navbar .top-cart span {
  color: #ffffff;
}
.dark .carousel-furniture {
  padding-top: 30px;
}
.dark .carousel-furniture .ctn-box .slider-categories .categories-wrap {
  border: 1px solid #484848;
}
.dark .carousel-furniture .ctn-box .slider-categories .categories-wrap .title {
  background: #252525;
  border-bottom: 1px solid #232323;
  color: #e0e0e0;
}
.dark .carousel-furniture .ctn-box .slider-categories .categories-wrap .category-contant ul li a {
  border-top: 1px solid #383636;
}
.dark .carousel-furniture .main-menu a.mat-button {
  color: #f1f3f5;
}
.dark .banners-container .baner h4 {
  color: #2d2a25 !important;
}
.dark .commercial-banner .story__content {
  background-color: #252525;
}
.dark .commercial-banner .story__content .inner-column h1 {
  color: #f1f3f5;
}
.dark .main-menu a.mat-button {
  color: #f1f3f5;
}
.dark .header-decor > div {
  width: 100%;
  background-color: #383838;
}
.dark .carousel-home-decor .slider-categories {
  background-color: #383838;
}
.dark .carousel-home-decor .slider-categories .categories-wrap {
  border: 1px solid #313131;
}
.dark .carousel-home-decor .slider-categories .categories-wrap .title {
  background: #252525;
  border-bottom: 1px solid #232323;
  color: #f1f3f5;
}
.dark .carousel-home-decor .slider-categories .categories-wrap .category-contant ul li a {
  border-top: 1px solid #4e4e4e;
  color: #e0e0e0;
}
.dark .banners-decor {
  border: 1px solid #828282;
}
.dark .banners-decor .baner h4 {
  color: #2d2a25 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-l-7 {
  margin-left: 7px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-7 {
  padding: 7px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.f-300 {
  font-weight: 300 !important;
}

.f-400 {
  font-weight: 400 !important;
}

.f-500 {
  font-weight: 500 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-static {
  position: static !important;
}

.o-hidden {
  overflow: hidden !important;
}

.o-visible {
  overflow: visible !important;
}

.o-auto {
  overflow: auto !important;
}

.d-block {
  display: block !important;
}

.di-block {
  display: inline-block !important;
}

.bgm-white {
  background-color: #fff !important;
}

.c-white {
  color: #fff !important;
}

.bgm-black {
  background-color: #000 !important;
}

.c-black {
  color: #000 !important;
}

.bgm-brown {
  background-color: #795548 !important;
}

.c-brown {
  color: #795548 !important;
}

.bgm-pink {
  background-color: #e91e63 !important;
}

.c-pink {
  color: #e91e63 !important;
}

.bgm-red {
  background-color: #f44336 !important;
}

.c-red {
  color: #f44336 !important;
}

.bgm-blue {
  background-color: #2196f3 !important;
}

.c-blue {
  color: #2196f3 !important;
}

.bgm-purple {
  background-color: #9c27b0 !important;
}

.c-purple {
  color: #9c27b0 !important;
}

.bgm-deeppurple {
  background-color: #673ab7 !important;
}

.c-deeppurple {
  color: #673ab7 !important;
}

.bgm-lightblue {
  background-color: #03a9f4 !important;
}

.c-lightblue {
  color: #03a9f4 !important;
}

.bgm-cyan {
  background-color: #00bcd4 !important;
}

.c-cyan {
  color: #00bcd4 !important;
}

.bgm-teal {
  background-color: #009688 !important;
}

.c-teal {
  color: #009688 !important;
}

.bgm-green {
  background-color: #4caf50 !important;
}

.c-green {
  color: #4caf50 !important;
}

.bgm-lightgreen {
  background-color: #8bc34a !important;
}

.c-lightgreen {
  color: #8bc34a !important;
}

.bgm-lime {
  background-color: #cddc39 !important;
}

.c-lime {
  color: #cddc39 !important;
}

.bgm-yellow {
  background-color: #ffeb3b !important;
}

.c-yellow {
  color: #ffeb3b !important;
}

.bgm-amber {
  background-color: #ffc107 !important;
}

.c-amber {
  color: #ffc107 !important;
}

.bgm-orange {
  background-color: #ff9800 !important;
}

.c-orange {
  color: #ff9800 !important;
}

.bgm-deeporange {
  background-color: #ff5722 !important;
}

.c-deeporange {
  color: #ff5722 !important;
}

.bgm-gray {
  background-color: #9e9e9e !important;
}

.c-gray {
  color: #9e9e9e !important;
}

.bgm-bluegray {
  background-color: #607d8b !important;
}

.c-bluegray {
  color: #607d8b !important;
}

.bgm-indigo {
  background-color: #3f51b5 !important;
}

.c-indigo {
  color: #3f51b5 !important;
}

.bg-black-trp {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.b-0 {
  border: 0 !important;
}

.w-100 {
  width: 100% !important;
}

.brd-2 {
  border-radius: 2px;
}

a {
  cursor: pointer;
}

.ds-summary-cart {
  width: 100%;
  min-width: 320px;
  max-width: 404px;
}
.ds-summary-cart .t-blue {
  color: var(--color1);
}
.ds-summary-cart mat-datepicker-toggle button {
  position: absolute;
  left: -52px;
  bottom: 0px;
  opacity: 0;
}
.ds-summary-cart .mat-icon {
  color: rgba(0, 0, 0, 0.45) !important;
}
.ds-summary-cart .ordersumary_heading {
  padding: 0px 16px;
}
.ds-summary-cart .md-3-line > .md-no-style:before,
.ds-summary-cart .md-3-line:before {
  min-height: 40px;
}
.ds-summary-cart .md-3-line,
.ds-summary-cart .md-3-line > .md-no-style {
  min-height: 40px;
}
.ds-summary-cart h3 {
  margin: 0 0 6px;
  line-height: 0.75em;
  font-size: 25px;
  font-weight: 600;
  color: #000000;
}
.ds-summary-cart p {
  margin: 0;
  line-height: 1.6em;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #000000;
}
.ds-summary-cart .md-secondary-container h3 {
  margin: 0 0 6px;
  line-height: 0.75em;
  font-size: 16px;
  font-weight: 600;
  color: #6a7379;
}
.ds-summary-cart .md-secondary-container p {
  margin: 0;
  line-height: 1.6em;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
}
.ds-summary-cart .mat-content {
  height: calc("100% - 100px");
  overflow: auto;
}
.ds-summary-cart .mat-content .list-item-head {
  margin-right: 0;
}
.ds-summary-cart .mat-content .list-item-head .md-3-line mat-icon {
  margin: 0 10px 0 0;
}
.ds-summary-cart .mat-content .list-item-head .total-coloumn h3 {
  margin: 0 0 6px;
  line-height: 0.75em;
  font-size: 16px;
  font-weight: 600;
}
.ds-summary-cart .mat-content .list-item-head .total-coloumn p {
  margin: 0;
  line-height: 1.6em;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
}
.ds-summary-cart .mat-content .mat-expansion-panel {
  box-shadow: none;
  padding-top: 10px;
}
.ds-summary-cart .mat-content .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0px 0px 0px 24px;
  height: auto;
}
.ds-summary-cart .mat-content .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0px 0px 16px 0px;
}
.ds-summary-cart .mat-content .mat-expansion-panel .panel1 .mat-expansion-indicator {
  margin-bottom: 25px;
  margin-right: 10px;
}
.ds-summary-cart .mat-content .mat-expansion-panel .panel2 .mat-expansion-indicator {
  margin-bottom: 180px;
  margin-right: 10px;
}
.ds-summary-cart .mat-content .list-item-head,
.ds-summary-cart .mat-content .items {
  padding: 0;
  min-height: auto;
}
.ds-summary-cart .mat-content .list-item-head .-text,
.ds-summary-cart .mat-content .items .-text {
  padding: 0;
}
.ds-summary-cart .mat-content .list-item-head .-text h3,
.ds-summary-cart .mat-content .items .-text h3 {
  color: var(--color1);
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 6px;
  line-height: 0.75em;
}
.ds-summary-cart .mat-content .list-item-head .-text p,
.ds-summary-cart .mat-content .items .-text p {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  line-height: 1.6em;
}
.ds-summary-cart .mat-content .items {
  padding-left: 50px;
  padding-bottom: 15px;
  margin-top: 15px !important;
}
.ds-summary-cart .mat-content .items .cart-items__images {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin-right: 15px;
  flex: 0 0 90px;
}
.ds-summary-cart .mat-content .items .cart-items__images img {
  border-radius: 0;
  width: 90px;
  height: auto;
  margin: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.ds-summary-cart .mat-content .items .md-avatar:first-child,
.ds-summary-cart .mat-content .items .-inner > .md-avatar:first-child {
  border-radius: 0;
  width: 90px;
  height: 90px;
}
.ds-summary-cart .mat-content .items .md-secondary-container {
  top: 87%;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 10px;
}
.ds-summary-cart .mat-content .items .input-group {
  width: 105px;
  line-height: 0;
}
.ds-summary-cart .mat-content .items .input-group .input-group-btn button {
  background: #fff;
  border: 1px solid #ccc;
  width: 30px;
  height: 30px;
  box-shadow: none;
  cursor: pointer;
}
.ds-summary-cart .mat-content .items .input-group .input-control {
  background: #fff;
  border: 1px solid #ccc;
  width: 30px;
  height: 28px;
  text-align: center;
  color: #1c7bbf;
}
.ds-summary-cart .mat-content .items .md-button.remove-items {
  position: absolute;
  top: -10px;
  right: 0;
}
.ds-summary-cart .mat-content .items mat-divider {
  left: 50px;
  width: calc("100% - 50px");
  bottom: -1px;
}
.ds-summary-cart .mat-content .view-more .md-subheader-inner {
  padding: 6px 0 0;
}
.ds-summary-cart .mat-content .view-more .md-subheader-inner h3 {
  text-align: center;
  margin: 0;
}
.ds-summary-cart .mat-content .items.total .right {
  text-align: right;
  padding-right: 15px;
  margin-right: 0;
  margin-top: 0;
}
.ds-summary-cart .mat-content .md-subheader-content {
  padding: 16px 16px 16px 0px;
}
.ds-summary-cart .mat-content .md-subheader-content .matDate {
  padding-left: 10px;
}
.ds-summary-cart .mat-content .md-subheader-content .matDate .autoShipFreq {
  text-align: center;
  font-size: 11px;
}
.ds-summary-cart .mat-content .md-subheader-content .initOrder h3 {
  color: var(--color1);
  font-size: 15px;
}
.ds-summary-cart .mat-content .md-subheader-content .pV {
  font-size: 12px;
  color: #0000008A;
}
.ds-summary-cart .mat-content .md-subheader-content .autoShipOrder {
  padding: 0px;
}
.ds-summary-cart .mat-content .md-subheader-content .autoShipH3 h3 {
  color: var(--color1);
  font-size: 15px;
}
.ds-summary-cart .mat-content .md-subheader-content .subTotal {
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  line-height: 1.6em;
  color: #0000008A;
}
.ds-summary-cart .mat-content .helper_text {
  background-color: #f6f6f6;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
.ds-summary-cart .mat-content .mat-list-base {
  overflow-y: auto;
  height: 100vh;
}
.ds-summary-cart [ng-click] {
  cursor: pointer;
}
.ds-summary-cart .logButton {
  background-color: var(--color1);
  color: #FFFFFF;
  width: 80%;
  font-size: 14px;
  padding: 12px;
  text-align: center;
  border-radius: 40px;
}
.ds-summary-cart .checkOUTNewButton {
  background-color: var(--color1);
  color: #fff;
  min-width: 80%;
  max-width: 100%;
  font-size: 14px;
  padding: 12px;
  text-align: center;
  border-radius: 40px;
}
.ds-summary-cart .guestCheck {
  color: #2196F3;
  font-size: 14px;
}

.sidenavbar {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 350px;
  right: -360px;
  margin-bottom: 50px;
  background-color: white;
  top: 0;
  z-index: 999;
  box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
  transition: all 0.5s ease;
}
.sidenavbar.show {
  right: -2px;
  -webkit-transition: max-height 0.25s ease-in;
  -moz-transition: max-height 0.25s ease-in;
  transition: all 0.5s ease;
}

.iconColor {
  color: #0000008A;
}

.m-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-5 {
  margin: 5px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-l-7 {
  margin-left: 7px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-t-13 {
  margin-top: 13px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-8 {
  padding-top: 8px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.f-300 {
  font-weight: 300 !important;
}

.f-400 {
  font-weight: 400 !important;
}

.f-500 {
  font-weight: 500 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-static {
  position: static !important;
}

.o-hidden {
  overflow: hidden !important;
}

.o-visible {
  overflow: visible !important;
}

.o-auto {
  overflow: auto !important;
}

.d-block {
  display: block !important;
}

.di-block {
  display: inline-block !important;
}

.bgm-white {
  background-color: #fff !important;
}

.c-white {
  color: #fff !important;
}

.bgm-black {
  background-color: #000 !important;
}

.c-black {
  color: #000 !important;
}

.bgm-brown {
  background-color: #795548 !important;
}

.c-brown {
  color: #795548 !important;
}

.bgm-pink {
  background-color: #e91e63 !important;
}

.c-pink {
  color: #e91e63 !important;
}

.bgm-red {
  background-color: #f44336 !important;
}

.c-red {
  color: #f44336 !important;
}

.bgm-blue {
  background-color: #2196f3 !important;
}

.c-blue {
  color: #2196f3 !important;
}

.bgm-purple {
  background-color: #9c27b0 !important;
}

.c-purple {
  color: #9c27b0 !important;
}

.bgm-deeppurple {
  background-color: #673ab7 !important;
}

.c-deeppurple {
  color: #673ab7 !important;
}

.bgm-lightblue {
  background-color: #03a9f4 !important;
}

.c-lightblue {
  color: #03a9f4 !important;
}

.bgm-cyan {
  background-color: #00bcd4 !important;
}

.c-cyan {
  color: #00bcd4 !important;
}

.bgm-teal {
  background-color: #009688 !important;
}

.c-teal {
  color: #009688 !important;
}

.bgm-green {
  background-color: #4caf50 !important;
}

.c-green {
  color: #4caf50 !important;
}

.bgm-lightgreen {
  background-color: #8bc34a !important;
}

.c-lightgreen {
  color: #8bc34a !important;
}

.bgm-lime {
  background-color: #cddc39 !important;
}

.c-lime {
  color: #cddc39 !important;
}

.bgm-yellow {
  background-color: #ffeb3b !important;
}

.c-yellow {
  color: #ffeb3b !important;
}

.bgm-amber {
  background-color: #ffc107 !important;
}

.c-amber {
  color: #ffc107 !important;
}

.bgm-orange {
  background-color: #ff9800 !important;
}

.c-orange {
  color: #ff9800 !important;
}

.bgm-deeporange {
  background-color: #ff5722 !important;
}

.c-deeporange {
  color: #ff5722 !important;
}

.bgm-gray {
  background-color: #9e9e9e !important;
}

.c-gray {
  color: #9e9e9e !important;
}

.bgm-bluegray {
  background-color: #607d8b !important;
}

.c-bluegray {
  color: #607d8b !important;
}

.bgm-indigo {
  background-color: #3f51b5 !important;
}

.c-indigo {
  color: #3f51b5 !important;
}

.bg-black-trp {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.b-0 {
  border: 0 !important;
}

.w-100 {
  width: 100% !important;
}

.brd-2 {
  border-radius: 2px;
}

.t-uppercase {
  text-transform: uppercase;
}

.pad-right {
  padding-right: 4% !important;
}

.webalias-card {
  height: 290px !important;
}

.webalias-button {
  margin-top: 63px;
}

.b-b {
  border-bottom: 1px solid #e0e0e0 !important;
}

.border-top {
  border-top: 1px solid #e0e0e0 !important;
}

.f-s-15 {
  font-size: 15px !important;
}

.f-s-12 {
  font-size: 12px !important;
}

.p-t-50 {
  padding-top: 50px;
}

.cursor-pointer {
  cursor: pointer;
}

.b-full {
  border: 1px solid #e0e0e0;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  border-top-color: #f0f0f0;
  border-top-style: solid;
  border-top-width: 1px;
  line-height: 1.42857;
  padding-bottom: 13px;
  padding-right: 13px;
  padding-top: 13px;
  vertical-align: top;
}

.m-t--10 {
  margin-top: -10px !important;
}

.fadeMe {
  width: 100% !important;
  height: 100% !important;
  z-index: 99999999 !important;
  top: 0 !important;
  left: 0 !important;
  position: fixed !important;
}

.b-r {
  border-right: 1px solid #ccc;
}

.b-l {
  border-right: 1px solid #ccc;
}

.b-t {
  border-right: 1px solid #ccc;
}

.min-h-230 {
  min-height: 230px;
}

.t-view[data-tv-type=text]:before {
  content: "";
  background: #00bcd4;
  box-shadow: 0 0 0 1px #00bcd4;
}

.t-view[data-tv-type=text]:after {
  background: #00bcd4;
}

.t-view[data-tv-type=image]:before {
  content: "";
  background: #4caf50;
  box-shadow: 0 0 0 1px #4caf50;
}

.t-view[data-tv-type=image]:after {
  background: #4caf50;
}

.t-view[data-tv-type=video]:before {
  content: "";
  background: #ffc107;
  box-shadow: 0 0 0 1px #ffc107;
}

.t-view[data-tv-type=video]:after {
  background: #ffc107;
}

.t-view .tvb-stats {
  list-style: none;
  padding: 0;
  margin: 10px 0 20px;
}

.t-view .tvb-stats > li {
  display: inline-block;
  padding: 5px 10px 6px;
  border: 1px solid #ccc;
  margin-right: 2px;
}

.t-view .tvb-stats > li i {
  font-size: 15px;
  line-height: 100%;
  vertical-align: top;
  margin-top: 2px;
}

.t-view .tvb-stats > li.tvbs-comments {
  border-color: #4caf50;
  color: #4caf50;
}

.t-view .tvb-stats > li.tvbs-likes {
  border-color: #03a9f4;
  color: #03a9f4;
}

.t-view .tvb-stats > li.tvbs-views {
  border-color: #ff9800;
  color: #ff9800;
}

.tv-comments .tvc-lists {
  padding: 0;
  list-style: none;
  margin: 0;
}

.tv-comments .tvc-lists > li {
  padding: 15px 20px;
  margin: 0;
  border-top: 1px solid #eee;
}

.tvc-more {
  color: #333;
  display: block;
  margin-bottom: -10px;
}

.tvc-more:hover {
  color: #000;
}

.tvc-more i {
  vertical-align: middle;
  margin-right: 5px;
}

.p-header {
  position: relative;
  margin: 0 -7px;
}

.p-header .actions {
  position: absolute;
  top: -18px;
  right: 0;
}

.p-menu {
  list-style: none;
  padding: 0 5px;
  margin: 0 0 30px;
}

.p-menu > li {
  display: inline-block;
  vertical-align: top;
}

.p-menu > li > a {
  display: block;
  padding: 5px 20px 5px 0;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
}

.p-menu > li > a > i {
  margin-right: 4px;
  font-size: 20px;
  vertical-align: middle;
  margin-top: -5px;
}

.p-menu > li:not(.active) > a {
  color: #4285F4;
}

.p-menu > li:not(.active) > a:hover {
  color: #333;
}

.p-menu > li.active > a {
  color: #000;
}

@media (max-width: 991px) {
  .p-menu .pm-search {
    margin: 20px 2px 30px;
    display: block;
  }

  .p-menu .pm-search input[type=text] {
    width: 100%;
    border: 1px solid #ccc;
  }
}
.p-menu .pms-inner {
  margin: -2px 0 0;
  position: relative;
  top: -2px;
  overflow: hidden;
  white-space: nowrap;
}

.p-menu .pms-inner i {
  vertical-align: top;
  font-size: 20px;
  line-height: 100%;
  position: absolute;
  left: 9px;
  top: 8px;
  color: #333;
}

.p-menu .pms-inner input[type=text] {
  height: 35px;
  border-radius: 2px;
  padding: 0 10px 0 40px;
}

@media (min-width: 768px) {
  .p-menu .pms-inner input[type=text] {
    border: 1px solid #fff;
    width: 50px;
    background: transparent;
    position: relative;
    z-index: 1;
    transition: all;
    transition-duration: 300ms;
  }

  .p-menu .pms-inner input[type=text]:focus {
    border-color: #DFDFDF;
    width: 200px;
  }
}
.photos {
  margin: -3px 0 0;
}

.photos .lightbox {
  margin: 0 -8px;
}

.photos:not(.p-timeline)[data-src] {
  padding: 3px;
  transition: all;
  transition-duration: 150ms;
}

.p-grid {
  list-style: none;
  padding: 0;
  margin: 0 0 23px;
  text-align: center;
}

.p-grid > li {
  display: inline-block;
  vertical-align: top;
  width: 30px;
  height: 30px;
  border: 1px solid #D5D5D5;
  line-height: 27px;
  font-size: 12px;
  color: #ABABAB;
  border-radius: 50%;
  cursor: pointer;
  letter-spacing: -1px;
  padding-right: 1px;
  margin: 0 2px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.p-grid > li:hover {
  border-color: silver;
}

.p-grid > li.active {
  color: #8C8C8C;
  border-color: #8C8C8C;
}

.p-timeline {
  position: relative;
  padding-left: 80px;
  margin-bottom: 75px;
}

.p-timeline[data-src] {
  float: left;
  width: 70px;
  height: 70px;
  margin: 0 3px 3px 0;
}

.contacts:not(.c-profile) {
  padding: 0 8px;
}

.contacts > [class*=col-] {
  padding: 0 10px;
}

.contacts .c-item {
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  margin-bottom: 24px;
}

.contacts .c-item .ci-avatar {
  display: block;
}

.contacts .c-item .ci-avatar img {
  width: 100%;
  border-radius: 2px 2px 0 0;
}

.contacts .ci-avatar {
  margin: -1px -1px 0;
}

.contacts .c-info {
  text-align: center;
  margin-top: 15px;
  padding: 0 5px;
}

.contacts .c-info strong {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.contacts .c-info small {
  color: #999;
  margin-top: 3px;
}

.contacts .c-info strong,
.contacts .c-info small {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.contacts .c-footer {
  border-top: 1px solid #e2e2e2;
  margin-top: 18px;
}

.contacts .c-footer > button {
  padding: 4px 10px 3px;
  display: block;
  width: 100%;
  text-align: center;
  color: #333;
  font-weight: 500;
  border-radius: 2px;
  background: #fff;
  border: 0;
}

.contacts .c-footer > button > i {
  font-size: 16px;
  vertical-align: middle;
  margin-top: -3px;
}

.z-depth-1 {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12) !important;
}

.z-depth-1-top {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12);
}

.z-depth-1-bottom {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16);
}

.z-depth-2 {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

.z-depth-2-top {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.19);
}

.z-depth-2-bottom {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
}

.z-depth-3 {
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.24), 0 17px 50px rgba(0, 0, 0, 0.19);
}

.z-depth-3-top {
  box-shadow: 0 17px 50px rgba(0, 0, 0, 0.19);
}

.z-depth-3-bottom {
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.24);
}

.z-depth-4 {
  box-shadow: 0 16px 28px rgba(0, 0, 0, 0.22), 0 25px 55px rgba(0, 0, 0, 0.21);
}

.z-depth-4-top {
  box-shadow: 0 25px 55px rgba(0, 0, 0, 0.21);
}

.z-depth-4-bottom {
  box-shadow: 0 16px 28px rgba(0, 0, 0, 0.22);
}

.z-depth-5 {
  box-shadow: 0 27px 24px rgba(0, 0, 0, 0.2), 0 40px 77px rgba(0, 0, 0, 0.22);
}

.z-depth-5-top {
  box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22);
}

.z-depth-5-bottom {
  box-shadow: 0 27px 24px rgba(0, 0, 0, 0.2);
}

.z-depth-animation .z-depth-1,
.z-depth-animation .z-depth-2,
.z-depth-animation .z-depth-3,
.z-depth-animation .z-depth-4,
.z-depth-animation .z-depth-5 {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.block-header {
  margin-bottom: 25px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .block-header {
    padding: 0 22px;
  }
}
@media screen and (max-width: 991px) {
  .block-header {
    padding: 0 18px;
  }
}
.block-header > h2 {
  font-size: 15px;
  color: #777;
  margin: 0;
  font-weight: 400;
  text-transform: uppercase;
}

.block-header > h2 > small {
  display: block;
  text-transform: none;
  margin-top: 8px;
  margin-bottom: 20px;
  color: #9E9E9E;
  line-height: 140%;
}

.block-header .actions {
  position: absolute;
  right: 10px;
  top: -5px;
  z-index: 5;
}

.block-header-alt h1,
.block-header-alt h2,
.block-header-alt h3,
.block-header-alt h4,
.block-header-alt h5 {
  color: #575757;
  font-weight: 100;
}

.actions {
  list-style: none;
  padding: 0;
  z-index: 2;
  margin: 0;
}

.actions > li {
  display: inline-block;
  vertical-align: baseline;
}

.actions > li > a,
.actions > a {
  width: 30px;
  height: 30px;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  position: relative;
}

.actions > li > a > i,
.actions > a > i {
  transition: color;
  transition-duration: 1300ms;
  color: #adadad;
  font-size: 20px;
}

.actions > li > a:hover > i,
.actions > a:hover > i {
  color: #000;
}

.actions > li > a:before,
.actions > a:before {
  left: 0;
  top: 0;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
  transition: all;
  transition-duration: 250ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 0;
  border-radius: 50%;
  opacity: 0;
  filter: alpha(opacity=0);
}

.actions > li > a:hover:before,
.actions > a:hover:before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
  filter: alpha(opacity=100);
}

.actions > li.open > a > i,
.actions.open > a > i {
  color: #000;
}

.actions > li.open > a:before,
.actions.open > a:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  filter: alpha(opacity=100);
}

.actions.actions-alt > li > a > i {
  color: #fff;
}

.actions.actions-alt > li > a > i:hover {
  color: #fff;
}

.actions.actions-alt > li.open > a > i {
  color: #fff;
}

.actions.open {
  z-index: 3;
}

.load-more {
  text-align: center;
  margin-top: 30px;
}

.load-more a {
  padding: 5px 10px 4px;
  display: inline-block;
  background-color: #f44336;
  color: #FFF;
  border-radius: 2px;
  white-space: nowrap;
}

.load-more a i {
  font-size: 20px;
  vertical-align: middle;
  position: relative;
  margin-right: 5px;
}

.load-more a:hover {
  background-color: #ea1c0d;
}

.a-title {
  color: #333;
  font-weight: 500;
}

.a-title:hover {
  color: #333;
  text-decoration: underline;
}

.container-alt {
  max-width: 1170px;
}

.ie-warning {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #000;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  font-family: "Courier New", Courier, monospace;
  padding: 50px 0;
}

.ie-warning p {
  font-size: 17px;
}

.ie-warning .iew-container {
  min-width: 1024px;
  width: 100%;
  height: 200px;
  background: #fff;
  margin: 50px 0;
}

.ie-warning .iew-download {
  list-style: none;
  padding: 30px 0;
  margin: 0 auto;
  width: 720px;
}

.ie-warning .iew-download > li {
  float: left;
  vertical-align: top;
}

.ie-warning .iew-download > li > a {
  display: block;
  color: #000;
  width: 140px;
  font-size: 15px;
  padding: 15px 0;
}

.ie-warning .iew-download > li > a > div {
  margin-top: 10px;
}

.ie-warning .iew-download > li > a:hover {
  background-color: #eee;
}

#footer .f-menu {
  display: block;
  width: 100%;
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
  margin-top: 8px;
}

#footer .f-menu > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

#footer .f-menu > li > a {
  color: #a2a2a2;
}

#footer .f-menu > li > a:hover {
  color: #777;
}

.pricing-table {
  max-width: 1170px;
  margin: 70px auto 0;
}

.invoice {
  /*min-width: 1100px;*/
  max-width: 1170px;
}

.i-logo {
  width: 150px;
}

.i-table .highlight {
  background-color: #eee;
  border-bottom: 1px solid #e6e6e6;
}

.i-table td.highlight {
  font-size: 14px;
  font-weight: 500;
}

.wall {
  max-width: 1000px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .wall {
    width: 600px;
  }
}
.wall-attrs {
  margin-bottom: 0;
}

.wa-stats {
  float: left;
}

.wa-stats > span {
  margin-right: -1px;
  padding: 7px 12px;
  border: 1px solid #cfcfcf;
  float: left;
  font-weight: 500;
}

.wa-stats > span.active {
  color: #4caf50;
}

.wa-stats > span:first-child {
  border-radius: 2px 0 0 2px;
}

.wa-stats > span:last-child {
  border-radius: 0 2px 2px 0;
}

.wa-stats > span > i {
  line-height: 100%;
  vertical-align: top;
  position: relative;
  top: 2px;
  font-size: 15px;
  margin-right: 2px;
}

.wa-users {
  float: right;
  padding: 0 !important;
  margin-right: -5px;
}

.wa-users > a {
  display: inline-block;
  margin-left: 2px;
}

.wa-users > a > img {
  width: 33px;
  height: 33px;
  border-radius: 50%;
}

.wa-users > a > img:hover {
  opacity: 0.85;
  filter: alpha(opacity=85);
}

.wcc-inner {
  resize: none;
  border-radius: 2px;
  background: #fff;
  color: #9A9A9A;
  cursor: pointer;
  position: relative;
}

.wcci-text {
  border: 1px solid #E4E4E4;
  display: block;
  width: 100%;
  resize: none;
  padding: 10px 15px;
}

.wcc-text-icon {
  position: absolute;
  right: 0;
  top: 2px;
}

.wall-comment-list {
  padding: 20px;
  background: #f7f7f7;
}

.wall-comment-list .media {
  position: relative;
}

.wall-comment-list .media:hover .actions {
  display: block;
}

.wall-comment-list .actions {
  display: none;
  position: absolute;
  right: -20px;
  top: -1px;
}

.wcl-list + .wcl-form {
  margin-top: 25px;
}

.wp-text {
  border: 0;
  padding: 0;
  display: block;
  width: 100%;
  resize: none;
}

.wp-media {
  background: #f7f7f7;
  border: 1px solid #E4E4E4;
  padding: 12px 15px;
  margin-top: 25px;
  text-align: center;
}

.wpb-actions {
  background: #f7f7f7;
  margin: 0;
  padding: 10px 20px;
}

.wpb-actions > li:not(.pull-right) {
  float: left;
}

[data-wpba=image] {
  color: #4caf50;
}

[data-wpba=image]:hover {
  color: #449d48;
}

[data-wpba=video] {
  color: #ff9800;
}

[data-wpba=video]:hover {
  color: #e68900;
}

[data-wpba=link] {
  color: #00bcd4;
}

[data-wpba=link]:hover {
  color: #00a5bb;
}

.wpba-attrs > ul > li {
  padding: 0;
  margin-right: 5px;
}

.wpba-attrs > ul > li > a {
  display: block;
  width: 22px;
}

.wpba-attrs > ul > li > a > i {
  font-size: 20px;
}

.wpba-attrs > ul > li > a.active > i {
  color: #333;
}

.wall-img-preview {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .wall-img-preview {
    margin: 0 -23px 20px;
  }
}
@media screen and (max-width: 991px) {
  .wall-img-preview {
    margin: 0 -16px 20px;
  }
}
.wall-img-preview .wip-item {
  display: block;
  float: left;
  position: relative;
  overflow: hidden;
  border: 2px solid #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.wall-img-preview .wip-item > img {
  display: none;
}

.wall-img-preview .wip-item:first-child:nth-last-child(2),
.wall-img-preview .wip-item:first-child:nth-last-child(2) ~ div {
  width: 50%;
  padding-bottom: 40%;
}

.wall-img-preview .wip-item:first-child:nth-last-child(3),
.wall-img-preview .wip-item:first-child:nth-last-child(3) ~ div,
.wall-img-preview .wip-item:first-child:nth-last-child(4),
.wall-img-preview .wip-item:first-child:nth-last-child(4) ~ div:not(:last-child),
.wall-img-preview .wip-item:first-child:nth-last-child(5),
.wall-img-preview .wip-item:first-child:nth-last-child(5) ~ div:not(:nth-last-of-type(-n+2)),
.wall-img-preview .wip-item:first-child:nth-last-child(6),
.wall-img-preview .wip-item:first-child:nth-last-child(6) ~ div,
.wall-img-preview .wip-item:first-child:nth-last-child(7) ~ div:nth-last-of-type(-n+3) {
  width: 33.333333%;
  padding-bottom: 30%;
}

.wall-img-preview .wip-item:first-child:nth-last-child(5) ~ div:nth-last-of-type(-n+2) {
  width: 50%;
  padding-bottom: 40%;
}

.wall-img-preview .wip-item:first-child:nth-last-child(7),
.wall-img-preview .wip-item:first-child:nth-last-child(7) ~ div:not(:nth-last-of-type(-n+3)),
.wall-img-preview .wip-item:first-child:nth-last-child(n+8),
.wall-img-preview .wip-item:first-child:nth-last-child(n+8) ~ div {
  width: 25%;
  padding-bottom: 22%;
}

.wall-img-preview .wip-item:only-child,
.wall-img-preview .wip-item:first-child:nth-last-child(4) ~ div:nth-child(4) {
  width: 100%;
  padding-bottom: 50%;
}

@media print {
  @page {
    margin: 0;
    size: auto;
  }
  body {
    margin: 0 !important;
    padding: 0 !important;
  }

  #header, #footer, #sidebar, #chat, .growl-animated, .m-btn {
    display: none !important;
  }

  .invoice {
    padding: 30px !important;
    -webkit-print-color-adjust: exact !important;
  }

  .invoice .card-header {
    background: #eee !important;
    padding: 20px;
    margin: -60px -30px 25px;
  }

  .invoice .block-header {
    display: none;
  }

  .invoice .highlight {
    background: #eee !important;
  }
}
#calendar-widget {
  margin-bottom: 30px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}

#fc-actions {
  position: absolute;
  bottom: 10px;
  right: 12px;
}

.fc {
  background-color: #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 30px;
}

.fc td,
.fc th {
  border-color: #cfcfcf;
}

.fc th {
  font-weight: 400;
}

#calendar-widget .fc-toolbar {
  background: #009688;
}

#calendar-widget .fc-day-header {
  color: #fff;
  background: #007d71;
  padding: 5px 0;
  border-width: 0;
}

#calendar-widget .fc-day-number {
  text-align: center;
  color: #ADADAD;
  padding: 5px 0;
}

#calendar-widget .fc-day-grid-event {
  margin: 1px 3px;
}

#calendar-widget .ui-widget-header th,
#calendar-widget .ui-widget-header {
  border-width: 0;
}

#calendar .fc-toolbar:before {
  content: "";
  height: 50px;
  width: 100%;
  background: rgba(0, 0, 0, 0.36);
  position: absolute;
  bottom: 0;
  left: 0;
}

#calendar .fc-toolbar .fc-center {
  margin-top: 238px;
  position: relative;
}

@media screen and (max-width: 991px) {
  #calendar .fc-toolbar {
    height: 200px;
  }

  #calendar .fc-toolbar .fc-center {
    margin-top: 138px;
  }
}
#calendar .fc-day-header {
  color: #ADADAD;
  text-align: left;
  font-size: 14px;
  border-bottom-width: 0;
  border-right-color: #cfcfcf;
  padding: 10px 12px;
}

#calendar .fc-day-number {
  padding-left: 10px !important;
  color: #CCC;
  text-align: left !important;
}

@media screen and (min-width: 991px) {
  #calendar .fc-day-number {
    font-size: 25px;
    letter-spacing: -2px;
  }
}
#calendar .fc-day-grid-event {
  margin: 1px 9px 0;
}

.fc-today {
  color: #ffc107;
}

.fc-toolbar {
  margin-bottom: 0;
  padding: 20px 7px 19px;
  position: relative;
}

.fc-toolbar h2 {
  margin-top: 7px;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.fc-toolbar .ui-button {
  border: 0;
  background: 0 0;
  padding: 0;
  outline: none !important;
  text-align: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 2px;
  color: #fff;
}

.fc-toolbar .ui-button:hover {
  background: #fff;
  color: #009688;
}

.fc-toolbar .ui-button > span {
  position: relative;
  font-family: "Material-Design-Iconic-Font";
  font-size: 20px;
  line-height: 100%;
  width: 30px;
  display: block;
  margin-top: 2px;
}

.fc-toolbar .ui-button > span:before {
  position: relative;
  z-index: 1;
}

.fc-toolbar .ui-button > span.ui-icon-circle-triangle-w:before {
  content: "";
}

.fc-toolbar .ui-button > span.ui-icon-circle-triangle-e:before {
  content: "";
}

.fc-event {
  padding: 0;
  font-size: 11px;
  border-radius: 0;
  border: 0;
}

.fc-event .fc-title {
  padding: 2px 8px;
  display: block;
}

.fc-event .fc-time {
  float: left;
  background: rgba(0, 0, 0, 0.2);
  padding: 2px 6px;
  margin: 0 0 0 -1px;
}

.fc-view,
.fc-view > table {
  border: 0;
}

.fc-view > table > tbody > tr > .ui-widget-content {
  border-top: 0;
}

div.fc-row {
  margin-right: 0 !important;
  border: 0 !important;
}

/*.fc-today {
  color: #ffc107!important
}*/
.event-tag {
  margin-top: 5px;
}

.event-tag > span {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 3px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.event-tag > span:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.event-tag > span.selected:before {
  font-family: "Material-Design-Iconic-Font";
  content: "";
  position: absolute;
  text-align: center;
  top: 3px;
  width: 100%;
  font-size: 17px;
  color: #FFF;
}

hr.fc-divider {
  border-width: 1px;
  border-color: #eee;
}

.fc-day-grid-container.fc-scroller {
  height: auto !important;
  overflow: hidden !important;
}

.noUi-target {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

.noUi-background {
  background: #d4d4d4;
  box-shadow: none;
}

.noUi-horizontal {
  height: 3px;
}

.noUi-horizontal .noUi-handle {
  top: -8px;
}

.noUi-vertical {
  width: 3px;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  width: 19px;
  height: 19px;
  border: 0;
  border-radius: 100%;
  box-shadow: none;
  transition: box-shadow;
  transition-duration: 200ms;
  cursor: pointer;
  position: relative;
}

.noUi-horizontal .noUi-handle:before,
.noUi-vertical .noUi-handle:before,
.noUi-horizontal .noUi-handle:after,
.noUi-vertical .noUi-handle:after {
  display: none;
}

.noUi-horizontal .noUi-handle:active,
.noUi-vertical .noUi-handle:active {
  background: #ccc !important;
}

.noUi-horizontal .noUi-handle .is-tooltip,
.noUi-vertical .noUi-handle .is-tooltip {
  position: absolute;
  bottom: 32px;
  height: 35px;
  border-radius: 2px;
  color: #fff;
  text-align: center;
  line-height: 33px;
  width: 50px;
  left: 50%;
  margin-left: -25px;
  padding: 0 10px;
  transition: all;
  transition-duration: 200ms;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  transform: scale(0);
}

.noUi-horizontal .noUi-handle .is-tooltip:after,
.noUi-vertical .noUi-handle .is-tooltip:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 10px 0;
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin-left: -9px;
  content: "";
}

.noUi-horizontal .noUi-active,
.noUi-vertical .noUi-active {
  box-shadow: 0 0 0 13px rgba(0, 0, 0, 0.1);
}

.noUi-horizontal .noUi-active .is-tooltip,
.noUi-vertical .noUi-active .is-tooltip {
  -webkit-transform: scale(1);
  transform: scale(1);
  bottom: 40px;
  opacity: 1;
  filter: alpha(opacity=100);
}

.input-slider:not([data-is-color]) .noUi-handle,
.input-slider-range:not([data-is-color]) .noUi-handle,
.input-slider-values:not([data-is-color]) .noUi-handle,
.input-slider:not([data-is-color]) .noUi-connect,
.input-slider-range:not([data-is-color]) .noUi-connect,
.input-slider-values:not([data-is-color]) .noUi-connect {
  background: #009688 !important;
}

.input-slider:not([data-is-color]) .is-tooltip,
.input-slider-range:not([data-is-color]) .is-tooltip,
.input-slider-values:not([data-is-color]) .is-tooltip {
  background: #009688;
}

.input-slider:not([data-is-color]) .is-tooltip:after,
.input-slider-range:not([data-is-color]) .is-tooltip:after,
.input-slider-values:not([data-is-color]) .is-tooltip:after {
  border-color: #009688 transparent transparent;
}

.input-slider[data-is-color=red] .noUi-handle,
.input-slider-range[data-is-color=red] .noUi-handle,
.input-slider-values[data-is-color=red] .noUi-handle,
.input-slider[data-is-color=red] .noUi-connect,
.input-slider-range[data-is-color=red] .noUi-connect,
.input-slider-values[data-is-color=red] .noUi-connect {
  background: #f44336 !important;
}

.input-slider[data-is-color=blue] .noUi-handle,
.input-slider-range[data-is-color=blue] .noUi-handle,
.input-slider-values[data-is-color=blue] .noUi-handle,
.input-slider[data-is-color=blue] .noUi-connect,
.input-slider-range[data-is-color=blue] .noUi-connect,
.input-slider-values[data-is-color=blue] .noUi-connect {
  background: #2196f3 !important;
}

.input-slider[data-is-color=cyan] .noUi-handle,
.input-slider-range[data-is-color=cyan] .noUi-handle,
.input-slider-values[data-is-color=cyan] .noUi-handle,
.input-slider[data-is-color=cyan] .noUi-connect,
.input-slider-range[data-is-color=cyan] .noUi-connect,
.input-slider-values[data-is-color=cyan] .noUi-connect {
  background: #00bcd4 !important;
}

.input-slider[data-is-color=amber] .noUi-handle,
.input-slider-range[data-is-color=amber] .noUi-handle,
.input-slider-values[data-is-color=amber] .noUi-handle,
.input-slider[data-is-color=amber] .noUi-connect,
.input-slider-range[data-is-color=amber] .noUi-connect,
.input-slider-values[data-is-color=amber] .noUi-connect {
  background: #ffc107 !important;
}

.input-slider[data-is-color=green] .noUi-handle,
.input-slider-range[data-is-color=green] .noUi-handle,
.input-slider-values[data-is-color=green] .noUi-handle,
.input-slider[data-is-color=green] .noUi-connect,
.input-slider-range[data-is-color=green] .noUi-connect,
.input-slider-values[data-is-color=green] .noUi-connect {
  background: #4caf50 !important;
}

.input-slider .noUi-origin {
  background: #d4d4d4;
}

.input-slider:not([data-is-color]) .noUi-base {
  background: #009688 !important;
}

.input-slider[data-is-color=red] .noUi-base {
  background: #f44336 !important;
}

.input-slider[data-is-color=blue] .noUi-base {
  background: #2196f3 !important;
}

.input-slider[data-is-color=cyan] .noUi-base {
  background: #00bcd4 !important;
}

.input-slider[data-is-color=amber] .noUi-base {
  background: #ffc107 !important;
}

.input-slider[data-is-color=green] .noUi-base {
  background: #4caf50 !important;
}

.cp-container {
  position: relative;
}

.cp-container > .input-group input.cp-value {
  color: #000 !important;
  background: transparent !important;
}

.cp-container > .input-group .dropdown-menu {
  padding: 20px;
}

.cp-container i.cp-value {
  width: 25px;
  height: 25px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  right: 15px;
}

.note-editor,
.note-popover {
  border-color: #e4e4e4;
}

.note-editor .note-toolbar,
.note-popover .note-toolbar,
.note-editor .popover-content,
.note-popover .popover-content {
  background: #fff;
  border-color: #e4e4e4;
  margin: 0;
  padding: 10px 0 15px;
  text-align: center;
}

.note-editor .note-toolbar > .btn-group,
.note-popover .note-toolbar > .btn-group,
.note-editor .popover-content > .btn-group,
.note-popover .popover-content > .btn-group {
  display: inline-block;
  float: none;
  box-shadow: none;
}

.note-editor .note-toolbar > .btn-group .btn,
.note-popover .note-toolbar > .btn-group .btn,
.note-editor .popover-content > .btn-group .btn,
.note-popover .popover-content > .btn-group .btn {
  margin: 0 1px;
}

.note-editor .note-toolbar > .btn-group > .active,
.note-popover .note-toolbar > .btn-group > .active,
.note-editor .popover-content > .btn-group > .active,
.note-popover .popover-content > .btn-group > .active {
  background: #00bcd4;
  color: #fff;
}

.note-editor .note-toolbar .btn,
.note-popover .note-toolbar .btn,
.note-editor .popover-content .btn,
.note-popover .popover-content .btn {
  height: 40px;
  border-radius: 2px !important;
  box-shadow: none !important;
}

.note-editor .note-toolbar .btn:active,
.note-popover .note-toolbar .btn:active,
.note-editor .popover-content .btn:active,
.note-popover .popover-content .btn:active {
  box-shadow: none;
}

.note-editor .note-toolbar .note-palette-title,
.note-popover .note-toolbar .note-palette-title,
.note-editor .popover-content .note-palette-title,
.note-popover .popover-content .note-palette-title {
  margin: 0 !important;
  padding: 10px 0 !important;
  font-size: 13px !important;
  text-align: center !important;
  border: 0 !important;
}

.note-editor .note-toolbar .note-color-reset,
.note-popover .note-toolbar .note-color-reset,
.note-editor .popover-content .note-color-reset,
.note-popover .popover-content .note-color-reset {
  padding: 0 0 10px !important;
  margin: 0 !important;
  background: none;
  text-align: center;
}

.note-editor .note-toolbar .note-color .dropdown-menu,
.note-popover .note-toolbar .note-color .dropdown-menu,
.note-editor .popover-content .note-color .dropdown-menu,
.note-popover .popover-content .note-color .dropdown-menu {
  min-width: 335px;
}

.note-editor .note-statusbar .note-resizebar,
.note-popover .note-statusbar .note-resizebar {
  border-color: #E8E8E8;
}

.note-editor .note-statusbar .note-resizebar .note-icon-bar,
.note-popover .note-statusbar .note-resizebar .note-icon-bar {
  border-color: #BCBCBC;
}

.note-editor .fa,
.note-popover .fa {
  font-style: normal;
  font-size: 20px;
  vertical-align: middle;
}

.note-editor .fa:before,
.note-popover .fa:before {
  font-family: "Material Design Iconic Font";
}

.note-editor .fa.fa-magic:before,
.note-popover .fa.fa-magic:before {
  content: "";
}

.note-editor .fa.fa-bold:before,
.note-popover .fa.fa-bold:before {
  content: "";
}

.note-editor .fa.fa-italic:before,
.note-popover .fa.fa-italic:before {
  content: "";
}

.note-editor .fa.fa-underline:before,
.note-popover .fa.fa-underline:before {
  content: "";
}

.note-editor .fa.fa-font:before,
.note-popover .fa.fa-font:before {
  content: "";
}

.note-editor .fa.fa-list-ul:before,
.note-popover .fa.fa-list-ul:before {
  content: "";
}

.note-editor .fa.fa-list-ol:before,
.note-popover .fa.fa-list-ol:before {
  content: "";
}

.note-editor .fa.fa-align-left:before,
.note-popover .fa.fa-align-left:before {
  content: "";
  content: "";
}

.note-editor .fa.fa-align-right:before,
.note-popover .fa.fa-align-right:before {
  content: "";
}

.note-editor .fa.fa-align-center:before,
.note-popover .fa.fa-align-center:before {
  content: "";
}

.note-editor .fa.fa-align-justify:before,
.note-popover .fa.fa-align-justify:before {
  content: "";
}

.note-editor .fa.fa-indent:before,
.note-popover .fa.fa-indent:before {
  content: "";
}

.note-editor .fa.fa-outdent:before,
.note-popover .fa.fa-outdent:before {
  content: "";
}

.note-editor .fa.fa-text-height:before,
.note-popover .fa.fa-text-height:before {
  content: "";
}

.note-editor .fa.fa-table:before,
.note-popover .fa.fa-table:before {
  content: "";
}

.note-editor .fa.fa-link:before,
.note-popover .fa.fa-link:before {
  content: "";
}

.note-editor .fa.fa-picture-o:before,
.note-popover .fa.fa-picture-o:before {
  content: "";
}

.note-editor .fa.fa-minus:before,
.note-popover .fa.fa-minus:before {
  content: "";
}

.note-editor .fa.fa-arrows-alt:before,
.note-popover .fa.fa-arrows-alt:before {
  content: "";
}

.note-editor .fa.fa-code:before,
.note-popover .fa.fa-code:before {
  content: "";
}

.note-editor .fa.fa-question:before,
.note-popover .fa.fa-question:before {
  content: "";
}

.note-editor .fa.fa-eraser:before,
.note-popover .fa.fa-eraser:before {
  content: "";
}

.note-editor .fa.fa-square:before,
.note-popover .fa.fa-square:before {
  content: "";
}

.note-editor .fa.fa-circle-o:before,
.note-popover .fa.fa-circle-o:before {
  content: "";
}

.note-editor .fa.fa-times:before,
.note-popover .fa.fa-times:before {
  content: "";
}

.note-editor .note-air-popover .arrow,
.note-popover .note-air-popover .arrow {
  left: 20px;
}

.note-editor .note-editable {
  padding: 20px 23px;
}

.fileinput {
  position: relative;
  padding-right: 35px;
}

.fileinput .close {
  position: absolute;
  top: 5px;
  font-size: 12px;
  float: none;
  opacity: 1;
  font-weight: 500;
  border: 1px solid #ccc;
  width: 19px;
  text-align: center;
  height: 19px;
  line-height: 15px;
  border-radius: 50%;
  right: 0;
}

.fileinput .close:hover {
  background: #eee;
}

.fileinput .btn-file > input {
  z-index: 1000;
}

.fileinput .input-group-addon {
  padding: 0 10px;
  vertical-align: middle;
}

.fileinput .fileinput-preview {
  width: 200px;
  height: 150px;
  position: relative;
}

.fileinput .fileinput-preview img {
  display: inline-block;
  vertical-align: middle;
  margin-top: -13px;
}

.fileinput .fileinput-preview:after {
  content: "";
  display: inline-block;
  vertical-align: middle;
}

#lg-slider:after {
  content: "";
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: 2px solid #2196f3;
  -webkit-animation: ball-scale-ripple 1s 0 infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  animation: ball-scale-ripple 1s 0 infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 50%;
  margin-top: -25px;
  z-index: -1;
}

#lg-outer {
  background: rgba(255, 255, 255, 0.95);
}

#lg-outer .object {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}

#lg-close {
  display: none;
}

#lg-action {
  top: 0;
  width: 100%;
  left: 0;
  margin-left: 0 !important;
  height: 40px;
  text-align: center;
}

#lg-action > a {
  background: transparent;
  color: #9D9D9D;
  font-size: 18px;
  width: 28px;
  height: 37px;
}

#lg-action > a:hover {
  background: transparent;
  color: #000;
}

#lg-action .cl-thumb {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 38px;
  background: #f44336;
  transition: all;
  transition-duration: 300ms;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
}

#lg-action .cl-thumb:after {
  text-align: center;
  left: 16px !important;
  bottom: 6px !important;
  color: #fff;
}

#lg-action .cl-thumb:hover {
  background: #f32c1e;
}

#lg-gallery .thumb-cont {
  background: #f44336;
  text-align: center;
}

#lg-gallery .thumb-cont .thumb-info {
  background: #f44336;
}

#lg-gallery .thumb-cont .thumb-info .count {
  display: none;
}

#lg-gallery .thumb-cont .thumb-info .close {
  width: 14px;
  margin-top: 0;
  background: none;
}

#lg-gallery .thumb-cont .thumb-info .close:hover {
  background: none;
}

#lg-gallery .thumb-cont .thumb {
  opacity: 1;
  filter: alpha(opacity=100);
}

#lg-gallery .thumb-cont .thumb-inner {
  display: inline-block;
  padding: 12px 12px 15px;
}

.lg-slide {
  background: none !important;
}

.lg-slide em {
  font-style: normal;
}

.lg-slide em h3 {
  margin-bottom: 5px;
}

.lg-slide .video-cont {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
}

@-webkit-keyframes ball-scale-ripple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ball-scale-ripple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
.waves-button,
.waves-circle {
  -webkit-mask-image: none;
  line-height: inherit;
}

.date-picker .dp-inline {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  display: inline-block;
}

.date-picker:not(.dp-blue):not(.dp-red) .tr-dpnav th {
  background: #009688;
}

.date-picker:not(.dp-blue):not(.dp-red) .tr-dpday th {
  background: #00877a;
}

.date-picker:not(.dp-blue):not(.dp-red) .dp-active {
  background-color: #009688;
}

.date-picker:not(.dp-blue):not(.dp-red) .dp-actions .btn-link {
  color: #009688;
}

.date-picker .dropdown-menu {
  padding: 0;
  margin: 0;
}

.date-picker.is-opened .dropdown-menu {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  filter: alpha(opacity=100);
}

.dp-table {
  position: relative;
  z-index: 1;
}

.dp-table thead > tr th:first-child {
  padding-left: 15px;
}

.dp-table thead > tr th:last-child {
  padding-right: 15px;
}

.dp-table tbody > tr:first-child > td {
  padding-top: 15px;
}

.dp-table tbody > tr:last-child > td {
  padding-bottom: 15px;
}

.dp-table tbody > tr td:first-child {
  padding-left: 15px;
}

.dp-table tbody > tr td:last-child {
  padding-right: 15px;
}

.btn-dp {
  background: transparent;
  border: 0;
}

.btn-dp .zmdi {
  font-size: 18px;
  line-height: 100%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 34px;
}

.btn-dp .zmdi:hover {
  background-color: rgba(0, 0, 0, 0.14);
}

.btn-dp[disabled] {
  color: #CDCDCD;
}

.btn-dp[disabled] .dp-day-muted {
  color: #CDCDCD;
}

.btn-dpbody {
  padding: 13px;
  font-size: 12px;
  border-radius: 2px;
}

.btn-dpbody:not(.dp-active):not([disabled]) {
  color: #000;
}

.btn-dpbody:not(.dp-active):not([disabled]):not(.dp-today):hover {
  background: #F0F0F0;
}

.btn-dpday {
  border-radius: 50%;
  width: 42px !important;
  height: 42px;
}

.tr-dpnav .btn-dp {
  color: #fff;
  padding: 25px 0 23px;
}

.tr-dpday th {
  padding: 8px 0 9px;
  color: #fff;
  text-transform: uppercase;
}

.dp-active {
  color: #fff;
}

.dp-today:not(.dp-active) {
  background-color: #E2E2E2;
}

.dp-day-muted {
  color: #8B8B8B;
}

.dp-title {
  font-weight: 400;
  font-size: 16px;
  text-transform: uppercase;
}

.dp-title:hover {
  background-color: rgba(0, 0, 0, 0.14);
  border-radius: 2px;
}

.dp-blue .tr-dpnav th {
  background: #2196f3;
}

.dp-blue .tr-dpday th {
  background: #128ff2;
}

.dp-blue .dp-active {
  background-color: #2196f3;
}

.dp-blue .dp-actions .btn-link {
  color: #2196f3;
}

.dp-red .tr-dpnav th {
  background: #f44336;
}

.dp-red .tr-dpday th {
  background: #f33527;
}

.dp-red .dp-active {
  background-color: #f44336;
}

.dp-red .dp-actions .btn-link {
  color: #f44336;
}

.dp-actions {
  padding: 10px 20px 20px;
  text-align: right;
}

.dp-actions .btn-link {
  text-decoration: none;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
}

.ng-table th.sortable {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ng-table th.sortable:hover {
  background-color: #FDFBD3;
}

.ng-table .form-control {
  border: 1px solid #E4E4E4;
  padding: 10px 15px;
  height: 40px;
}

.ng-table .form-control:active,
.ng-table .form-control:focus {
  border-color: #D7D7D7 !important;
}

.ng-table-pager {
  padding: 12px 25px 15px;
  min-height: 101px;
}

@media screen and (min-width: 768px) {
  .ng-table-pager {
    background: #f9f9f9;
  }
}
.ng-table-counts {
  box-shadow: none !important;
  margin-top: 23px;
  border: 1px solid #E8E8E8;
}

@media screen and (max-width: 768px) {
  .ng-table-counts {
    display: none;
  }
}
.ng-table-counts .active {
  background: #F0F0F0;
}

@media screen and (max-width: 768px) {
  .ng-table-pagination {
    width: 100%;
    text-align: center;
  }
}
.ng-table-pagination > li {
  position: relative;
}

.ng-table-pagination > li:first-child:before,
.ng-table-pagination > li:last-child:before {
  font-family: "Material-Design-Iconic-Font";
  font-size: 22px;
  position: absolute;
  left: 16px;
  top: 5px;
  z-index: 12;
}

.ng-table-pagination > li:first-child > a,
.ng-table-pagination > li:last-child > a {
  font-size: 0;
}

.ng-table-pagination > li:first-child:before {
  content: "";
}

.ng-table-pagination > li:last-child:before {
  content: "";
}

.sortable .ng-table-header {
  position: relative;
}

.sortable .ng-table-header:before {
  font-family: "Material-Design-Iconic-Font";
  position: absolute;
  right: 0;
  top: -2px;
  font-size: 17px;
}

.sortable.sort-desc .ng-table-header:before {
  content: "";
}

.sortable.sort-asc .ng-table-header:before {
  content: "";
}

.timepicker-picker .btn {
  box-shadow: none !important;
}

.timepicker-picker table tbody tr + tr:not(:last-child) {
  background: #009688;
  color: #fff;
}

.timepicker-picker table tbody tr + tr:not(:last-child) td {
  border-radius: 0;
}

.timepicker-picker .btn {
  background: #fff;
  color: #333;
}

.datepicker.top {
  -webkit-transform-origin: 0 100% !important;
  transform-origin: 0 100% !important;
}

.datepicker table thead tr th {
  border-radius: 0;
  color: #fff;
}

.datepicker table thead tr th .glyphicon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 29px;
}

.datepicker table thead tr th:hover .glyphicon {
  background: rgba(0, 0, 0, 0.2);
}

.datepicker table thead tr:first-child th {
  background: #009688;
  padding: 20px 0;
}

.datepicker table thead tr:first-child th:hover {
  background: #009688;
}

.datepicker table thead tr:first-child th.picker-switch {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
}

.datepicker table thead tr:last-child th {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
}

.datepicker table thead tr:last-child th:first-child {
  padding-left: 20px;
}

.datepicker table thead tr:last-child th:last-child {
  padding-right: 20px;
}

.datepicker table thead tr:last-child:not(:only-child) {
  background: #00877a;
}

.datepicker table tbody tr:last-child td {
  padding-bottom: 25px;
}

.datepicker table tbody tr td:first-child {
  padding-left: 13px;
}

.datepicker table tbody tr td:last-child {
  padding-right: 13px;
}

.datepicker table td.day {
  width: 35px;
  height: 35px;
  line-height: 20px;
  color: #333;
  position: relative;
  padding: 0;
  background: transparent;
}

.datepicker table td.day:hover {
  background: none;
}

.datepicker table td.day:before {
  content: "";
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-bottom: -33px;
  display: inline-block;
  background: transparent;
  position: static;
  text-shadow: none;
}

.datepicker table td.day.old, .datepicker table td.day.new {
  color: #CDCDCD;
}

.datepicker table td:not(.today):not(.active):hover:before {
  background: #F0F0F0;
}

.datepicker table td.today {
  color: #333;
}

.datepicker table td.today:before {
  background-color: #E2E2E2;
}

.datepicker table td.active {
  color: #fff;
}

.datepicker table td.active:before {
  background-color: #009688;
}

.datepicker-months .month, .datepicker-years .year, .timepicker-minutes .minute, .timepicker-hours .hour {
  border-radius: 50%;
}

.datepicker-months .month:not(.active):hover, .datepicker-years .year:not(.active):hover, .timepicker-minutes .minute:not(.active):hover, .timepicker-hours .hour:not(.active):hover {
  background: #F0F0F0;
}

.datepicker-months .month.active, .datepicker-years .year.active, .timepicker-minutes .minute.active, .timepicker-hours .hour.active {
  background: #009688;
}

.timepicker-minutes .minute, .timepicker-hours .hour {
  padding: 0;
}

.checkbox > small {
  margin-top: 20px;
}

.m-l--5 {
  margin-left: -5px !important;
}

/*.col-lg-3 {
width:22% !important;
}*/
.tooltip {
  opacity: 1 !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.f-s {
  font-size: 23px !important;
}

@media (max-width: 360px) {
  #InOrder {
    float: left !important;
    margin-top: 10px;
  }

  #buttoncard {
    height: 98px !important;
  }

  #mobilecard {
    height: 390px !important;
  }
}
.product-grid__description {
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 4px;
  overflow: hidden;
  color: #5e5e5e;
  font-size: 16px;
}

.headmin-height {
  min-height: 170px;
}

.p-l-28 {
  padding-left: 28px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.f-bold {
  font-weight: 700;
}

.m-auto {
  margin: auto;
}

.f-s-18 {
  font-size: 18px;
}

.bottom-btn {
  bottom: 15px;
  position: fixed;
  right: 15px;
  z-index: 20;
}

.f-s-14 {
  font-size: 14px;
}

.f-s-16 {
  font-size: 16px;
}

.f-s-20 {
  font-size: 20px;
}

.f-s-24 {
  font-size: 24px !important;
}

@media (max-width: 1199px) {
  #user {
    margin-bottom: 20px !important;
  }
}
.m-l-23 {
  margin-left: 23px !important;
}

@media (max-width: 1199px) {
  #autoshipdiv {
    padding-right: 0px !important;
  }
}
.btn {
  text-transform: uppercase !important;
  width: auto;
}

#__lpform_input_idx_0_numspan, .__lpform_input_idx_0 {
  display: none !important;
}

.methoddropdown {
  width: 100%;
  background-color: transparent;
  color: #000000;
  text-align: left;
  padding: 6px 12px 0;
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border-color: currentcolor currentcolor var(--color1);
  border-style: none none solid;
  border-width: medium medium 2px;
}

.dropper {
  display: block;
  float: left;
  width: 88%;
}

.dropper_menu {
  width: 100%;
  padding-left: 3px;
}

.second_menu {
  padding-left: 18px;
  color: #999999;
  font-size: 14px;
}

.caret_arrow {
  float: right;
  font-size: 22px;
  text-align: center;
  width: 8%;
}

.caret_arrow i {
  color: #00c1d6;
}

.dropdown-menu.dropper_menu > li:hover {
  cursor: grab;
}

.col-height {
  height: 330px;
}

.ds-cart__page-container {
  min-height: 600px;
}

.iosDevice .modal-open {
  overflow: unset;
}

.iosDevice .modal {
  overflow-y: scroll;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.iosDevice .modal.fade .modal-dialog {
  z-index: 99999;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.iosDevice .modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.iosDevice .modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.iosDevice .modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

.iosDevice .modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.iosDevice .modal-backdrop.fade {
  filter: alpha(opacity=0);
}

.iosDevice .modal-backdrop.in {
  filter: alpha(opacity=50);
}

.iosDevice .modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857143px;
}

.iosDevice .modal-header .close {
  margin-top: -2px;
}

.iosDevice .modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.iosDevice .modal-body {
  position: relative;
  padding: 20px;
}

.iosDevice .modal-footer {
  margin-top: 15px;
  padding: 19px 20px 20px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.iosDevice .modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}

.iosDevice .modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.iosDevice .modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

@media (max-width: 420px) {
  .cart_mobile {
    margin-left: -30% !important;
    margin-top: -8% !important;
  }

  .cart_mobile .zmdi-shopping-cart {
    font-size: 21px !important;
  }

  .cart_mobile .cart-counter {
    width: 14px !important;
    height: 14px !important;
  }

  .menu_list {
    opacity: 1 !important;
    z-index: 9999 !important;
  }

  .ds-product-detail__body .img-box {
    width: 100% !important;
  }

  .ds-product-detail__body .share_box {
    text-align: left !important;
    margin-bottom: 2% !important;
  }

  .ds-product-detail__options__option {
    width: 100%;
  }

  .iosDevice .modal-backdrop {
    opacity: 0.5;
  }
}
@media (min-width: 768px) {
  .iosDevice .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }

  .iosDevice .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .iosDevice .modal-sm {
    width: 300px;
  }
}
@media (min-width: 992px) {
  .iosDevice .modal-lg {
    width: 900px;
  }
}
.flex-justify-end {
  justify-content: flex-end;
}

.rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

#product_update_card .ds-cart__image-wrapper {
  position: relative;
  border-bottom: 1px solid #d3d3d3;
}
#product_update_card .ds-cart__image-wrapper__sales {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 79px solid #1c7bbf;
  border-top: 79px solid var(--color1);
  border-bottom: 79px solid transparent;
  border-right: 79px solid transparent;
}
#product_update_card .ds-cart__image-wrapper__sales p {
  position: absolute;
  top: -60px;
  width: 79px;
  left: -15px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: block;
}
#product_update_card .ds-cart__image-wrapper .btn {
  position: absolute;
  height: 32px;
  bottom: 10px;
  border-radius: 2px;
  box-shadow: none;
}
#product_update_card .ds-cart__image-wrapper .zmdi-share-btn {
  left: 15px;
  width: 42px;
}
#product_update_card .ds-cart__image-wrapper .more_colors {
  right: 15px;
  width: 108px;
  font-size: 12px;
  font-weight: 400;
}
#product_update_card .ds-cart__image-wrapper .opacity_btn {
  opacity: 0.8;
}
#product_update_card .ds-cart__image-wrapper .ds-cart__image img {
  height: 306px;
  width: 306px;
}
#product_update_card .ds-cart-size {
  height: 394px !important;
  width: 306px !important;
  box-shadow: none;
}
#product_update_card .ds-cart__item-meta .product-name {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 0px 0px 0px;
}
#product_update_card .ds-cart__item-meta span {
  font-size: 14px;
}
#product_update_card .ds-cart__item-meta .product-color {
  color: #1c7bbf;
  color: var(--color1);
  text-align: left;
  width: 100%;
  font-weight: bold;
  padding: 0px 15px;
}
#product_update_card .ds-cart__item-meta .strikethrough {
  text-decoration: line-through;
  display: inline-block;
}
#product_update_card .ds-cart__item-meta .darkgray {
  color: darkgray;
}
#product_update_card .ds-cart__item-meta .zmdi-cart-plus-btn {
  border-radius: 5px;
  border: 2px solid #a5a5a5b0;
  box-shadow: none;
  color: #a5a5a5b0;
  font-weight: bold;
  padding: 5px 12px;
}

.mwo {
  max-width: 1340px !important;
}

.product-color {
  color: #1c7bbf;
  color: var(--color1);
  text-align: left;
  width: 100%;
  font-weight: bold;
  padding: 0px 15px;
}

.m-b-4 {
  margin-bottom: 4%;
}

.md-dropdown {
  position: relative;
}
.md-dropdown button {
  padding-right: 20px;
}
.md-dropdown .zmdi {
  position: absolute;
  right: 5px;
  top: 10px;
  display: inline-block;
  pointer-events: none;
}

#OrderView .selected_tab {
  border-bottom: 4px solid #4a4a4a !important;
  border-bottom: 2px solid #4a4a4a !important;
}
#OrderView .selected_tab > a {
  color: black !important;
}
#OrderView .tab-nav:not(.tn-vertical) {
  box-shadow: none !important;
}
#OrderView .tab-nav > li > a {
  font-weight: normal !important;
  text-transform: none !important;
  padding: 13px 13px 1px 13px !important;
}

.ds-container {
  max-width: 1304px;
  margin: 0 auto;
  padding-top: 40px !important;
  margin-top: 40px !important;
}

.md-tab {
  text-transform: inherit !important;
}

md-tabs.md-default-theme md-tabs-wrapper, md-tabs md-tabs-wrapper {
  background-color: #edecec;
  border-color: rgba(0, 0, 0, 0);
}

md-tabs .md-tab.md-active {
  color: #5d5e63;
}

.md-subheader.md-default-theme {
  background-color: white;
  font-size: 15px !important;
}

.md-subheader {
  background-color: #f6f6f6;
  font-size: 15px !important;
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
  background: #5d5e63;
}

.sort-dropdown {
  top: 92%;
  right: 1%;
  padding-bottom: 15px !important;
}

.m-t--15 {
  margin-top: -15px;
}

html, body {
  background: #edecec !important;
}

.sort-dropdown md-select {
  padding: 10px 2px 10px !important;
}

ordercartmodal md-backdrop {
  height: auto !important;
  position: fixed;
}

.f-right {
  float: right;
}

.margin-0 {
  margin: 0px !important;
}

.line_height_1 {
  line-height: 1.2em !important;
}

.bg-white {
  background-color: white;
}

.padding-0 {
  padding: 0px !important;
}

.ds-summary-cart md-toolbar md-list md-list-item .md-list-item-text h3 {
  font-weight: normal !important;
}

.c-light {
  color: rgba(0, 0, 0, 0.37);
}

.main_heading {
  font-size: 22px !important;
  margin-bottom: 0 !important;
}

.subheading_text {
  font-size: 12px !important;
}

p.main_price {
  font-size: 16px !important;
}

.main_qv {
  font-size: 12px;
}

h3.enroll_main_text {
  font-size: 15px !important;
}

p.enroll_main_total {
  font-size: 12px !important;
  font-weight: bold !important;
}

p.price_each {
  font-size: 15px !important;
}

p.f-right.qv_each {
  font-size: 12px !important;
  margin: 0 !important;
  font-weight: bold !important;
}

.product_detail_text {
  font-size: 15px !important;
  margin: 0 !important;
  line-height: 18px !important;
}

.product_detail_qv {
  font-size: 12px !important;
  overflow: auto;
}

.price_set p {
  margin-top: 26% !important;
  margin-left: -40% !important;
}

.price_set_diabled p {
  margin-top: 45px !important;
  margin-left: -40% !important;
}

.total_block {
  padding: 2%;
  padding-right: 15px !important;
  font-size: 14px;
}

.top_0 {
  top: 0 !important;
}

.pad-right {
  padding-right: 4% !important;
}

body.modal-open .modal-body {
  overflow: auto !important;
}

md-datepicker input {
  pointer-events: none;
}

.f-small {
  font-size: small !important;
}

.md-3-line.m-t-5 {
  margin-top: 5% !important;
}

.ds-cart__page .ds-checkbox + .help-block {
  margin-bottom: 0;
  position: relative;
  clear: both;
}

.width-100 {
  min-width: 100px !important;
}

.header-bg-color {
  background-color: #F0F0F0;
}

.ratings {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  color: #b1b1b1;
  overflow: hidden;
}

.full-stars {
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
  overflow: hidden;
  color: #fde16d;
}

.empty-stars:before, .full-stars:before {
  content: "★★★★★";
  font-size: 14pt;
}

.empty-stars:before {
  -webkit-text-stroke: 1px #848484;
}

.full-stars:before {
  -webkit-text-stroke: 1px orange;
}

/* Webkit-text-stroke is not supported on firefox or IE */
/* Firefox */
@-moz-document url-prefix() {
  .full-stars {
    color: #ECBE24;
  }
}
.full-stars {
  color: #ECBE24;
}

.c-red {
  color: #C6161D;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.carousel-inner > .item > img {
  width: 100%;
  height: 730px;
}
@media (min-width: 768px) and (max-width: 960px) {
  .carousel-inner > .item > img {
    height: 366px !important;
  }
}
@media (min-width: 500px) and (max-width: 767px) {
  .carousel-inner > .item > img {
    height: 250px !important;
  }
}
@media (min-width: 320px) and (max-width: 499px) {
  .carousel-inner > .item > img {
    height: 200px !important;
  }
}

.item > img {
  border: none !important;
}

.w-49 {
  width: 49%;
}

.m-r-2 {
  margin-right: 2%;
}

.m-t-2 {
  margin-top: 2%;
}

.m-b-2 {
  margin-bottom: 2%;
}

#zpimage-zoomContainer {
  z-index: 1 !important;
}

.md-button.disabled,
.md-button[disabled],
fieldset[disabled] .md-button {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.md-button.disabled,
fieldset[disabled] a.md-button {
  pointer-events: none;
}

.md-button.disabled, .md-button[disabled], fieldset[disabled] .md-button {
  cursor: not-allowed !important;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.md-button.disabled, fieldset[disabled] a.md-button {
  pointer-events: none;
}

.btn-disabled {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Sticky footer*/
.page-wrap {
  min-height: "calc(100vh - 260px)";
}

@media (max-width: 600px) {
  .product_detail_text {
    font-size: 13px !important;
    margin: 0 !important;
    line-height: 15px !important;
    padding-bottom: 5px;
  }

  .product_detail_qv.m-b-20 {
    margin-bottom: 4px !important;
  }
}
@media (max-width: 320px) {
  .ds-summary-cart md-content md-list-item.items {
    padding-left: 14px !important;
  }
}
.quantity_disable.disabled {
  background-color: #999999 !important;
  /* stylelint-disable-line declaration-no-important */
}

/* Loading bar custom styles */
#loading-bar .bar {
  height: 5px;
  background: #51c1E8;
}

#loading-bar .peg {
  box-shadow: #fff 1px 0 6px 1px;
}

#loading-bar-spinner {
  width: 56px;
  height: 56px;
  text-align: center;
  padding: 14px;
  border-radius: 50%;
  position: fixed;
  top: 105px;
  left: 50%;
  margin-left: -28px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  z-index: 9;
}

#loading-bar-spinner .spinner-icon {
  width: 28px;
  height: 28px;
  margin: 0;
  background: transparent !important;
  border: 2px solid #51c1e8;
  border-bottom-color: transparent !important;
  border-radius: 100%;
  animation: loading-bar-spinner 0.75s linear infinite;
}

/* end Loading bar custom styles */
/* Sweet Alert custom css*/
.sweet-alert {
  border-radius: 2px;
  padding: 10px 30px;
}

.sweet-alert h2 {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  z-index: 1;
}

.sweet-alert .lead {
  font-size: 13px;
}

.sweet-alert .btn {
  padding: 6px 12px;
  font-size: 13px;
  margin: 20px 2px 0;
}

.sweet-alert .form-group {
  display: none;
}

.sweet-alert .sa-confirm-button-container {
  display: inline-block;
  position: relative;
}

/* end sweet alert custom css*/
.text-nonecase {
  text-transform: none !important;
}

.text_overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.opacity-87 {
  opacity: 0.87 !important;
}

.opacity-54 {
  opacity: 0.54 !important;
}

.opacity-38 {
  opacity: 0.38 !important;
}

/* Product demo */
.ds-product-custom {
  border: none;
  margin: 7px 8px;
}

.c-1 {
  color: #000000DE;
}

:root {
  --color1: #00a9ef;
  --color2: #31b9f2;
  --color3: #0891cb;
  --color4: #404040;
  --color5: white;
  --color6: #eaf8fd;
  --color7: #b2e5fa;
  --e-global-color-primary: #9599A7;
  --e-global-color-secondary: #404040;
  --e-global-color-text: #FFFFFF;
  --e-global-color-accent: #FFFFFF2B;
  --e-global-color-d49ac81: #4BA6E2;
  --e-global-color-332724a: #D3EDF8;
  --e-global-color-4d462f5: #DCDCDE;
  --e-global-color-cfa1f76: #FFFFFF;
  --e-global-color-044b931: #404040;
  --e-global-color-bb890b8: #D3EDF8;
  --e-global-color-639e8dd: #4BA6E2;
  --e-global-color-7c932ec: #2FA6DE;
  --e-global-color-8824ac8: #F8B119;
  --e-global-color-bae28b2: #CD1582;
  --e-global-color-819df98: #075144;
  --e-global-color-a86432b: #4BA6E221;
  --e-global-typography-primary-font-family: "Gotham Bold";
  --e-global-typography-primary-font-size: 72px;
  --e-global-typography-primary-font-weight: 400;
  --e-global-typography-primary-line-height: 1em;
  --e-global-typography-primary-letter-spacing: -2px;
  --e-global-typography-secondary-font-family: "Gotham Book";
  --e-global-typography-secondary-font-size: 48px;
  --e-global-typography-secondary-font-weight: 300;
  --e-global-typography-secondary-line-height: 1.1em;
  --e-global-typography-text-font-family: "Inter";
  --e-global-typography-text-font-size: 16px;
  --e-global-typography-text-font-weight: 300;
  --e-global-typography-accent-font-family: "Inter";
  --e-global-typography-accent-font-size: 16px;
  --e-global-typography-accent-font-weight: 300;
  --e-global-typography-b2ce6af-font-family: "Inter";
  --e-global-typography-b2ce6af-font-size: 16px;
  --e-global-typography-b2ce6af-font-weight: 300;
  --e-global-typography-b2ce6af-text-transform: none;
  --e-global-typography-b2ce6af-font-style: normal;
  --e-global-typography-b2ce6af-line-height: 1.5em;
  --e-global-typography-69c152f-font-family: "Inter";
  --e-global-typography-69c152f-font-size: 14px;
  --e-global-typography-69c152f-font-weight: 300;
  --e-global-typography-69c152f-line-height: 1.6em;
  --e-global-typography-520c191-font-family: "Inter";
  --e-global-typography-520c191-font-size: 13px;
  --e-global-typography-520c191-font-weight: 600;
  --e-global-typography-520c191-text-transform: uppercase;
  --e-global-typography-520c191-letter-spacing: 1px;
  --e-global-typography-583e54c-font-family: "Gotham Bold";
  --e-global-typography-583e54c-font-size: 72px;
  --e-global-typography-583e54c-font-weight: 300;
  --e-global-typography-583e54c-line-height: 1em;
  --e-global-typography-583e54c-letter-spacing: -2px;
  --e-global-typography-5d167aa-font-family: "Gotham Book";
  --e-global-typography-5d167aa-font-size: 48px;
  --e-global-typography-5d167aa-font-weight: 300;
  --e-global-typography-5d167aa-line-height: 1.1em;
  --e-global-typography-5d167aa-letter-spacing: -2px;
  --e-global-typography-d3dae9a-font-family: "Gotham Book";
  --e-global-typography-d3dae9a-font-size: 36px;
  --e-global-typography-d3dae9a-font-weight: 300;
  --e-global-typography-d3dae9a-text-transform: none;
  --e-global-typography-d3dae9a-font-style: normal;
  --e-global-typography-d3dae9a-line-height: 1.2em;
  --e-global-typography-83682a1-font-family: "Libre Franklin";
  --e-global-typography-83682a1-font-size: 24px;
  --e-global-typography-83682a1-font-weight: 400;
  --e-global-typography-83682a1-text-transform: none;
  --e-global-typography-83682a1-font-style: normal;
  --e-global-typography-83682a1-line-height: 1.3em;
  --e-global-typography-7044a64-font-family: "Libre Franklin";
  --e-global-typography-7044a64-font-size: 18px;
  --e-global-typography-7044a64-font-weight: 500;
  --e-global-typography-7044a64-line-height: 1.4em;
  --e-global-typography-84ca66e-font-family: "Raleway";
  --e-global-typography-84ca66e-font-size: 18px;
  --e-global-typography-84ca66e-font-weight: 500;
  --e-global-typography-84ca66e-font-style: italic;
  --e-global-typography-84ca66e-line-height: 1.5em;
  --e-global-typography-84ca66e-letter-spacing: 0px;
  --e-global-typography-112b6c4-font-family: "Inter";
  --e-global-typography-112b6c4-font-size: 13px;
  --e-global-typography-112b6c4-font-weight: 300;
  --e-global-typography-a435f28-font-family: "Gotham Bold";
  --e-global-typography-a435f28-font-size: 200px;
  --e-global-typography-a435f28-font-weight: 400;
  --e-global-typography-a435f28-text-transform: capitalize;
  --e-global-typography-a435f28-line-height: 1em;
  --e-global-typography-a435f28-letter-spacing: -2px;
  color: var(--e-global-color-primary);
  font-family: var(--e-global-typography-b2ce6af-font-family), Sans-serif;
  font-size: var(--e-global-typography-b2ce6af-font-size);
  font-weight: var(--e-global-typography-b2ce6af-font-weight);
  text-transform: var(--e-global-typography-b2ce6af-text-transform);
  font-style: var(--e-global-typography-b2ce6af-font-style);
  line-height: var(--e-global-typography-b2ce6af-line-height);
}

.mat-form-field-appearance-outline._mat-animation-noopable:not(.mat-form-field-disabled) .mat-form-field-flex:hover ~ .mat-form-field-outline,
.mat-form-field-appearance-outline._mat-animation-noopable .mat-form-field-outline,
.mat-form-field-appearance-outline._mat-animation-noopable .mat-form-field-outline-gap {
  transition: none;
  background-color: white !important;
  color: var(--color1) !important;
}

.formodal {
  overflow: hidden !important;
}

.has-error .help-block {
  position: absolute !important;
  margin: 0px !important;
  padding: 0 !important;
  top: 42px !important;
  left: 0px !important;
  margin-top: 3px !important;
  font-size: 12px !important;
  margin-left: -10px !important;
}

#signupForm .has-error .help-block {
  position: absolute !important;
  margin: 0px !important;
  padding: 0 !important;
  top: 30px !important;
  left: 10px !important;
  margin-top: 3px !important;
  font-size: 12px !important;
  margin-left: -10px !important;
}

.f-s-22 {
  font-size: 22px;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute !important;
  top: 85% !important;
  bottom: 0 !important;
  z-index: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 40px !important;
  color: white !important;
  text-align: center !important;
  opacity: 0.4 !important;
  transition: opacity 0.15s ease !important;
  background-color: var(--color2);
  border: 1px solid white;
  border-radius: 50%;
  height: 40px;
  margin-left: 20%;
  margin-right: 20%;
  background-size: contain;
  background-repeat: no-repeat;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  position: absolute !important;
  top: 85% !important;
  bottom: 0 !important;
  z-index: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 40px !important;
  color: white !important;
  text-align: center !important;
  opacity: 0.8 !important;
  transition: opacity 0.15s ease !important;
  background-color: var(--color1) !important;
  border: 1px solid white;
  border-radius: 50%;
  height: 40px;
  margin-left: 20%;
  margin-right: 20%;
  background-size: contain;
  background-repeat: no-repeat;
}

.carousel-control-next-icon {
  background-image: url(./assets/images/new/next.svg);
  filter: brightness(0) invert(1);
  scale: 1.2;
}

.carousel-control-prev-icon {
  background-image: url(./assets/images/new/previous.svg);
  filter: brightness(0) invert(1);
  scale: 1.2;
}

.mat-drawer-content {
  overflow: unset !important;
}

.mat-drawer-container[fullscreen] {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: unset !important;
}

div#mainDIV {
  overflow: unset !important;
}

.myapp-no-padding-dialog .mat-dialog-container {
  padding: 0 !important;
}

/*Market Selector */
.marketselector-dialog-container .mat-dialog-container {
  padding: 0px !important;
}

.marketselector-dialog-container .mat-card {
  padding: 0px;
  overflow: hidden;
}

.marketselector-dialog-container .mat-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-bottom: 10px;
}

.marketselector-dialog-container mat-card-header {
  margin-bottom: 0px;
}

.marketselector-dialog-container .mat-card-header-text {
  margin: 0px;
  width: 100%;
  text-align: center;
  background-color: var(--theme-deafult-secondary);
}

.marketselector-dialog-container div.mat-card-header-text {
  padding: 10px;
}

.marketselector-dialog-container .mat-card-title {
  margin-bottom: 0px !important;
  display: inline-block;
  font-size: 26px;
  font-weight: 400;
  color: #fff;
}

.marketselector-dialog-container .mat-form-field-flex {
  border-radius: 0px !important;
  padding: 0px 0.75em !important;
  box-shadow: 0 1px 4px 0 #000000 !important;
  background-color: #fff !important;
}

#ds_application2 .mat-expansion-panel {
  margin: 24px 8px 8px 8px !important;
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
}
#ds_application2 h4 {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
  opacity: 0.7 !important;
}
#ds_application2 #verifystatus-Content {
  margin: 24px 8px 8px 8px !important;
}

#application h4 {
  line-height: 1.5 !important;
}

.avtar img {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.f-s-16 {
  font-size: 16px;
}

.f-s-14 {
  font-size: 14px;
}

.f-s-18 {
  font-size: 18px !important;
}

.f-s-20 {
  font-size: 20px;
}

.f-s-22 {
  font-size: 22px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-15 {
  padding: 15px;
}

.textBlack {
  color: black !important;
}

mat-toolbar {
  background-color: black !important;
  color: white !important;
}

.m-l-300 {
  margin-left: 300px !important;
}

.m-15 {
  margin: 15px;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.bgm-color2 {
  background-color: #007ec6 !important;
  color: #fff;
}

.text-uppercase {
  text-transform: uppercase;
}

.f-s-12 {
  font-size: 12px;
}

.f-s-10 {
  font-size: 10px;
}

.d-flex {
  display: flex;
}

.ds-summary-cart > mat-sidenav-container {
  width: 420px;
}

.marketselecter_find_popup {
  position: fixed !important;
  top: 0;
}

.mat-fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 1rem;
  left: auto;
  position: fixed;
}

.help-block {
  color: red !important;
}

.mail_position {
  bottom: 8px;
  position: relative;
}

.v-align-middle {
  vertical-align: middle;
}

.main-nav__link {
  color: #262626;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  min-height: 48px;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.m-t--15 {
  margin-top: -15px;
}

.l-height-5 {
  line-height: 5;
}

.l-height-3 {
  line-height: 3;
}

.checkbox .has-error {
  position: absolute;
  margin: 0;
  padding: 0;
  top: 25px !important;
  left: 24px !important;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-s-36 {
  font-size: 36px;
}

.f-s-34 {
  font-size: 34px;
}

.f-s-32 {
  font-size: 32px;
}

.f-s-30 {
  font-size: 30px;
}

.f-s-28 {
  font-size: 28px;
}

.f-s-26 {
  font-size: 26px;
}

.f-s-24 {
  font-size: 24px;
}

.f-s-23 {
  font-size: 23px;
}

.f-s-44 {
  font-size: 44px;
}

.f-s-42 {
  font-size: 42px;
}

.f-s-50 {
  font-size: 50px;
}

.f-s-72 {
  font-size: 50px;
}

.m-t-12 {
  margin-top: 12px !important;
}

.m-t-26 {
  margin-top: 26px !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.m-l-12 {
  margin-left: 25px !important;
}

.m-l-18 {
  margin-left: 32px !important;
}

.font-IBM {
  font-family: "Gotham", sans-serif !important;
}

.font-gotham {
  font-family: "Gotham", sans-serif !important;
}

.font-gothamnormal {
  font-family: "Gotham", sans-serif !important;
}

.mat-card {
  font-family: "Gotham", sans-serif !important;
}

.mat-dialog-content {
  display: block !important;
  max-height: 125vh !important;
  overflow: auto !important;
  width: 100%;
  margin: 0 5px 0 0;
}

.b-r-23 {
  border-radius: 23px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--theme-deafult-secondary) !important;
}

::ng-deep .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: var(--theme-deafult-secondary) !important;
}

::ng-deep .mat-form-field-infix {
  font-family: "Gotham", sans-serif !important;
}

.m-t-30 {
  margin-top: 30px;
}

.mat-form-field-infix {
  border-top: 0.4em solid transparent !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.75em 0 0.75em 0;
}

@media only screen and (max-width: 600px) {
  .carousel-control-prev,
.carousel-control-next {
    position: absolute !important;
    top: 90% !important;
    bottom: 0 !important;
    z-index: 1 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 10% !important;
    color: white !important;
    text-align: center !important;
    opacity: 0.5 !important;
    transition: opacity 0.15s ease !important;
    background-color: #0891cb;
    border: 1px solid white;
    border-radius: 50%;
    height: 5%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .carousel-control-prev:hover,
.carousel-control-next:hover {
    position: absolute !important;
    top: 90% !important;
    bottom: 0 !important;
    z-index: 1 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 10% !important;
    color: white !important;
    text-align: center !important;
    opacity: 0.8 !important;
    transition: opacity 0.15s ease !important;
    background-color: #0891cb;
    border: 1px solid white;
    border-radius: 50%;
    height: 5%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .carousel-indicators li {
    box-sizing: content-box !important;
    flex: 0 1 auto !important;
    width: 30px !important;
    height: 3px !important;
    margin-right: 3px !important;
    margin-left: 3px !important;
    margin-bottom: -100px !important;
    text-indent: -999px !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border-top: 10px solid transparent !important;
    border-bottom: 10px solid transparent !important;
    opacity: 1 !important;
    transition: opacity 0.6s ease !important;
  }
}
.has-errors {
  color: red !important;
}